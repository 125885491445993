import React from 'react';
import { 
			Alert, 
			Col,
			Spinner
		} from 'reactstrap';
import './Styles.css';
import { IoIosAlert, IoIosNotifications } from "react-icons/io";

import globalVars from './constants';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Redirect} from 'react-router-dom';


export default class Alertas extends React.Component{

	constructor(props){
		super(props);
		this.state = {

			url: globalVars.url,

			isLoading: true,
			dataSourceAlerts: [],
		};
	}

	componentDidMount(){

		this.setVarsCurrentSession();

	}


	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { 
      		this.getAlerts( this.state.url );
      });

  	}


	getAlerts = (url) => {

		return fetch( url + '/get-alerts-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
					           headers:{  
					               "Accept": "application/json",
					               "Content-Type": "application/json"
					           },
					           method:'GET'

				    	}).then((response) => response.json())
				          .then((responseJson) => {
				          	if( responseJson != 'No Results.' ){

				          		if( responseJson == 'Token Error.' ){
				          			localStorage.removeItem('permissValid');
				          			localStorage.removeItem('currentUser');
		    						localStorage.removeItem('currentToken');
				          			this.setState({
				          				currentUser: null,
				          				currentToken: null
				          			});
			          			}else{

					          		this.setState({
						          		isLoading: false,
						            	dataSourceAlerts: responseJson,
						          	}, function() {
						            	// In this block you can do something with new state.
						            	// console.log( this.state.dataSourceAlerts );
						            
						          	});
					          	}

				          	}
				          	else{
			          				this.setState({ isLoading: false });				          		
				          	}
					          
					       }).catch((error) => {
				          		// console.error(error);
				           	  });

	}


	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}

		const styles = {

			styleIcons: {
				fontSize: "30px",
				color: " #34495e ",
			},

			fontTitle: {
				color: '#34495e',
			},

			cardAlert: {
				fontFamily: 'Crimson Text',
				width: '70%',
				boxShadow: '0px 0px 25px #0000004F',
				borderRadius: '3px',
				margin: '0px auto 40px',
				fontWeight: 600
			},

			topCard: {
				background: 'linear-gradient(135deg, rgba(255,91,91,1) 0%, rgba(255,159,209,1) 100%)',
				height: '120px',
				color: '#fff',
				padding: '15px',
				borderTopLeftRadius: '3px',
				borderTopRightRadius: '3px',
			},

			footerCard: {
				color: '#FF5B5B',
				padding: '5px 15px',
				textAlign: 'right',
				height: '50px',
				borderBottomLeftRadius: '3px',
				borderBottomRightRadius: '3px',
			},

			notifyIcon: {
				margin: '0 10px',
				fontSize: '18px'
			}

		};

		var render_alerts_daily = null;

		if( this.state.isLoading ){

			return(
				<div>
					<Spinner type="grow" style={{ marginTop: '35px' }} color="info" />
				</div>
			);

		}

		if( this.state.dataSourceAlerts.length > 0 ){

			render_alerts_daily = this.state.dataSourceAlerts.map( (item, index) => 

					<div style={ styles.cardAlert }>
						<div style={ styles.topCard }>
							<p>{ item.entrada_titulo.replace("?", "ó") }</p>
							<p>{ item.tipo.replace("?", "Ó") }</p>
						</div>
						<div style={ styles.footerCard }>
							<IoIosNotifications style={ styles.notifyIcon } />
							{ item.fecha_hora_entrada }
						</div>
					</div>

			);

		}else{
			render_alerts_daily = <Alert color="danger" style={{ margin: '0 auto', width: '80%' }}> Sin acciones por mostrar </Alert>;
		}


		return(

			<div className="container-first" style={{ overflowY: 'scroll' }}>
				<Col sm={12} className="sidebar-4-header">
					<h3 style={ styles.fontTitle }> Acciones de Hoy </h3>
					<IoIosAlert style={ styles.styleIcons } />
					<hr />
				</Col>
				{ render_alerts_daily }
			</div>

		);


	}



}