import React from 'react';
import { 
			Form, 
			FormGroup, 
			Modal,
			ModalHeader,
			ModalBody,
			ModalFooter,
			Label, 
			Input, 
			Button, 
			ButtonGroup, 
			Alert, 
			Col,
			UncontrolledAlert,
			Spinner
		} from 'reactstrap';
import './Styles.css';
import { FaBookReader, FaSearch, FaFileExport } from 'react-icons/fa';

import globalVars from './constants';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Redirect, NavLink} from 'react-router-dom';



export default class Agendas extends React.Component{

	constructor(props){
		
		super(props);
		this.state = {

			fecha_busqueda: '',
			caso_busqueda: '',
			usuario_busqueda: '',
			url: globalVars.url,
			isLoading: true,
			dataSourceAgendas: [],
			dataFull: [],
			dataSourceUsuarios: [],
			dataSourceCasos: [],
			usersByEvents: [],

			alert_busqueda: false,
			alert_updated_estado_eve: false,
			alert_error_updating: false,

			modalUpdateComment: false,

		};

	}

	componentDidMount(){

		this.setVarsCurrentSession();

	}

	setVarsCurrentSession = () => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => {
      		var actual_date = this.get_actual_date();
			this.get_events_api( this.state.url, '', '', actual_date );
			this.get_cases_api( this.state.url );
			this.get_users_api( this.state.url );
      });

  	}

	get_users_api = (url) => {
			
			return fetch( url + '/get-users-table-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

		          		if( responseJson != 'No Results.' ){

		          			if( responseJson == 'Token Error.' ){

		          				localStorage.removeItem('permissValid');
		          				localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});

		          			}else{

		          				this.setState({
						            dataSourceUsuarios: responseJson,
						        }, function() {
						            // In this block you can do something with new state.
						            // console.log( this.state.dataSourceUsuarios );
						            
						        });

		          			}

		          		}
			          
			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}

	get_cases_api = (url) => {

			return fetch( url + '/get-cases-all-data-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

		          		if( responseJson != 'No Results.' ){

		          			if( responseJson == 'Token Error.' ){

		          				localStorage.removeItem('permissValid');
		          				localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});

		          			}else{

						          this.setState({

						            dataSourceCasos: responseJson,

						          }, function() {
						            // In this block you can do something with new state.
						            // console.log( responseJson );
						            
						          });

						    }

						}

			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}

	get_events_api = ( url, caso, usuario, fecha ) => {
		
		let obj = {};

		obj.idCaso = caso;
		obj.idUsuario = usuario;
		obj.fecha = fecha;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log( obj );

		fetch( url + '/get-cases-events-users-by-filters-api.php',{
					   headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST', 
			           body: JSON.stringify({
				              					obj: obj,
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				  		//   console.log( responseJson );

				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;
			          			
				          	}else{
							
								this.setState({ 
	 											isLoading: false,
												dataSourceAgendas: responseJson,
												dataFull: responseJson

											  }, () => {  });
							}

				          }
				          
				   }).catch((error) => {
				        // 
				     });

	}

	get_actual_date(){

		var fecha = new Date();
		var anio = fecha.getFullYear();
		var mes = ( fecha.getMonth() + 1 ) < 10 ? ( "0" + ( fecha.getMonth() + 1 ) ) : ( fecha.getMonth() + 1 );

		fecha = anio + '-' + mes;
		
		return fecha;

	}

	searchFilter = (textSearch) => {
		     
		     const newData = this.state.dataFull.filter( function(item){
		         const itemData = item.filter_text.toUpperCase();
		         const textData = textSearch.toUpperCase();
		         return itemData.indexOf(textData) > -1;
		     });

		     this.setState({
		         dataSourceAgendas: newData,
		         textSearch: textSearch
		     }, () => { /*console.log( this.props.dataSourceClientes )*/ });

	}

	onChangeDate = (value) => {

		let obj = {};

		obj.idCaso = this.state.caso_busqueda;
		obj.idUsuario = this.state.usuario_busqueda;
		obj.fecha = value;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		var url = this.state.url;

		// console.log( value );

		this.setState({ fecha_busqueda: value });

		fetch( url + '/get-cases-events-users-by-filters-api.php',{
					   headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST', 
			           body: JSON.stringify({
				              					obj: obj,
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				  		  // console.log( responseJson );

				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){
				          		// console.log(1);
				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;
			          			
				          	}else{
							
								this.setState({ 
	 
	 											isLoading: false,
												dataSourceAgendas: responseJson,
												dataFull: responseJson,
												alert_busqueda: false

											  }, () => {  });
							}

				          }
				          else{

				          	this.setState({

				          		alert_busqueda: true
				          	
				          	});
				          }
				          
				   }).catch((error) => {
				        // console.log(error);
				     });

	}

	onChangeCase = (value) => {

		let obj = {};

		obj.idCaso = value;
		obj.idUsuario = this.state.usuario_busqueda;
		obj.fecha = this.state.fecha_busqueda;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		var url = this.state.url;

		// console.log( value );

		this.setState({ caso_busqueda: value });

		fetch( url + '/get-cases-events-users-by-filters-api.php',{
					   headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST', 
			           body: JSON.stringify({
				              					obj: obj,
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				  		  // console.log( responseJson );

				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;
			          			
				          	}else{
							
								this.setState({ 
	 											
	 											isLoading: false,
												dataSourceAgendas: responseJson,
												dataFull: responseJson,
												alert_busqueda: false

											  }, () => {  });

							}

				          }
				          else{

				          	this.setState({

				          		alert_busqueda: true
				          	
				          	});
				          }
				          
				   }).catch((error) => {
				        // console.log(error);
				     });

	}


	onChangeUser = (value) => {

		let obj = {};

		obj.idCaso = this.state.caso_busqueda;
		obj.idUsuario = value;
		obj.fecha = this.state.fecha_busqueda;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		var url = this.state.url;

		// console.log( value );

		this.setState({ usuario_busqueda: value });

		fetch( url + '/get-cases-events-users-by-filters-api.php',{
					   headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST', 
			           body: JSON.stringify({
				              					obj: obj,
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				  		  // console.log( responseJson );

				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;
			          			
				          	}else{
							
								this.setState({ 
	 											
	 											isLoading: false,
												dataSourceAgendas: responseJson,
												dataFull: responseJson,
												alert_busqueda: false

											  }, () => {  });
							}

				          }
				          else{

				          	this.setState({

				          		alert_busqueda: true
				          	
				          	});
				          }
				          
				   }).catch((error) => {
				        // console.log(error);
				     });

	}


	onUpdateStateEvent = ( id_estado_evento, estado_txt ) => {

		var url = this.state.url;
		let obj = {};

		obj.id_estado_evento = id_estado_evento;
		obj.estado = estado_txt;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch(url+'/update-events-api.php', {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Updated.'){

			            // alert('Se registro exitosamente el Cliente');
			            this.setState({ 
										alert_updated_estado_eve: true
			            			});
			            setTimeout(() => {
		  
						  this.setState({ alert_updated_estado_eve: false });

						}, 3500);

						this.get_events_api( this.state.url, this.state.caso_busqueda, this.state.usuario_busqueda, this.state.fecha_busqueda );


			          }
			          else{

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;

			          	}else{

			          		this.setState({ alert_error_updating: true });
				          	setTimeout(() => {
			  
							  this.setState({ alert_error_updating: false });

							}, 3500);
							
							window.scrollTo(0, 120);

			          	}

			          }
			          
			      }).catch((error) => {
			        // console.log(error);
			      });

	}

	setVarsCommentState = (idE, Desc, eveV, Fecha, Hora, Prioridad, Estate, Resumen) => {

		if( Fecha.length === 9 ){
			Fecha = (Fecha !== null) ? Fecha.slice(0, 9) : null;
			Fecha = (Fecha !== null) ? Fecha.slice(5,9).concat( "-", Fecha.slice(2, 4).concat( "-", "0" + Fecha.slice(0, 1) ) ) : null;
		}else{
			Fecha = (Fecha !== null) ? Fecha.slice(0, 10) : null;
			Fecha = (Fecha !== null) ? Fecha.slice(6,10).concat( "-", Fecha.slice(3, 5).concat( "-", Fecha.slice(0, 2) ) ) : null;
		}

		this.setState({ idEve: idE, commentV: Desc, eventV: eveV, fechaV: Fecha, horaV: Hora, 
						prioridadV: Prioridad, estateV: Estate, resumenV: Resumen, modalUpdateComment: true });

	}

	updateEventComment = () => {

		var url = this.state.url;
		let obj = {};

		obj.id_evento_c = this.state.idEve;
		obj.descripcion = this.state.commentV;
		obj.fechaV = this.state.fechaV;
		obj.horaV = this.state.horaV;
		obj.estateVAccion = this.state.estateV;
		obj.prioridadVAccion = this.state.prioridadV;
		obj.resumenAccion = this.state.resumenV;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log(obj);

		fetch(url+'/update-events-comment-api.php', {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Updated.'){

			            // alert('Se registro exitosamente el Cliente');
			            this.setState({ 
			            				modalUpdateComment: false,
										alert_updated_estado_eve: true,
										dataSourceAgendas: [],
										dataFull: [],
										isLoading: true
			            			}, () => { this.get_events_api( this.state.url, this.state.caso_busqueda, this.state.usuario_busqueda, this.state.fecha_busqueda ); });
			            setTimeout(() => {
		  
						  this.setState({ alert_updated_estado_eve: false });

						}, 3500);

			          }
			          else{

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;

			          	}else{

			          		this.setState({ alert_error_updating: true });
				          	setTimeout(() => {
			  
							  this.setState({ alert_error_updating: false });

							}, 3500);
							
							window.scrollTo(0, 120);

			          	}

			          }
			          
			      }).catch((error) => {
			        // console.log(error);
			      });

	}


	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}

		const styles = {

			styleIcons: {
				fontSize: "30px",
				color: "#FFF",
			},

			containerSerachIcon: {
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignContent: "center",
				alignItems: "center",
				float: "left",
			},

			searchIcone: {
				position: "absolute",
				color: '#FFF'
			},

		};

		var render_panel = null;
		var chargin_var = null;
		var render_alert_busqueda = null;
		var render_options_cases = null;
		var render_options_usuarios = null;
		var render_alert_error_updating = null;
		var render_alert_updated_estado_eve = null;

		if( this.state.isLoading ){

			chargin_var = (
				<div>
					<Spinner type="grow" style={{ marginTop: '35px' }} color="info" />
				</div>
			);

		}

		if( this.state.dataSourceAgendas.length > 0 ){

			render_panel = this.state.dataSourceAgendas.map( (item, index) => 

					<Alert className="item-events" color="light" 
								style={{ 
											borderTop: item.accion_prioridad == "tramite" ? 
												'solid 10px #D6EAF8' : 
												( item.accion_prioridad == "no urgente" ? 
													'solid 10px #F7DC6F' : 
													( item.accion_prioridad == "urgente" ? 'solid 10px #dc3545' : '' ) ),

										}}> 
						<h4 style={{ marginBottom: 0, marginTop: "20px" }}> 
							{ item.evento }, 
							<NavLink className="no-text-decoration" to={{ pathname: '/viewcasosfind', state: { CasoRedirected: item.codigo_caso } }}>caso: { item.codigo_caso }</NavLink> 
						</h4> 
						<hr style={{ marginTop: 0 }} />
						<Input type="textarea" style={{ margin: '10px auto', width: 'calc(100% - 60px)' }} value={ item.descripcion } />
						<div style={{ display: 'flex' }}>
							<Col sm={4}>
								<p style={{ textAlign: 'left', marginLeft: '7%' }}> { item.primer_nombre } { item.primer_apellido } </p>
								<p style={{ textAlign: 'left', marginLeft: '7%' }}> { item.fecha.slice(0, 10) } </p>
							</Col>
							<Col sm={8}>
								{
									item.descripcion_adicional !== ''
									?
									<Input type="textarea" style={{ margin: '', width: 'calc(100% - 15px)' }} value={ item.descripcion_adicional } />
									:
									<h5 style={{ fontWeight: '500', fontStyle: 'italic', fontSize: 'medium' }}>Sin resumen.</h5>
								}
							</Col>
						</div>
						
						<div style={{ display: 'flex' }}>
							<p style={{ textAlign: 'left', marginLeft: '5%', 
										borderRadius: '8px', width: '88px',
										padding: 5, border: 'solid 2px #17a2b8' }}> { item.hora.slice(11, 19) } 
							</p>
							<span style={{ marginLeft: '40px', borderRadius: '20px', height: '34px', width: '110px',
											backgroundColor: '#3498DB', color: '#FFF', padding: '5px 15px', }} 
								  onClick={ () => { this.setVarsCommentState( item.id_evento_caso, item.descripcion, item.evento, item.fecha, item.hora.slice(11, 19), item.accion_prioridad, item.accion_estado, item.descripcion_adicional ) }}>
									Modificar
							</span>
							<ButtonGroup className="group-buttons" style={{ width: '55%', marginLeft: '30px' }}>
						      	<Button 
						      		color={ item.accion_prioridad == "no urgente" ? "primary" : "info" } 
						      		onClick={ () => {  } }
						      		>No urgente</Button>
						      	<Button 
						      		color={ item.accion_prioridad == "tramite" ? "primary" : "info" } 
						      		onClick={ () => {  } }
						      		>Trámite</Button>
						      	<Button 
						      		color={ item.accion_prioridad == "urgente" ? "danger" : "info" }
						      		onClick={ () => {  } }
						      		>Urgente</Button>
							</ButtonGroup>
						</div>
						
						<ButtonGroup className="group-buttons">
					      	<Button 
					      		color={ item.estado == "PENDIENTE" ? "primary" : "info" } 
					      		onClick={ () => { this.onUpdateStateEvent( item.id_estado_evento, "PENDIENTE" ) } }
					      		>Pendiente</Button>
					      	<Button 
					      		color={ item.estado == "ASIGNADO" ? "primary" : "info" } 
					      		onClick={ () => { this.onUpdateStateEvent( item.id_estado_evento, "ASIGNADO" ) } }
					      		>Asignado</Button>
					      	<Button 
					      		color={ item.estado == "PROGRESO" ? "primary" : "info" } 
					      		onClick={ () => { this.onUpdateStateEvent( item.id_estado_evento, "PROGRESO" ) } }
					      		>En proceso</Button>
					      	<Button 
					      		color={ item.estado == "ATRASADO" ? "primary" : "info" } 
					      		onClick={ () => { this.onUpdateStateEvent( item.id_estado_evento, "ATRASADO" ) } }
					      		>Atrasado</Button>
					      	<Button 
					      		color={ item.estado == "ESPERA" ? "primary" : "info" } 
					      		onClick={ () => { this.onUpdateStateEvent( item.id_estado_evento, "ESPERA" ) } }
					      		>Por Comprobar</Button>
					      	<Button 
					      		color={ item.estado == "COMPLETADO" ? "success" : "info" }
					      		onClick={ () => { this.onUpdateStateEvent( item.id_estado_evento, "COMPLETADO" ) } }
					      		>Finalizado</Button>
						</ButtonGroup>

						<Col sm={12}>
							{
								item.uri_documento !== '' 
								?
								<a href={ item.uri_documento } target="_blank" rel="noreferrer noopener" 
									style={{ backgroundColor: '#3498DB', width: '150px', height: '35px', display: 'flex', 
												justifyContent: 'center', alignItems: 'center', alignContent: 'center', 
												borderRadius: '3px', margin: '0 auto' }}
								 	onClick={ () => { } }><FaFileExport style={{ color: '#FFF' }} size={15} /></a>
								:
								null
							}
						</Col>
					</Alert>

			);
		}


		if( this.state.alert_busqueda ){

			render_alert_busqueda = <UncontrolledAlert color="danger">No se encontraron resultados</UncontrolledAlert>;

		}else{

			render_alert_busqueda = null;

		}

		if( this.state.dataSourceCasos.length > 0 ){

			render_options_cases = this.state.dataSourceCasos.map( (item, index) => 

				<option key={ index } value={ item.id_caso }> {item.codigo_caso} - { item.cliente } </option>

			);
		}else{

			render_options_cases = <option key="" value=""> Cargando... </option>;

		}


		if( this.state.dataSourceUsuarios.length > 0 ){

			render_options_usuarios = this.state.dataSourceUsuarios.map( (item, index) => 

				<option key={ index } value={ item.id_usuario }> { item.primer_nombre } { item.primer_apellido } </option>

			);
		}else{

			render_options_usuarios = <option key="" value=""> Cargando... </option>;
			
		}

		if( this.state.alert_updated_estado_eve ){

			render_alert_updated_estado_eve = <Alert sm={12} style={{ width: '100%' }} color="success">Se actualizo el evento correctamente</Alert>;

		}

		if( this.state.alert_error_updating ){

			render_alert_error_updating = <Alert sm={12} style={{ width: '100%' }} color="success">Error actualizando el estado</Alert>;

		}

		return(

			<div className="container-first">
				<div className="container-panel-2-segments">
					
					<Col md={4} className="sidebar-4">
						
						<Col sm={12} className="sidebar-4-header">
							<h3> Agendas </h3>
							<FaBookReader style={ styles.styleIcons } />
							<hr />
						</Col>
						
						<Col sm={12}>
							<Form>
								<FormGroup row className="input-search-div-container">
							        	<Col style={ styles.containerSerachIcon } sm={3}>
								        	<FaSearch style={ styles.searchIcone } />
								        </Col>
								        <Col sm={9}>
									        <Input
									          type="search"
									          name="buscar_caso"
									          id="buscar_caso"
									          placeholder="Buscar un caso ó usuario"
									          value={ this.state.textSearch }
									          onChange={ (textS) => { this.searchFilter( textS.target.value ) } }
									        />
								        </Col>
						      	</FormGroup>
      							<FormGroup row>
							        <Label for="select_date" sm={3}>Fecha: </Label>
							        <Col sm={9}>
							          <Input 
							           		name="select_date" 
							           		id="select_date" 
							           		type="date" 
							           		onChange={ (textS) => { this.onChangeDate( textS.target.value ) } }
							           		/>
							        </Col>
							    </FormGroup>
							    <FormGroup row>
							        <Label for="caso_busqueda" sm={3}>Caso: </Label>
							        <Col sm={9}>
							          <Input 
							           		name="caso_busqueda" 
							           		id="caso_busqueda" 
							           		type="select" 
							           		onChange={ (val) => { this.onChangeCase(val.target.value); }}
							           	>
							           		<option value="">Filtrar por caso</option>
							           		{ render_options_cases }

							          </Input>
							        </Col>
							    </FormGroup>
							    <FormGroup row>
							        <Label for="usuario_busqueda" sm={3}>Usuario: </Label>
							        <Col sm={9}>
							          <Input 
							           		name="usuario_busqueda" 
							           		id="usuario_busqueda" 
							           		type="select" 
							           		onChange={ (val) => { this.onChangeUser(val.target.value); }}
							           	>

							           		<option value="">Filtrar por usuario</option>
							           		{ render_options_usuarios }

							          </Input>
							        </Col>
							    </FormGroup>
							    <FormGroup row>
							    	{ render_alert_updated_estado_eve }
							    	{ render_alert_error_updating }
							    </FormGroup>
      						</Form>
						</Col>

					</Col>

        			<Col md={8} className="panel-8">

        				<div className="title-div-panel">
				        	<h1 className="title-panel">Agenda General</h1>
				        	<hr />
				      	</div>

				      	<div className="render-in-panel">
				      		
				      		{ render_alert_busqueda }	
							{ this.state.isLoading ? chargin_var : render_panel }
				 
						</div>

						<div>
			      			<Modal isOpen={this.state.modalUpdateComment} >
						        <ModalHeader >Actulizar evento</ModalHeader>
						        <ModalBody style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
					        		<Form inline style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
					        		   <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
								        <Label for="eventV" sm={4} style={{ justifyContent: 'end' }}>Evento:</Label>
								        <Col sm={8}>
								          <Input type="text" name="eventV" id="eventV" placeholder="Evento" 
								          		 style={{ width: '100%' }} value={ this.state.eventV }/>
								        </Col>
								      </FormGroup>
								      <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
								        <Label for="fechaV" sm={4} style={{ justifyContent: 'end' }}>Nueva fecha:</Label>
								        <Col sm={8}>
								          <Input type="date" name="fechaV" id="fechaV" placeholder="Fecha" 
								          		 style={{ width: '100%', }} 
								          		 value={ this.state.fechaV }
								          		 onChange={ (text) => { this.setState({ fechaV: text.target.value }) }}/>
								        </Col>
								      </FormGroup>
								      <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
								        <Label for="horaV" sm={4} style={{ justifyContent: 'end' }}>Nueva hora:</Label>
								        <Col sm={8}>
								          <Input type="time" name="horaV" id="horaV" placeholder="Hora" 
								          		 style={{ width: '100%', }} 
								          		 value={ this.state.horaV }
								          		 onChange={ (text) => { this.setState({ horaV: text.target.value }) }}/>
								        </Col>
								      </FormGroup>
					        		  <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
								        <Label for="commentV" sm={4} style={{ justifyContent: 'end' }}>Descripcion:</Label>
								        <Col sm={8}>
								          <Input type="textarea" name="commentV" id="commentV" placeholder="Descripción" 
								          		 style={{ width: '100%', height: '150px' }} 
								          		 value={ this.state.commentV }
								          		 onChange={ (text) => { this.setState({ commentV: text.target.value }) }}/>
								        </Col>
								      </FormGroup>
								      <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
								        <Label for="commentV" sm={4} style={{ justifyContent: 'end' }}>Prioridad:</Label>
								        <Col sm={8}>
									      	<ButtonGroup className="group-buttons" style={{ }}>
										      	<Button 
										      		color={ this.state.prioridadV == "no urgente" ? "primary" : "info" } 
										      		onClick={ () => { this.setState({ prioridadV: 'no urgente' }) } }
										      		>No urgente</Button>
										      	<Button 
										      		color={ this.state.prioridadV == "tramite" ? "primary" : "info" } 
										      		onClick={ () => { this.setState({ prioridadV: 'tramite' }) } }
										      		>Trámite</Button>
										      	<Button 
										      		color={ this.state.prioridadV == "urgente" ? "danger" : "info" }
										      		onClick={ () => { this.setState({ prioridadV: 'urgente' }) } }
										      		>Urgente</Button>
											</ButtonGroup>
										</Col>
								      </FormGroup>
								      <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
								        <Label for="resumenV" sm={4} style={{ justifyContent: 'end' }}>Resumen:</Label>
								        <Col sm={8}>
								          <Input type="textarea" name="resumenV" id="resumenV" placeholder="Resumen" 
								          		 style={{ width: '100%', height: '150px' }} 
								          		 value={ this.state.resumenV }
								          		 onChange={ (text) => { this.setState({ resumenV: text.target.value }) }}/>
								        </Col>
								      </FormGroup>
								      {
								      	this.state.alert_error_updating
								      	?
								      	<Alert color="warning" style={{ width: '100%' }}>Error actualizando.</Alert>
								      	:
								      	null
								      }			      	      
								    </Form>
						        </ModalBody>
						        <ModalFooter style={{ }}>	
						          <Button color="primary" onClick={ () => { this.updateEventComment(); } }>Actualizar</Button>
						          <Button color="secondary" onClick={ () => { this.setState({modalUpdateComment: !this.state.modalUpdateComment}); } }>Cerrar</Button>
						        </ModalFooter>
	      					</Modal>
	      				</div>

        			</Col>

				</div>
			</div>

		);


	}

}