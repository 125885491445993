import React from 'react';
import {
			Form,
			FormGroup,
			ButtonGroup,
			Label,
			Input,
			FormText,
			Button,
			Alert,
			UncontrolledAlert,
			Col,
			Row,
			Nav,
			NavItem,
			NavLink,
			Table,
			Spinner,
			Modal,
			ModalHeader, ModalBody, ModalFooter
		} from 'reactstrap';
import './Styles.css';
import { FaUserTie, FaMinus, FaSearch, FaWhmcs, FaFileExport, FaAlignJustify } from 'react-icons/fa';

import globalVars from './constants';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Redirect} from 'react-router-dom';



class Casos extends React.Component{


	constructor(props){
		super(props);
		this.state = {

			li_value: 1,

			redirect: false,

			textSearch: '',

			isLoadingCasos: true,
			dataSourceCasos: [],

			array_casos: [],

			item_index_arr: null,

			dataSourceUsuarios: [],

			display_init_spinner: true,

			id_caso_to_edit: '',
			redirect_to_edit_case: false,

		};
	}


	componentDidMount() {

    	var url = globalVars.url;

    	this.setVarsCurrentSession(url);

    	// console.log( this.props.CasoRedirected );

	}

	setVarsCurrentSession = (url) => {

	    let usuario = JSON.parse(localStorage.getItem('currentUser'));
	    let token = JSON.parse(localStorage.getItem('currentToken'));

	    this.setState({
	      currentUser: usuario,
	      currentToken: token
	    }, () => { this.get_cases_data(url); this.get_users_api(url); });

	}

	get_users_api = (url) => {
			
			return fetch( url + '/get-users-table-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

		          		if( responseJson != 'No Results.' ){

		          			if( responseJson == 'Token Error.' ){

		          				localStorage.removeItem('permissValid');
		          				localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});

		          			}else{

		          				this.setState({
						            dataSourceUsuarios: responseJson,
						        }, function() {
						            // In this block you can do something with new state.
						            // console.log( 'esto es', this.state.dataSourceUsuarios );
						            
						        });

		          			}

		          		}
			          
			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}

	onChangeUserSelect = (val) => {
		// console.log(val);

		var url = globalVars.url;
		if( val !== '' && val != '' && val !== null ){
			if( val == 'phamton' ){
				this.get_cases_data(url);
			}else{
				this.get_cases_data_by_user_case(url, val);
			}
			// console.log(1);
		}else{
			this.get_cases_data(url);
			// console.log(2);
		}

	}


	get_cases_data = (url) => {

		return fetch( url + '/get-cases-all-data-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {
			          	// console.log(responseJson);
		          		if( responseJson != 'No Results.' ){

		          			if( responseJson == 'Token Error.' ){

		          				localStorage.removeItem('permissValid');
		          				localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});

		          			}else{
		          				this.setState({
							            isLoadingCasos: false,
							            dataSourceCasos: responseJson,
							            array_casos: responseJson,
						         	}, function() {
						            // In this block you can do something with new state.
						            // console.log( 'esto es', responseJson );

						            // console.log( this.props.CasoRedirected );
						            if( this.props.CasoRedirected !== null && this.props.CasoRedirected !== '' && this.props.CasoRedirected !== undefined ){
						            	
						            	this.getArraySourceCase();
						       		
						       		}

						        });
		          			}

		          		}



			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}

	get_cases_data_by_user_case = (url, idParam) => {

		return fetch( url + '/get-cases-all-data-user-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken+'&to_id='+idParam, {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {
			          	// console.log(responseJson);
		          		if( responseJson != 'No Results.' ){

		          			if( responseJson == 'Token Error.' ){

		          				localStorage.removeItem('permissValid');
		          				localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});

		          			}else{
		          				this.setState({
							            isLoadingCasos: false,
							            dataSourceCasos: responseJson,
							            array_casos: responseJson,
						         	}, function() {
						            // In this block you can do something with new state.
						            // console.log( 'esto es', responseJson );

						            // console.log( this.props.CasoRedirected );
						         //    if( this.props.CasoRedirected !== null && this.props.CasoRedirected !== '' && this.props.CasoRedirected !== undefined ){
						            	
						         //    	this.getArraySourceCase();
						       		
						       		// }

						        });
		          			}

		          		}else{
		          			this.setState({
		          				isLoadingCasos: false,
		          				dataSourceCasos: [],
		          				array_casos: [],
		          			});
		          		}



			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}


	SearchFilterCasos = (textSearch) => {

		     const newData = this.state.array_casos.filter( function(item){
		         const itemData = item.filter.toUpperCase();
		         const textData = textSearch.toUpperCase();
		         return itemData.indexOf(textData) > -1;
		     });

		     this.setState({
		         dataSourceCasos: newData,
		         textSearch: textSearch
		     }, () => { /*console.log( this.props.dataSourceCasos )*/ });

	}

	goToViewCaso = (index) => {

		this.setState({ li_value: 5, item_index_arr: index });

	}

	changeLiValue = (value) => {

		var url = globalVars.url;
		this.setState({ li_value: 1 });
		this.get_cases_data(url);

	}

	goToEditScreenRedirect = (index) => {

		this.setState({
						redirect_to_edit_case: true,
						id_caso_to_edit: this.state.dataSourceCasos[index].id_caso
					 }, ()=>{ /* console.log(this.state.redirect_to_edit_case) */ } );
	}


	getArraySourceCase = () => {

			// console.log( this.props.CasoRedirected );
			var counter_act = 0;
			var indexReturn = null;
    		this.state.array_casos.map( (item, index) => {

    			// console.log( item.codigo_caso );
    			if( ( item.codigo_caso === this.props.CasoRedirected ) && counter_act === 0 ){
    				// console.log( index, item.codigo_caso, this.props.CasoRedirected );
    				indexReturn = index;
    				counter_act++;

    			}
    		
    		});
    		this.setState({ li_value: 5, item_index_arr: indexReturn });

	}


	render(){



		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}


		var render_panel = null;

      	if ( this.state.redirect_to_edit_case ) {

         	return <Redirect to={{ pathname: '/editarcaso', state: { idCaso: this.state.id_caso_to_edit } }} />;

      	}

      	if( this.state.li_value === 1 ){

      		render_panel = (

      			<Viewcasos isLoadingCasos={ this.state.isLoadingCasos } 
      						dataSourceCasos={ this.state.dataSourceCasos } 
      						dataSourceUsuarios={this.state.dataSourceUsuarios}
      						onChangeUserSelect={ this.onChangeUserSelect }
      						textSearch={ this.state.textSearch } 
      						onDataSourceCasosChange={ this.SearchFilterCasos } 
      						goToViewCaso={ this.goToViewCaso } 
      						goToEditScreen={ this.goToEditScreenRedirect } />
      		);

      	}


      	// if( this.state.li_value === 2 ){

      	// 	render_panel = <ViewAsociarUsuarios OnChangeLiValue={ this.changeLiValue } />;

      	// }


      	if( this.state.li_value === 5 ){

      		render_panel = <ViewSelectedCaso casoActual={ this.state.dataSourceCasos[this.state.item_index_arr] } />;

      	}


      	if( this.state.li_value === 3 ){

      		render_panel = <ViewAsociarEventos OnChangeLiValue={ this.changeLiValue } />;

      	}


      	if( this.state.li_value === 4 ){

      		render_panel = <ViewAsociarCasoCliente OnChangeLiValue={ this.changeLiValue } />;

      	}


      	if( this.state.li_value === 6 ){

      		render_panel = <ViewHonorariosCaso />;

      	}


      	const styles = {

			styleIcons: {
				fontSize: "30px",
				color: "#FFF",
			},

			styleActiveNav: {
				backgroundColor: "rgba(0,0,0,.2)",
				color: "#FFF",
				borderLeft: "solid 5px #ef8700",
				borderTopLeftRadius: '5px',
				borderBottomLeftRadius: '5px',

			}

		};

		setTimeout(() => {

			  this.setState({ display_init_spinner: false });

		}, 1000);

		var init_spinner = <Spinner style={{ display: this.state.display_init_spinner ? "" : "none", width: '3rem', height: '3rem', margin: "15px", }} type="grow" color="primary" />;


		return(

			<div className="container-clientes-options">
				<div className="contenedor-de-cajas-clientes-options">

					<Col md={3} className="sidebar-4-clientes-options">
						<Col sm={12} className="sidebar-4-header-clientes-options">
							<h3> Area de casos </h3>
							<FaUserTie style={ styles.styleIcons } />
							<hr />
						</Col>
						<Col sm={12}>
							<Nav className="sidebar-4-links-option-clientes-options" vertical>
        						<NavItem style={ this.state.li_value == 1 ? styles.styleActiveNav : null } onClick={ () => { this.changeLiValue(1); } }>
						          <NavLink style={{ color: this.state.li_value == 1 ? "#FFF" : "#98cde6" }} href="#">Casos</NavLink>
						        </NavItem>

						        {
						        	/*
						        	<NavItem style={ this.state.li_value == 2 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 2 }); } }>
							          <NavLink style={{ color: this.state.li_value == 2 ? "#FFF" : "#98cde6" }} href="#">Asociar usuarios - caso</NavLink>
							        </NavItem>
						        	*/
						        }
						        
						        <NavItem style={ this.state.li_value == 3 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 3 }); } }>
						          <NavLink style={{ color: this.state.li_value == 3 ? "#FFF" : "#98cde6" }} href="#">Asociar usuario - eventos</NavLink>
						        </NavItem>
						        <NavItem style={ this.state.li_value == 4 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 4 }); } }>
						          <NavLink style={{ color: this.state.li_value == 4 ? "#FFF" : "#98cde6" }} href="#">Asociar caso - cliente</NavLink>
						        </NavItem>
						        <NavItem style={ this.state.li_value == 6 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 6 }); } }>
						          <NavLink style={{ color: this.state.li_value == 6 ? "#FFF" : "#98cde6" }} href="#">Honorarios - Caso</NavLink>
						        </NavItem>
						        <NavItem style={ this.state.li_value == 5 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 5 }); } }>
						          <NavLink style={{ display: this.state.li_value == 5 ? "" : "none", color: this.state.li_value == 5 ? "#FFF" : "#98cde6" }} href="#">{ this.state.item_index_arr != null ? "Caso: " + this.state.dataSourceCasos[this.state.item_index_arr].codigo_caso : "" }</NavLink>
						        </NavItem>
						    </Nav>
						</Col>
					</Col>

        			<Col md={9} className="panel-8-clientes-options">

						{ init_spinner }
        				{ render_panel }

        			</Col>

				</div>
			</div>


		);


	}

}


export default Casos;






class Viewcasos extends React.Component{


	constructor(props){
		super(props);
		this.state = {

			caso_actual: [],
			typeCase: 1,

			usuario_busqueda: '',

		};

	}

	handleInputChangeTextSearch = (textSearch) => {
		//
    	this.props.onDataSourceCasosChange( textSearch );

  	}

  	handleInputChangeUser = (value) => {
  		// console.log(value);
  		if( this.props.onChangeUserSelect && value != '' && value !== null ){
  			this.props.onChangeUserSelect(value);
  		}
  	}

  	sendIndex = (key) => {

  		this.props.goToViewCaso( key );
  	}

  	sendIndexGoToRedirect = (key) => {

  		this.props.goToEditScreen( key );

  	}

	render_casos_table(){
		//
		if( this.props.dataSourceCasos.length > 0 ){

			var render_table = null;

			render_table = this.props.dataSourceCasos.map( (item, index) =>

						<tr key={index} > <th scope="row"  onClick={ () => this.sendIndex( index ) }>{ item.codigo_caso }</th> <td  onClick={ () => this.sendIndex( index ) }>{ item.cliente }</td> <td  onClick={ () => this.sendIndex( index ) }>{ item.tipo_caso }</td> <td  onClick={ () => this.sendIndex( index ) } style={{ color: item.estatus_caso == 'Activo' ? "#82e0aa" : "rgba(234, 83, 63, 1)", fontWeight: '650' }}>{ item.estatus_caso }</td> <td> { item.estatus != 0 ? /*<Button onClick={ () => this.sendIndexGoToRedirect( index ) } color="info">Editar</Button>*/ null : null }</td> </tr>

			);


			return render_table;

		}

	}

	render_casos_table_activos(){
		//
		if( this.props.dataSourceCasos.length > 0 ){

			var render_table = null;

			render_table = this.props.dataSourceCasos.map( (item, index) =>{

					if( item.estatus_caso == 'Activo' ){
						return(
							<tr key={index} style={{ display: this.state.typeCase == 1 ? '' : 'none' }}> <th scope="row"  onClick={ () => this.sendIndex( index ) }>{ item.codigo_caso }</th> <td  onClick={ () => this.sendIndex( index ) }>{ item.cliente }</td> <td onClick={ () => this.sendIndex( index ) }>{ item.tipo_caso }</td> <td style={{ width: '20vw', maxWidth: '20%' }} onClick={ () => this.sendIndex( index ) }>{ item.submateria }</td> <td  onClick={ () => this.sendIndex( index ) } style={{ color: item.estatus_caso == 'Activo' ? "#82e0aa" : "rgba(234, 83, 63, 1)", fontWeight: '650' }}>{ item.estatus_caso }</td> <td> { item.estatus != 0 ? /*<Button onClick={ () => this.sendIndexGoToRedirect( index ) } color="info">Editar</Button>*/ null : null }</td> </tr>
						);
					}

			});


			return render_table;

		}

	}

	render_casos_table_unactivos(){
		//
		if( this.props.dataSourceCasos.length > 0 ){

			var render_table = null;

			render_table = this.props.dataSourceCasos.map( (item, index) =>{

					if( item.estatus_caso != 'Activo' ){
						return(
							<tr key={index} style={{ display: this.state.typeCase == 1 ? 'none' : '' }}> <th scope="row"  onClick={ () => this.sendIndex( index ) }>{ item.codigo_caso }</th> <td  onClick={ () => this.sendIndex( index ) }>{ item.cliente }</td> <td onClick={ () => this.sendIndex( index ) }>{ item.tipo_caso }</td> <td style={{ width: '20vw', maxWidth: '20%' }} onClick={ () => this.sendIndex( index ) }>{ item.submateria }</td> <td  onClick={ () => this.sendIndex( index ) } style={{ color: item.estatus_caso == 'Activo' ? "#82e0aa" : "rgba(234, 83, 63, 1)", fontWeight: '650' }}>{ item.estatus_caso }</td> <td> { item.estatus != 0 ? /*<Button onClick={ () => this.sendIndexGoToRedirect( index ) } color="info">Editar</Button>*/ null : null }</td> </tr>
						);
					}

			});


			return render_table;

		}

	}


	render_usuarios_input(){
		if( this.props.dataSourceUsuarios.length > 0 ){

			var render_options_usuarios = this.props.dataSourceUsuarios.map( (item, index) => 

				<option key={ index } value={ item.id_usuario }> { item.primer_nombre } { item.primer_apellido } </option>

			);

			return render_options_usuarios;
		}else{

			var render_options_usuarios = <option key="" value=""> Cargando... </option>;
			return render_options_usuarios;
			
		}
	}



	render(){

		//

		// console.log( this.props.dataSourceCasos );
		// var render_casos_table = this.render_casos_table();
		var render_casos_table_activos = this.render_casos_table_activos();
		var render_casos_table_unactivos = this.render_casos_table_unactivos();

		const styles = {

			searchTitle: {
				color: "rgba(89, 91, 101)",
				fontFamily: 'Crimson Text',
				fontSize: '25px',
				fontWeight: '600',
			},

			containerSerachIcon: {
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignContent: "center",
				alignItems: "center",
				float: "left",
			},

			searchIcone: {
				left: "5px",
				position: "absolute"
			},

			tableStyle: {
				maxHeight: "80%",
				height: "80%",
				overflowY: "scroll"
			}

		};


		if( this.props.isLoadingCasos ){

			return (
				<div style={{ marginTop: "15px" }}>
					<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="success" />
				</div>
			);

		}


		return(

			<div className="panel-all-clientes-view">


				<Form className="form-panel-all-clientes-view" style={{ marginBottom: '30px', position: 'relative' }}>
					<FormGroup>
				        <Label style={ styles.searchTitle } for="buscar_caso">Buscar caso por código, cliente ó materia</Label>
				        <Row sm={2} className="input-search-div-container">
					        <Col sm={10}>
						        <Input
						          type="search"
						          name="buscar_caso"
						          id="buscar_caso"
						          placeholder="Buscar un caso"
						          value={ this.props.textSearch }
						          onChange={ (textS) => { this.handleInputChangeTextSearch( textS.target.value ) } }
						        />
					        </Col>
					        <Col style={ styles.containerSerachIcon } sm={2}>
					        	<FaSearch style={ styles.searchIcone } />
					        </Col>
					    </Row>
			      	</FormGroup>

			      	<Row style={{ position: 'absolute', width: '220px', top: '25%', right: '2%' }} >
			      		<FormGroup>
			      			<Input 
					           		name="usuario_busqueda" 
					           		id="usuario_busqueda" 
					           		type="select" 
					           		onChange={ (val) => { this.handleInputChangeUser(val.target.value); } }
					           	>

					           		<option value="">Filtrar por usuario</option>
					           		<option value="phamton">Todos</option>
					           		{ this.render_usuarios_input() }

					        </Input>
					    </FormGroup>
			      	</Row>
		      	</Form>


		      	<div className="table-panel-all-clientes-view" style={ styles.tableStyle }>

		      		<Nav tabs style={{ padding: '20px 0' }}>
		      			<NavItem style={{ padding: '10px 20px', borderTopLeftRadius: 7, backgroundColor: this.state.typeCase == 1 ? 'rgb(41, 128, 185)' : 'rgba(41, 128, 185, 0.8)', width: '50%' }}
		      						onClick={ () => { this.setState({ typeCase: 1 }); } }>
				          <h3 style={{ color: this.state.typeCase == 1 ? '#FAFAFA' : '#CFD8DC', fontWeight: '300' }}>Casos activos</h3>
				        </NavItem>
				        <NavItem style={{ padding: '10px 20px', borderTopRightRadius: 7, backgroundColor: this.state.typeCase == 2 ? 'rgb(41, 128, 185)' : 'rgba(41, 128, 185, 0.8)', width: '50%' }}
				        			onClick={ () => { this.setState({ typeCase: 2 }); } }>
				          <h3 style={{ color: this.state.typeCase == 2 ? '#FAFAFA' : '#CFD8DC', fontWeight: '300' }}>Casos inactivos/cerrados</h3>
				        </NavItem>
		      		</Nav>

		      		<Table dark>
				      <thead>
				        <tr>
				          <th>CÓDIGO CASO</th>
				          <th>CLIENTE</th>
				          <th>MATERIA</th>
				          <th>SUBMATERIA</th>
				          <th>ESTADO</th>
				          <th style={{ display: 'none' }}>EDITAR</th>
				        </tr>
				      </thead>
				      <tbody>

				      	{ render_casos_table_activos }
				      	{ render_casos_table_unactivos }

				      </tbody>
				    </Table>

		      	</div>





			</div>

		);

	}


}



class ViewAsociarUsuarios extends React.Component{

	constructor(props){
		super(props);
		this.state = {

			caso_id: '',
			usuarios_id: [],
			rol_user: '',

			isLoadingCasos: true,
			dataSourceCasos: [],
			array_casos: [],

			dataSourceUsuarios: [],

			complete_associate: false,

		};
	}


	componentDidMount(){

		var url = globalVars.url;
		// ...
		this.setVarsCurrentSession(url);

		// console.log( this.props );
		if( this.props.casoActual ){
			this.change_usuarios_by_case( this.props.casoActual.id_caso );
		}

	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => {
      		// this.get_casos_data(url);
      });

  	}


  	/*
	get_casos_data = (url) => {

		return fetch( url + '/get-cases-all-data-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

		          	if( responseJson != 'Token Error.'){

		          		if( responseJson == 'No Results.' ){
		          			return null;
		          		}else{
		          			this.setState({
					            isLoadingCasos: false,
					            dataSourceCasos: responseJson,
					            array_casos: responseJson,
					          }, function() {
					            // In this block you can do something with new state.
					            // console.log( this.state.dataSourceCasos );

					          });
		          		}

			      	}else{

			      			localStorage.removeItem('permissValid');
		          			localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});

		          	}

			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}
	*/


	/*
	change_usuarios_by_case( text ){

		var caso_id = text.target.value;
		// console.log( caso_id );

		this.setState({ caso_id: caso_id }, () => { this.get_user_by_case( caso_id ); });

	}
	*/


	change_usuarios_by_case( idC ){

		var caso_id = idC;
		// console.log( caso_id );

		this.setState({ caso_id: caso_id }, () => { this.get_user_by_case( caso_id ); });

	}


	get_user_by_case = ( caso_id ) => {

		var url = globalVars.url;
		fetch( url + '/get-users-all-by-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: caso_id,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

						  // console.log( responseJson );
				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

								this.setState({

												dataSourceUsuarios: responseJson

											  }, () => {  });
							}

				          }

				   }).catch((error) => {
				        console.log(error);
				     });

	}


	change_usuarios_ids( value_re ){

		var options = value_re.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
        	if (options[i].selected) {
                value.push(options[i].value);
            }
        }

        this.setState({ usuarios_id: value }, () => { /* console.log( this.state.usuarios_id); */ });

	}


	AsociarCasoUsuarios = () => {

		// console.log( this.state.usuarios_id );
		var url = globalVars.url;
		let obj = {};

		obj.idCaso = this.state.caso_id;
		obj.usuarios_id = this.state.usuarios_id;
		obj.rol_user = this.state.rol_user;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log(obj);

		fetch( url + '/set-users-associate-by-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					obj: obj,
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				  		  // console.log(responseJson);
				          if(responseJson != 'Error.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

				          		// console.log( responseJson );
								this.setState({
												complete_associate: true,
												// caso_id: '',
												// usuarios_id: []
											  }, ()=> {});

				          	}

				          }

				   }).catch((error) => {
				        console.log(error);
				     });

	}

	// changeLiValue = () => {

	// 	this.props.OnChangeLiValue(1);

	// }

	closeModalUserAdd = () => {
		this.props.closeModalUserAdd();
	}

	// SearchFilterCasos = (textSearch) => {

	// 	     const newData = this.state.array_casos.filter( function(item){
	// 	         const itemData = item.filter.toUpperCase();
	// 	         const textData = textSearch.toUpperCase();
	// 	         return itemData.indexOf(textData) > -1;
	// 	     });

	// 	     this.setState({
	// 	         dataSourceCasos: newData,
	// 	         textSearch: textSearch
	// 	     }, () => { /*console.log( this.state.dataSourceCasos )*/ });

	// }

	render(){

		let render_options_caso_usuario = null;
		let render_options_usuarios = null;

		const styles = {
			styleIcons:{
				fontSize: "30px",
				color: "#FFF",
			},
			buttonAnadirOtherClient: {
				marginTop: '15px',
				color: '#FFF',
				fontWeight: '650',
			},
			searchTitle: {
				color: "rgba(89, 91, 101)",
				fontFamily: 'Crimson Text',
				fontSize: '25px',
				fontWeight: '600',
			},

			containerSerachIcon: {
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignContent: "center",
				alignItems: "center",
				float: "left",
			},

			searchIcone: {
				left: "5px",
				position: "absolute"
			},
		};



		// if( this.state.isLoadingCasos ){

		// 	return(

		// 		<Alert style={{ margin: "20px 20px" }} color="success">Cargando Casos!</Alert>

		// 	);

		// }else{

		// 	render_options_caso_usuario = this.state.dataSourceCasos.map( (item, index) =>

		// 		<option key={index} value={ item.id_caso }>{ item.codigo_caso } - { item.cliente }</option>

		// 	);
		// }


		if( this.state.dataSourceUsuarios.length > 0 ){

			render_options_usuarios = this.state.dataSourceUsuarios.map( (item, index) =>

				<option key={index} value={ item.id_usuario } selected={ item.estado_usuario == "asociado" ? "selected" : "" }> { item.nombre_usuario } </option>

			);

		}


		// if( this.state.complete_associate ){

		// 	return(

		// 		<div>
		// 			<Alert style={{ marginTop: "20px", marginHorizontal: "10px" }} color="success">Se asociaron los usuarios al caso correctamente!</Alert>
		// 			<Form>

		// 				<FormGroup>
		// 			    	<Button className="button_addcliente" onClick={ this.changeLiValue }>Continuar</Button>
		// 			    </FormGroup>

		// 		    </Form>
		// 	    </div>

		// 	);
		// }


		if( this.state.complete_associate ){

			return(

				<div>
					<Alert style={{ marginTop: "20px", marginHorizontal: "10px" }} color="success">Se asoció el usuario al caso correctamente!</Alert>
					<Form>

						<FormGroup>
					    	<Button className="button_addcliente" onClick={ this.closeModalUserAdd }>Ok</Button>
					    </FormGroup>

				    </Form>
			    </div>

			);

		}



		return(

			<div className="panel-associate-user-case-client-view">

				<Form className="form-relate-case-user">

				      <div className="title-div-cliente-form">
				        <Label className="title-add-cliente-form"> Asociar Caso - Usuario </Label>
				      </div>

				      <div className="logo-div-add-cliente-form">
				        <FaWhmcs style={ styles.styleIcons } />
				      </div>

				      <br />

				      <FormGroup style={{ display: 'none' }} >
				        <Label style={ styles.searchTitle } for="buscar_caso">Buscar caso por código, cliente ó materia</Label>
				        <Row sm={2} className="input-search-div-container">
					        <Col sm={11}>
						        <Input
						          type="search"
						          name="buscar_caso"
						          id="buscar_caso"
						          placeholder="Buscar un caso"
						          onChange={ (textS) => { this.SearchFilterCasos( textS.target.value ) } }
						        />
					        </Col>
					        <Col style={ styles.containerSerachIcon } sm={1}>
					        	<FaSearch style={ styles.searchIcone } />
					        </Col>
					    </Row>
			      	  </FormGroup>

				      <FormGroup>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="select"
						          name="caso_id"
						          id="caso_id"
						          placeholder="Caso Seleccionado"
						          value={ this.state.caso_id }
						          // onChange={ (text) => { this.change_usuarios_by_case(text) } }
						        >
						        	<option key={0} value={ this.props.casoActual.id_caso }>{ this.props.casoActual.codigo_caso } - { this.props.casoActual.cliente }</option>
						        </Input>

						        <Input
						          type="text"
						          name="rol_user"
						          id="rol_user"
						          placeholder="Rol del usuario"
						          value={ this.state.rol_user }
						          onChange={ (textS) => { this.setState({ rol_user: textS.target.value }) } }
						          style={{ marginTop: '10px' }}
						        		/>
					      	</Col>

					      	<Col sm={6}>
					      		<Input
						          type="select"
						          multiple
						          name="usuarios_id"
						          id="usuarios_id"
						          placeholder="Usuarios"
						          onChange={ (value) => { this.change_usuarios_ids(value) } }
						        >
						        	{ render_options_usuarios }
						        </Input>
					      	</Col>
					    </Row>

				      </FormGroup>


				      <FormGroup className="centrarBtnAsociatte">
				      	<Button className="button_addcliente" onClick={ this.AsociarCasoUsuarios }>Asociar</Button>
				      </FormGroup>

			    </Form>

			</div>

		);

	}


}



class ViewSelectedCaso extends React.Component{

	constructor(props){
		super(props);
		this.state = {

			dui_vista: '',
			nit_vista: '',

			alert_updated_estado_caso: false,
			alert_error_updating: false,

			new_doc: null,
			name_doc: '',
			alert_status_upload_doc: 0,

			isLoadingDocs: true,
			docsCase: [],
			arr_all_docs: [],

			fases_arr: [],

			modalFasesAdd: false,
			fase: '',
			estado_fase: 'NO',
			observacion_fase: '',
			fecha_proyectada: '',

			modalActionAdd: false,
			accion: '',
			accion_etapa_pertenece: '',
			fecha_accion: null,
			hora_accion: null,
			descripcion_accion: '',
			accion_prioridad: 'no',
			accion_estado: 'No',

			alert_addingfase_ok: false,
			alert_error_addingfase: false,

			modal: false,
			comment: '',

			isLoadingLastCaseAction: true,
			isLoadingCaseUsers: true,
			isLoadingFases: true,

			dataSourceArrayUsersCase: [],
			dataSourceArrayEvents: [],

			dataAperturaEvent: [],

			isLoadingCasos: true,
			dataSourceCasos: [],

			dataSourceArrayHonorarios: [],

			idFase: '',
			newFase: '',
			newEstado: '',
			newObservacion: '',
			newFechaP: '',
			newCosto: '',
			newGasto: '',
			newDescripcionAdicionalFase: '',

			modalFasesUpdate: false,

			modalViewClient: false,

			modalItemAdd: false,
			requisitoAdd: '',
			requisitoStateAdd: 'NO',
			requisitoObservacion: '',
			requisitoDescripcion: '',


			modalItemUpdate: false,
			idRequisito: '',
			requisitoUpdate: '',
			requisitoStateUpdate: 'NO',
			requisitoObservacionUpdate: '',
			requisitoDescripcionUpdate: '',

			warning_add_requisito: false,
			warning_update_requisito: false,
			error_add_requisito: false,
			error_update_requisito: false,
			complete_add_requisito: false,

			isLoadingRequisitos: true,
			requisitos_arr: [],


			ctrlFaseI: false,
			ctrlFaseII: false,
			ctrlFaseIII: false,
			ctrlFaseIV: false,
			ctrlFaseV: false,
			ctrlFaseVI: false,
			ctrlFasesArr: [],

			changeNombreEtapa: false,
			nuevo_nombre_etapa2: '',

			modalUpdateDescripcion: false,

			idEveAccion: '', 
			commentVAccion: '', 
			eventVAccion: '',
			fechaVAccion: '', 
			horaVAccion: '', 
			estateVAccion: '',
			prioridadVAccion: '', 
			resumenAccion: '',
			modalUpdateAccion: false, 

			idAccionToFile: '',
			modalUpdateAccionFile: false,

			modalUserCaseAdd: false,

			title_case_infoCase: 'Información de caso',

		};

		this.ctrlFasesArr = [];

	}


	componentDidMount(){

		// console.log( this.props.casoActual );

		this.acoplateData();
		this.setVarsCurrentSession();

	}

	setVarsCurrentSession = () => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { 
      				this.getDocsArr( this.props.casoActual.id_caso ); 
      				this.get_events_case(globalVars.url, this.props.casoActual.id_caso);
					this.get_users_case(globalVars.url, this.props.casoActual.id_caso);
					this.get_last_move_case(globalVars.url, this.props.casoActual.codigo_caso);
					this.getFasesCase(this.props.casoActual.id_caso);
					this.get_cases_data(globalVars.url);
					this.get_honorarios_by_case(this.props.casoActual.id_caso);
					this.getRequisitosCase(this.props.casoActual.id_caso);
      			});

  	}


	acoplateData(){

		var cadDui = this.props.casoActual.DUI;
		var dui_format = cadDui.slice(0, 8).concat( "-", cadDui.slice(8, 9) );

		var cadNit = this.props.casoActual.NIT;
		// console.log( cadNit );
		var nit_format = cadNit.slice(0, 4).concat( "-", cadNit.slice(4, 10).concat( "-", cadNit.slice(10, 13).concat( "-", cadNit.slice(13, 14) ) ) );
		// console.log( nit_format );

		this.setState({
						dui_vista: dui_format,
						nit_vista: nit_format,
						newTitleFaseII: this.props.casoActual.nombre_fase_II
					  }, () => {});

	}

	get_cases_data = (url) => {

		return fetch( url + '/get-cases-all-data-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {
			          	// console.log(responseJson);
		          		if( responseJson != 'No Results.' ){

		          			if( responseJson == 'Token Error.' ){

		          				localStorage.removeItem('permissValid');
		          				localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});

		          			}else{

		          				let array_casos = responseJson;
		          				var textSearch = this.props.casoActual.codigo_caso;
							    const newData = array_casos.filter( function(item){
							         const itemData = item.filter.toUpperCase();
							         const textData = textSearch.toUpperCase();
							         return itemData.indexOf(textData) > -1;
							    });

		          				this.setState({
							            isLoadingCasos: false,
							            dataSourceCasos: newData,
						         	}, () => {
						            // In this block you can do something with new state.
						            // console.log( this.state.dataSourceCasos );
						             
						        });
		          			}

		          		}



			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}


	get_events_case = (url, idCaso) => {

		fetch( url + '/get-events-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: idCaso,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				  		  // console.log( responseJson );
				          
				          if(responseJson != 'No Results.'){

							if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{
								this.setState({ 
												isLoadingCaseEvents: false, 
												dataSourceArrayEvents: responseJson 
											  }, () => { this.getAperturaEvent(); });
				            }

				          }

				   }).catch((error) => {
				        console.log(error);
				     });

	}

	getAperturaEvent(){

		// console.log(this.state.dataSourceArrayEvents);
		var textSearch = 'APERTURA DE CASO';
		const newData = this.state.dataSourceArrayEvents.filter( function(item){
		         const itemData = item.evento.toUpperCase();
		         const textData = textSearch.toUpperCase();
		         return itemData.indexOf(textData) > -1;
		     });

		     this.setState({
		         dataAperturaEvent: newData,
		         loadingApertura: true,
		     }, () => { /* console.log( this.state.dataAperturaEvent ) */ });

	}

	get_users_case = (url, idCaso) =>{

		fetch( url + '/get-users-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: idCaso,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

						  // console.log( responseJson );
				          
				          if(responseJson != 'No Results.'){

							if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{
								this.setState({ isLoadingCaseUsers: false, dataSourceArrayUsersCase: responseJson }, () => {  });
								// console.log( responseJson );
							}

				          }else{
				          	this.setState({ isLoadingCaseUsers: false, UsersCaseMsg: responseJson });
				          }

				   }).catch((error) => {
				        console.log(error);
				     });

	}

	getDocsArr = (idCaso) => {

		var url = globalVars.url;

		// console.log(idCaso);
		fetch( url + '/get-documents-case-api.php', {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: idCaso,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),

		    	}).then( response => response.json() )
				  .then( responseJson => {

				  		// console.log(responseJson);

				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{


					          	// responseJson.map( (item) =>{

				            // 		console.log( item );

				            // 	});

								this.setState({
												isLoadingDocs: false,
												docsCase: responseJson,
												arr_all_docs: responseJson,
												no_results_docs: false,
											  });

				        	}


				          }
				          else{

				          	this.setState({ no_results_docs: true, isLoadingDocs: false, 
				          					docsCase: [], arr_all_docs: [] });

				          }

				   }).catch((error) => {
				        // console.log(error);
				     });

	}

	get_last_move_case = (url, CodeCaso) => {

		fetch( url + '/get-last-move-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken+'&var_finding='+CodeCaso,{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'
				}).then( response => response.json() )
				  .then( responseJson => {

						  // console.log( responseJson );
				          
				          if(responseJson != 'No Results.'){

							if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{
								this.setState({ isLoadingLastCaseAction: false, dataSourceArrayLastAction: responseJson }, () => {  });
								// console.log( responseJson );
							}

				          }

				   }).catch((error) => {
				        console.log(error);
				     });

	}


	get_honorarios_by_case = ( caso_id ) => {

		var url = globalVars.url;
		fetch( url + '/get-honorarios-by-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: caso_id,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

						  // console.log( responseJson );
				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

								this.setState({

												dataSourceArrayHonorarios: responseJson

											  }, () => {  });
							}

				          }

				   }).catch((error) => {
				        console.log(error);
				     });

	}


	updateStateCaso = (idCaso, stateU) => {

		// console.log( globalVars.url );

		var url = globalVars.url;
		let obj = {};

		obj.idCaso = idCaso;
		obj.estado = stateU;
		obj.comment = this.state.comment;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch(url+'/update-caso-state-api.php', {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Updated.'){

			            // alert('Se registro exitosamente el estado');
			            this.setState({
										alert_updated_estado_caso: true
			            			});


			          }
			          else{

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;

			          	}else{

			          		this.setState({ alert_error_updating: true });

							window.scrollTo(0, 120);

			          	}

			          }

			      }).catch((error) => {
			        console.log(error);
			      });

	}


	addFaseCaso = (idCaso) =>{

		this.setState({ loadingAddFase: true });

		var url = globalVars.url;
		let obj = {};

		obj.idCaso = idCaso;
		obj.fase = this.state.fase;
		obj.estado_fase = this.state.estado_fase;
		obj.observacion_fase = this.state.observacion_fase;
		obj.fecha_proyectada = this.state.fecha_proyectada;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log( obj );

		if( obj.fase !== '' && obj.fase !== null ){
			fetch(url+'/addfase-caso-api.php', {
				           headers:{
				               "Accept": "application/json",
				               "Content-Type": "application/json"
				           },
				           method:'POST',
				           body: JSON.stringify({
				              obj,
				           }),

				    }).then( response => response.json() )
				      .then( responseJson => {

				      	  // console.log( responseJson );

				          if(responseJson == 'Added.'){

				            // alert('Se registro exitosamente el estado');
				            this.setState({
											alert_addingfase_ok: true
				            			});
				            setTimeout( () => { this.setState({ alert_addingfase_ok: false}) }, 2500);
				            this.getFasesCase(idCaso);

				          }
				          else{

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

				          		this.setState({ alert_error_addingfase: true });
				          		setTimeout( () => { this.setState({ alert_error_addingfase: false}) }, 2500);

				          	}

				          }

				      }).catch((error) => {
				        console.log(error);
				      });
		}else{
			this.setState({ alert_error_addingfase: true });
			setTimeout( () => { this.setState({ alert_error_addingfase: false}) }, 2500);
		}

		this.setState({ loadingAddFase: false, modalFasesAdd: false });

	}


	sendingCommentState = () => {

		this.setState({ modal: !this.state.modal });

	}


	onFileChange = event => {

      // Update the state
      this.setState({ new_doc: event.target.files[0] });
      // console.log(event.target.files[0]);

  	};


	uploadDoc = (idCaso) => {

		var url = globalVars.url;

		var data = new FormData()
		data.append('idCaso', idCaso);
		data.append('codeCase', this.props.casoActual.codigo_caso);
		data.append('nameDoc', this.state.name_doc);
		data.append('fileU', this.state.new_doc);
		data.append('token', this.state.currentToken);
		data.append('key', '90732020f187b3124eda60d942ad2020cada2efdxyzcba');

		this.setState({ alert_status_upload_doc: 4 });

		if( this.state.new_doc == '' || this.state.new_doc == null || this.state.name_doc == '' ){

			this.setState({ alert_status_upload_doc: 3 });
	        setTimeout( () => {
	        	this.setState({ alert_status_upload_doc: 0 });
	        }, 3500);

		}else{

			fetch(url+'/upload-caso-file-api.php', {
			           mode: 'no-cors',
			           method:'POST',
			           body: data

			    }).then( res => {

			    	// console.log(res);
		    		this.setState({ alert_status_upload_doc: 1 });
			        setTimeout( () => {
			        	this.setState({ alert_status_upload_doc: 0 });
			        }, 3500);
			        this.getDocsArr(idCaso);

				      if (res.ok) {

				        console.log("Perfect! ");
				        this.setState({ alert_status_upload_doc: 1 });
				        setTimeout( () => {
				        	this.setState({ alert_status_upload_doc: 0 });
				        }, 3500);

				      } else if (res.status == 401) {

				        // console.log("Oops! ");
				        this.setState({ alert_status_upload_doc: 2 });
				        setTimeout( () => {
				        	this.setState({ alert_status_upload_doc: 0 });
				        }, 3500);

				      }
			    }, function (e) {
			      	// console.log("Error submitting form!", e);
			    }).catch((error) => {
			        // console.log(error);
			    });

		}

	}


	getFasesCase = (idCaso) => {

		var url = globalVars.url;

		// console.log(idCaso);

		fetch( url + '/get-fases-case-api.php', {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: idCaso,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),

		    	}).then( response => response.json() )
				  .then( responseJson => {

				  		// console.log(responseJson);

				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

								this.setState({
												isLoadingFases: false,
												fases_arr: responseJson,
												no_results_fases: false,
											  });

								var arr_tmp_estados_fases = [];
								responseJson.map( (item, index) => {
									arr_tmp_estados_fases.push(false);
								});

								// this.ctrlFasesArr = arr_tmp_estados_fases;
								// console.log(this.ctrlFasesArr);

								this.setState({ ctrlFasesArr: arr_tmp_estados_fases }, () => { /*console.log( this.state.ctrlFasesArr )*/ } ); 

				        	}


				          }
				          else{

				          	this.setState({ no_results_fases: true, isLoadingFases: false });

				          }

				   }).catch((error) => {
				        console.log(error);
				     });

	}

	changeFaseUpdate = () => {

		this.setState({ actualizando_fase_load: true });

		var url = globalVars.url;
		let obj = {};

		obj.idFase = this.state.idFase;
		obj.newFase = this.state.newFase;
		obj.newEstado = this.state.newEstado;
		obj.newObservacion = this.state.newObservacion;
		obj.newFechaP = this.state.newFechaP;
		obj.newCosto = this.state.newCosto;
		obj.newGasto = this.state.newGasto;
		obj.newDescripcionAdicionalFase = this.state.newDescripcionAdicionalFase;
		obj.casoCod = this.props.casoActual.codigo_caso;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log( obj );

		if( this.state.idFase !== '' && this.state.newEstado !== '' && this.state.newFechaP !== '' )
		{

			fetch(url+'/update-fase-caso-api.php', {
				           headers:{
				               "Accept": "application/json",
				               "Content-Type": "application/json"
				           },
				           method:'POST',
				           body: JSON.stringify({
				              obj,
				           }),

				    }).then( response => response.json() )
				      .then( responseJson => {

				      	  // console.log( responseJson );

				          if(responseJson == 'Updated.'){

				            // alert('Se registro exitosamente el estado');
				            this.setState({
											alert_updated_fase: true,
											idFase: '',
											newEstado: '',
											newObservacion: '',
											newFechaP: '',
											newCosto: '',
											newGasto: '',
											newDescripcionAdicionalFase: '',
											modalFasesUpdate: false
				            			});
				           	setTimeout( () => { this.setState({ alert_updated_fase: false }) }, 2000);
				           	this.getFasesCase(this.props.casoActual.id_caso);

				          }
				          else{

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

				          		this.setState({ alert_error_updating_fase: true }, () => {  });
				          		setTimeout( () => { 
				          			this.setState({ alert_error_updating_fase: false }) 
				          		}, 2000);

				          	}

				          }

				      }).catch((error) => {
				        console.log(error);
				      });
		}else{
			this.setState({ alert_error_updating_fase: true }, () => {  });
			setTimeout( () => { 
				this.setState({ alert_error_updating_fase: false }) 
			}, 2000);
			
		}

		this.setState({ actualizando_fase_load: false });

	}

	setVarsFaseUpdate = (idV, faseV, estateV, obV, fechaV, costoV, gastoV, ResumenV ) => {

		this.setState({ 
						idFase: idV, newFase: faseV, newEstado: estateV, 
						newObservacion: obV, newFechaP: fechaV,
						newCosto: costoV, newGasto: gastoV, newDescripcionAdicionalFase: ResumenV,
						modalFasesUpdate: true 
					  }, () => {  });

	}


	minusDocument = (idDocumento) => {

		var url = globalVars.url;
		let obj = {};

		obj.idDocumento = idDocumento;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch(url+'/delete-doc-caso-api.php', {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Deleted.'){

			            // alert('Se registro exitosamente el estado');
			            this.setState({
										alert_deleting_doc: true
			            			});
			            setTimeout( () => { this.setState({ alert_deleting_doc: false }) }, 2000 );
			            this.getDocsArr( this.props.casoActual.id_caso );

			          }
			          else{

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;

			          	}else{

			          		this.setState({ alert_error_deleting_doc: true });
			          		setTimeout( () => { this.setState({ alert_error_deleting_doc: false }) }, 2000 );

			          	}

			          }

			      }).catch((error) => {
			        console.log(error);
			      });

	}

	viewClientOpen = (varIndex) => {

		let object_case = this.state.dataSourceCasos[varIndex];
		// console.log( object_case );

		var cadNit = this.state.dataSourceCasos[varIndex].NIT;
		// console.log( cadNit );
		var nit_format = '';
		if( cadNit != '' && cadNit != null ){
			
			nit_format = cadNit.slice(0, 4).concat( "-", cadNit.slice(4, 10).concat( "-", cadNit.slice(10, 13).concat( "-", cadNit.slice(13, 14) ) ) );
			
		}


		var cadDui = this.state.dataSourceCasos[varIndex].DUI;
		var dui_format = '';

		if( cadDui != '' && cadDui != null ){

			dui_format = cadDui.slice(0, 8).concat( "-", cadDui.slice(8, 9) );

		}
		
		this.setState({

			varCliente: object_case.cliente,
			varCalidad: object_case.Calidad,
			varDui: dui_format,
			varNit: nit_format,
			varNrc: object_case.nrc,
			varUrlDui: object_case.img_dui,
			varUrlNit: object_case.img_nit,
			varUrlIva: object_case.img_iva,
			varEmail: object_case.email,
			varNumero: object_case.numero_telefonico,
			varTipo: object_case.tipo_cliente,
			varRepresentante: object_case.representante_legal,
			varNumeroRepre: object_case.telefono_representante,
			varMailRepre: object_case.mail_representante,
			varDireccion: object_case.viewClientOpen,
			varDescripA: object_case.descripcion_adicional,

			modalViewClient: true

		});
	
	}

	EditCase = () => {

		var idCaso = this.props.casoActual.id_caso;
		var url = globalVars.url;

		this.setState({ loadingAddAction: true });

		if( this.state.accion != '' && this.state.accion_etapa_pertenece != '' && this.state.accion_etapa_pertenece != 'no' && this.state.user_assigned != '' && this.state.user_assigned != 'no' ){

			let obj = {};
			obj.anadir_evento_caso = this.state.accion;
			obj.fecha_evento_caso_anadir = this.state.fecha_accion;
			obj.descripcion_evento_anadir = this.state.descripcion_accion;
			obj.hora_evento_caso_anadir = this.state.hora_accion;
			obj.tipo = 2;
			obj.user_assigned = this.state.user_assigned;
			obj.accion_etapa_pertenece = this.state.accion_etapa_pertenece;
			obj.accion_prioridad = (this.state.accion_prioridad == 'no' || this.state.accion_prioridad == null) ? 'tramite' : this.state.accion_prioridad;
			obj.accion_estado = this.state.accion_estado;
			// console.log(obj);

			fetch( url + '/set-actions-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: idCaso,
				              					eventsObj: obj,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'

				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {
				  		// console.log(responseJson);
				  		if( responseJson == "Token Error." ){
				  			localStorage.removeItem('permissValid');
				  			localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;
				  		}else{

				  		  if( responseJson == "key error." ){
				  		  	return null;
				  		  }

				          if( responseJson == 'Added'){

							this.setState({ 
											msj_done_save_edit: true,
											accion: '',
											fecha_accion: null,
											hora_accion: null,
											descripcion_accion: '',
											loadingAddAction: false,
											modalActionAdd: false
										  });
							setTimeout(() => {

							  this.setState({ msj_done_save_edit: false });

							}, 2500);
							this.get_events_case(globalVars.url, this.props.casoActual.id_caso);

							// console.log( responseJson );

				          }

				      	}

				   }).catch((error) => {
				        console.log(error);
				     });

		}
		else{

			this.setState({ warning_nada_x_actualizar: true, loadingAddAction: false });
			setTimeout(() => {

			  this.setState({ warning_nada_x_actualizar: false });

			}, 2500);

		}

	}


	minusAccion = (idEvento) => {

		var url = globalVars.url;
		let obj = {};

		obj.idEvento = idEvento;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch(url+'/delete-event-caso-api.php', {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Deleted.'){

			            // alert('Se registro exitosamente el estado');
			            this.setState({
										alert_deleting_event: true
			            			});
			            setTimeout( () => { this.setState({ alert_deleting_event: false }) }, 2000 );
			            this.get_events_case(globalVars.url, this.props.casoActual.id_caso );

			          }
			          else{

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;

			          	}else{

			          		this.setState({ alert_error_deleting_event: true });
			          		setTimeout( () => { this.setState({ alert_error_deleting_event: false }) }, 2000 );

			          	}

			          }

			      }).catch((error) => {
			        console.log(error);
			      });

	}

	addRequisitoCaso = (idCaso) => {

		this.setState({ loadingAddRequisito: true });

		var url = globalVars.url;
		let obj = {};

		obj.idCaso = idCaso;
		obj.requisitoAdd = this.state.requisitoAdd;
		obj.requisitoStateAdd = this.state.requisitoStateAdd;
		obj.requisitoObservacion = this.state.requisitoObservacion;
		obj.requisitoDescripcion = this.state.requisitoDescripcion;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log( obj );

		fetch(url+'/addrequisito-caso-api.php', {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  console.log( responseJson );

			          if(responseJson == 'Added.'){

			            // alert('Se registro exitosamente el estado');
			            this.setState({
										complete_add_requisito: true,
										requisitoAdd: '',
										requisitoStateAdd: 'NO',
										requisitoObservacion: '',
										requisitoDescripcion: '',
										modalItemAdd: false,
			            			});
			            setTimeout( () => { this.setState({ complete_add_requisito: false, }) }, 2500);
			            this.getRequisitosCase(idCaso);

			          }
			          else{

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;

			          	}else{

			          		this.setState({ error_add_requisito: true });
			          		setTimeout( () => { this.setState({ error_add_requisito: false}) }, 2500);

			          	}

			          }

			      }).catch((error) => {
			        console.log(error);
			      });

		this.setState({ loadingAddRequisito: false });

	}

	getRequisitosCase = (idCaso) => {

		var url = globalVars.url;

		// console.log(idCaso);

		fetch( url + '/get-requisitos-case-api.php', {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: idCaso,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),

		    	}).then( response => response.json() )
				  .then( responseJson => {

				  		// console.log(responseJson);

				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{


								this.setState({
												isLoadingRequisitos: false,
												requisitos_arr: responseJson,
												no_results_requisitos: false,
											  });

				        	}


				          }
				          else{

				          	this.setState({ requisitos_arr: [], no_results_requisitos: true, isLoadingRequisitos: false });

				          }

				   }).catch((error) => {
				        console.log(error);
				     });

	}

	changeRequisitoUpdate = () => {

		this.setState({ actualizando_requisito_load: true });

		var url = globalVars.url;
		let obj = {};

		obj.idRequisito = this.state.idRequisito;
		obj.nombRequ =  this.state.nombRequ;
		obj.requisitoStateUpdate = this.state.requisitoStateUpdate;
		obj.requisitoObservacionUpdate = this.state.requisitoObservacionUpdate;
		obj.requisitoDescripcionUpdate = this.state.requisitoDescripcionUpdate;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log( obj );

		if( this.state.idRequisito !== '' && this.state.requisitoStateUpdate !== '' )
		{

			fetch(url+'/update-requisito-caso-api.php', {
				           headers:{
				               "Accept": "application/json",
				               "Content-Type": "application/json"
				           },
				           method:'POST',
				           body: JSON.stringify({
				              obj,
				           }),

				    }).then( response => response.json() )
				      .then( responseJson => {

				      	  // console.log( responseJson );

				          if(responseJson == 'Updated.'){

				            // alert('Se registro exitosamente el estado');
				            this.setState({
											alert_updated_requisito: true,
											idRequisito: '',
											nombRequ: '',
											requisitoStateUpdate: '',
											requisitoObservacionUpdate: '',
											requisitoDescripcionUpdate: '',
											modalItemUpdate: false
				            			});
				           	setTimeout( () => { this.setState({ alert_updated_requisito: false }) }, 2000);
				           	this.getRequisitosCase(this.props.casoActual.id_caso);

				          }
				          else{

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

				          		this.setState({ alert_error_updating_requisito: true }, () => {  });
				          		setTimeout( () => { 
				          			this.setState({ alert_error_updating_requisito: false }) 
				          		}, 2000);

				          	}

				          }

				      }).catch((error) => {
				        console.log(error);
				      });
		}else{
			this.setState({ alert_error_updating_requisito: true }, () => {  });
			setTimeout( () => { 
				this.setState({ alert_error_updating_requisito: false }) 
			}, 2000);
			
		}

		this.setState({ actualizando_requisito_load: false });

	}

	setVarsRequisitoUpdate = (idV, nombreReq, estateV, obV) => {

		this.setState({ 
						idRequisito: idV, requisitoStateUpdate: estateV, nombRequ: nombreReq,
						requisitoObservacionUpdate: obV, modalItemUpdate: true 
					  }, () => {  });

	}

	minusRequisito = (idRequisito) => {

		var url = globalVars.url;
		let obj = {};

		obj.idRequisito = idRequisito;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch(url+'/delete-requisito-caso-api.php', {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Deleted.'){

			            // alert('Se registro exitosamente el estado');
			            this.setState({
										alert_deleting_requisito: true
			            			});
			            setTimeout( () => { this.setState({ alert_deleting_requisito: false }) }, 2000 );
			            this.getRequisitosCase(this.props.casoActual.id_caso );

			          }
			          else{

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;

			          	}else{

			          		this.setState({ alert_error_deleting_requisito: true });
			          		setTimeout( () => { this.setState({ alert_error_deleting_requisito: false }) }, 2000 );

			          	}

			          }

			      }).catch((error) => {
			        console.log(error);
			      });

	}

	changeNombreEtapa = () => {

		var url = globalVars.url;
		let obj = {};

		obj.idCaso = this.props.casoActual.id_caso;
		obj.newTitleFaseII = this.state.newTitleFaseII;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		if( obj.newTitleFaseII !== null && this.state.newTitleFaseII !== '' && this.state.idCaso !== '' && this.state.idCaso !== null ){
			
			fetch(url+'/change-faseIIname-caso-api.php', {
				           headers:{
				               "Accept": "application/json",
				               "Content-Type": "application/json"
				           },
				           method:'POST',
				           body: JSON.stringify({
				              obj,
				           }),

				    }).then( response => response.json() )
				      .then( responseJson => {

				      	  // console.log( responseJson );

				          if(responseJson == 'Changed.'){

				            // alert('Se registro exitosamente el estado');
				            this.setState({
											alert_changed_faseII: true,
											nuevo_nombre_etapa2: obj.newTitleFaseII,
											changeNombreEtapa: false
				            			});
				            setTimeout( () => { this.setState({ alert_changed_faseII: false }) }, 2000 );
				            this.getFasesCase(this.props.casoActual.id_caso);

				          }
				          else{

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

				          		this.setState({ alert_error_changing_faseII: true });
				          		setTimeout( () => { this.setState({ alert_error_changing_faseII: false }) }, 2000 );

				          	}

				          }

				      }).catch((error) => {
				        console.log(error);
				      });
		}else{
			this.setState({ alert_error_changing_faseII: true });
			setTimeout( () => { this.setState({ alert_error_changing_faseII: false }) }, 2000 );
		}

	}

	SearchDoc = (text) => {

		// console.log(this.state.dataSourceArrayEvents);
		var textSearch = text;

		const newData = this.state.arr_all_docs.filter( function(item){
		         const itemData = item.estatus.toUpperCase();
		         const textData = textSearch.toUpperCase();
		         return itemData.indexOf(textData) > -1;
		     });

		     this.setState({
		         docsCase: newData,
		     }, () => { /* console.log( this.state.docsCase ) */ });

	}

	changeDescripcionCase = () => {

		this.setState({ casoDescripcionUpdate: this.props.casoActual.descripcion_general, modalUpdateDescripcion: true });

	}

	changeDescripcionUpdate =() => {

		var url = globalVars.url;
		let obj = {};

		obj.idCaso = this.props.casoActual.id_caso;
		obj.casoDescripcionUpdate = this.state.casoDescripcionUpdate;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		if( this.state.idCaso !== '' && this.state.idCaso !== null ){
			
			fetch(url+'/change-descripcion-caso-api.php', {
				           headers:{
				               "Accept": "application/json",
				               "Content-Type": "application/json"
				           },
				           method:'POST',
				           body: JSON.stringify({
				              obj,
				           }),

				    }).then( response => response.json() )
				      .then( responseJson => {

				      	  // console.log( responseJson );

				          if(responseJson == 'Changed.'){

				            // alert('Se registro exitosamente el estado');
				            this.setState({
											alert_changed_faseII: true,
											modalUpdateDescripcion: false
				            			});
				            setTimeout( () => { this.setState({ alert_changed_faseII: false }) }, 2000 );
				            this.props.casoActual.descripcion_general = obj.casoDescripcionUpdate;

				          }
				          else{

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

				          		this.setState({ alert_error_changing_faseII: true });
				          		setTimeout( () => { this.setState({ alert_error_changing_faseII: false }) }, 2000 );

				          	}

				          }

				      }).catch((error) => {
				        console.log(error);
				      });
		}else{
			this.setState({ alert_error_changing_faseII: true });
			setTimeout( () => { this.setState({ alert_error_changing_faseII: false }) }, 2000 );
		}

	}


	setVarsAccionUpdate = (idE, Desc, eveV, Fecha, Hora, estadoVar, prioridadVar, resumenVar) => {

		if( Fecha.length === 9 ){
			Fecha = (Fecha !== null) ? Fecha.slice(0, 9) : null;
			Fecha = (Fecha !== null) ? Fecha.slice(5,9).concat( "-", Fecha.slice(2, 4).concat( "-", "0" + Fecha.slice(0, 1) ) ) : null;
		}else{
			Fecha = (Fecha !== null) ? Fecha.slice(0, 10) : null;
			Fecha = (Fecha !== null) ? Fecha.slice(6,10).concat( "-", Fecha.slice(3, 5).concat( "-", Fecha.slice(0, 2) ) ) : null;
		}

		this.setState({ idEveAccion: idE, commentVAccion: Desc, eventVAccion: eveV,
						 fechaVAccion: Fecha, horaVAccion: Hora, estateVAccion: estadoVar, 
						 prioridadVAccion: prioridadVar, resumenAccion: resumenVar, modalUpdateAccion: true 
					  });

	}


	updateEventComment = () => {

		var url = globalVars.url;
		let obj = {};

		obj.id_evento_c = this.state.idEveAccion;
		obj.nameAccion = this.state.eventVAccion;
		obj.descripcion = this.state.commentVAccion;
		obj.fechaV = this.state.fechaVAccion;
		obj.horaV = this.state.horaVAccion;
		obj.estateVAccion = this.state.estateVAccion;
		obj.prioridadVAccion = this.state.prioridadVAccion;
		obj.resumenAccion = this.state.resumenAccion;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log(obj);

		fetch(url+'/update-events-comment-api.php', {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Updated.'){

			            // alert('Se registro exitosamente el Cliente');
			            this.setState({ 
			            				modalUpdateAccion: false,
										alert_updated_estado_eve: true,
			            			}, () => { this.get_events_case(globalVars.url, this.props.casoActual.id_caso); });
			            setTimeout(() => {
		  
						  this.setState({ alert_updated_estado_eve: false });

						}, 3500);

			          }
			          else{

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;

			          	}else{

			          		this.setState({ alert_error_updating: true });
				          	setTimeout(() => {
			  
							  this.setState({ alert_error_updating: false });

							}, 3500);
							
							window.scrollTo(0, 120);

			          	}

			          }
			          
			      }).catch((error) => {
			        // console.log(error);
			      });

	}


	setAccionUpdateFile = ( idAccion ) => {

		this.setState({ idAccionToFile: idAccion, modalUpdateAccionFile: true });

	}


	updateFileAccion = () => {

		var url = globalVars.url;

		var data = new FormData()
		data.append('idEvento', this.state.idAccionToFile);
		data.append('codeCase', this.props.casoActual.codigo_caso);
		data.append('fileU', this.state.new_doc);
		data.append('token', this.state.currentToken);
		data.append('key', '90732020f187b3124eda60d942ad2020cada2efdxyzcba');

		this.setState({ alert_status_upload_doc: 4, actualizando_documentoaccion_load: true });

		if( this.state.new_doc == '' || this.state.new_doc == null ){

			this.setState({ alert_status_upload_doc: 3, actualizando_documentoaccion_load: false, });
	        setTimeout( () => {
	        	this.setState({ alert_status_upload_doc: 0 });
	        }, 3500);

		}else{

			fetch(url+'/upload-action-file-api.php', {
			           mode: 'no-cors',
			           method:'POST',
			           body: data

			    }).then( res => {

			    	// console.log(res);
		    		this.setState({ alert_status_upload_doc: 1, modalUpdateAccionFile: false });
			        setTimeout( () => {
			        	this.setState({ alert_status_upload_doc: 0, actualizando_documentoaccion_load: false, });
			        }, 3500);
			        this.get_events_case(globalVars.url, this.props.casoActual.id_caso);

				      if (res.ok) {

				        // console.log("Perfect! ");
				        this.setState({ alert_status_upload_doc: 1, actualizando_documentoaccion_load: false,
				        				modalUpdateAccionFile: false });
				        setTimeout( () => {
				        	this.setState({ alert_status_upload_doc: 0 });
				        }, 3500);

				      } else if (res.status == 401) {

				        // console.log("Oops! ");
				        this.setState({ alert_status_upload_doc: 2, actualizando_documentoaccion_load: false,
				        				modalUpdateAccionFile: false });
				        setTimeout( () => {
				        	this.setState({ alert_status_upload_doc: 0, actualizando_documentoaccion_load: false });
				        }, 3500);

				      }
			    }, function (e) {
			      	// console.log("Error submitting form!", e);
			    }).catch((error) => {
			        // console.log(error);
			    });

		}

	}

	setRequisitoUpdateFile = ( idRequisito ) => {

		this.setState({ idRequisitoToFile: idRequisito, modalUpdateRequisitoFile: true });

	}


	updateFileRequisito = () => {

		var url = globalVars.url;

		var data = new FormData()
		data.append('idRequisito', this.state.idRequisitoToFile);
		data.append('fileU', this.state.new_doc);
		data.append('token', this.state.currentToken);
		data.append('key', '90732020f187b3124eda60d942ad2020cada2efdxyzcba');

		this.setState({ alert_status_upload_doc: 4, actualizando_documentoaccion_load: true });

		if( this.state.new_doc == '' || this.state.new_doc == null ){

			this.setState({ alert_status_upload_doc: 3, actualizando_documentoaccion_load: false, });
	        setTimeout( () => {
	        	this.setState({ alert_status_upload_doc: 0 });
	        }, 3500);

		}else{

			fetch(url+'/upload-requisito-file-api.php', {
			           mode: 'no-cors',
			           method:'POST',
			           body: data

			    }).then( res => {

			    	// console.log(res);
		    		this.setState({ alert_status_upload_doc: 1, modalUpdateRequisitoFile: false });
			        setTimeout( () => {
			        	this.setState({ alert_status_upload_doc: 0, actualizando_documentoaccion_load: false, });
			        }, 3500);
			        this.getRequisitosCase(this.props.casoActual.id_caso);

				      if (res.ok) {

				        // console.log("Perfect! ");
				        this.setState({ alert_status_upload_doc: 1, actualizando_documentoaccion_load: false,
				        				modalUpdateRequisitoFile: false });
				        setTimeout( () => {
				        	this.setState({ alert_status_upload_doc: 0 });
				        }, 3500);

				      } else if (res.status == 401) {

				        // console.log("Oops! ");
				        this.setState({ alert_status_upload_doc: 2, actualizando_documentoaccion_load: false,
				        				modalUpdateRequisitoFile: false });
				        setTimeout( () => {
				        	this.setState({ alert_status_upload_doc: 0, actualizando_documentoaccion_load: false });
				        }, 3500);

				      }
			    }, function (e) {
			      	// console.log("Error submitting form!", e);
			    }).catch((error) => {
			        // console.log(error);
			    });

		}

	}


	closeModalUserAdd = () => {
		this.setState({ modalUserCaseAdd: false });
		this.get_users_case(globalVars.url, this.props.casoActual.id_caso);
	}


	onChangeTitleViewCase = (title_item) => {

		if( this.state.title_case_infoCase === 'Información de caso' ){
			this.setState({ title_case_infoCase: 'Etapa en revisión: ' + title_item });
		}else{
			this.setState({ title_case_infoCase: 'Información de caso' });
		}

	}
	
	
	render(){

		// console.log( this.props.casoActual );
		// console.log( this.state.idFase, this.state.newEstado, this.state.newObservacion, this.state.newFechaP );

		const styles = {

			titleClient: {
				color: "rgba(89, 91, 101)",
				fontFamily: 'Crimson Text',
				fontSize: '30px',
				fontWeight: '700',
				margin: "25px auto 0",
			},
			containerCardsInfo: {

			},

			separatorTitle: {
				marginTop: '8px',
				boxShadow: "0 0 9px rgba(0, 0, 0, 0.3)",
			}

		};


		var space_text = " ";
		var render_alert_status_upload_doc = null;
		var render_no_results_docs = null;
		var render_charging_docs = null;
		var render_docs_case = null;
		var render_users_caso = null;
		var render_users_caso_2 = null;
		var render_events_caso = null;
		var render_events_caso_2 = null;
		var render_fases_caso = null;
		var render_clientes_associates = null;
		var render_honorarios = null;
		var counter_eventos = 0;
		var counter_acciones = 0;
		var render_requisitos = null;
		var render_idsfases_caso = null;

		var render_costo_total = 0;
		var render_gasto_total = 0;

		var ToastsDisplay = null;


		if( this.state.alert_updated_estado_caso ){

			return(
				<Alert style={{ marginTop: '20px' }} color="success">Se actualizó el caso correctamente!</Alert>
			);

		}

		if( this.state.alert_error_updating ){

			return(
				<Alert style={{ marginTop: '20px' }} color="danger">Error actualizando el caso!</Alert>
			);

		}

		if( this.state.alert_status_upload_doc == 1 ){

			render_alert_status_upload_doc = <Alert style={{ marginTop: '2px' }} color="success">Se agregó el documento!</Alert>;

		}

		if( this.state.alert_status_upload_doc == 2 ){

			render_alert_status_upload_doc = <Alert style={{ marginTop: '2px' }} color="danger">Error al subir el documento!</Alert>;
			//
		}

		if( this.state.no_results_docs ){

			render_no_results_docs = <p style={{ marginTop: '12px' }} >Sin documentos archivados!</p>;
			//

		}

		if( this.state.alert_status_upload_doc == 3 ){

			render_alert_status_upload_doc = <Alert style={{ marginTop: '2px' }} color="warning">Seleccione un documento y asigne un nombre!</Alert>;
			//

		}


		if( this.state.isLoadingDocs ){

			render_charging_docs = <Spinner color="success" />;

		}

		if( this.state.docsCase.length > 0 ){

			render_docs_case = this.state.docsCase.map((item, index) =>

				<span style={{ margin: 'auto 20px', position: 'relative' }}>
					<a href={item.url_documento} target="_blank" rel="noopener noreferrer" className="ellipsisStyle">
						<FaFileExport />
						{item.estatus}
					</a>
					<span 
							className="boton-accion-sticky-delete"
							style={{ width: '15px', height: '15px', right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
							onClick={ () => this.minusDocument(item.id_documento) } 
							>
								<FaMinus style={{ color: '#FFF' }} size={9} />
					</span>
				</span>

			);

		} else{
			render_docs_case = null;
		}

		if( !this.state.isLoadingCaseUsers ){

			if( this.state.UsersCaseMsg == 'No Results.' ){
				render_users_caso = <option> Sin usuarios añadidos </option>;
				//
			}else if( this.state.dataSourceArrayUsersCase.length > 0 ){
				render_users_caso = this.state.dataSourceArrayUsersCase.map( (item, index) =>

	      			<option key={index} selected> {item.rol}: {item.nombre} </option>

	      		);
	      		//
	      		render_users_caso_2 = this.state.dataSourceArrayUsersCase.map( (item, index) =>

	      			<option key={index} value={item.id_usuario}> {item.nombre} </option>

	      		);
	      		//
			}
			
		}

		if( this.state.isLoadingCaseEvents ){

      		render_events_caso = null;

      	}else if( this.state.dataSourceArrayEvents.length > 1 ){

      		render_events_caso = this.state.dataSourceArrayEvents.map( (item, index) =>{

      			if( item.evento != 'REGISTRO DE CASO' && item.tipo_evento == 1 ){
      				counter_eventos++;
      				return(
      					<tr key={index} id={index}> <th>{item.evento}</th> <th style={{ color: item.estado == 'COMPLETADO' ? " #58d68d" : '#FFF' }}>{item.estado}</th> <th>{item.fecha}</th> <th>{item.hora}</th> </tr>
      				);
      			}

      		});

      		render_events_caso_2 = this.state.dataSourceArrayEvents.map( (item, index) =>{

      			if( item.evento != 'REGISTRO DE CASO' && item.tipo_evento == 2 ){
      				counter_acciones++;
      				return(
      					<tr id={index}> 
      						<th>{item.evento}</th> <th>{item.descripcion}</th> <th>{item.fecha}</th> 
      						<th style={{ position: 'relative' }}>
      							{item.hora}
      							<span 
									className="boton-accion-sticky-delete"
									style={{ width: '15px', height: '15px', right: '15px', top: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
									onClick={ () => this.minusAccion(item.id_evento_caso) } 
									>
										<FaMinus style={{ color: '#FFF' }} size={9} />
								</span>
      						</th> 
      					</tr>
      				);
      			}

      		});

      	}

      	if( this.state.isLoadingFases ){

      		render_fases_caso = null;


      	}else if( this.state.fases_arr.length > 0 ){

      		render_fases_caso = this.state.fases_arr.map( (item, index) =>{

      			var faseId = item.id_fase;

      			// componente de control de vista de etapa
      			var control_etapa = (
      			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<div className="resume-caso-ctrl-view" style={{  }} 
							onClick={ () => {  this.state.ctrlFasesArr[index] = !this.state.ctrlFasesArr[index]; this.onChangeTitleViewCase(item.fase); } } >
						<h5> {item.fase} </h5>
						<span>{ this.state.ctrlFasesArr[index] ? '-' : '+' }</span>
					</div>
				</div> );

				
				// Render de acciones asociadas a esta etapa
				var acciones_asociadas = null;
				if( this.state.isLoadingCaseEvents ){
					acciones_asociadas = null
				}else if( this.state.dataSourceArrayEvents.length > 1 ){

					acciones_asociadas = this.state.dataSourceArrayEvents.map( (item, index) =>{

						// console.log( item.flag_etapa, faseId );
						// console.log( item );
						var uriRender = item.uri_documento === '' ? item.evento : (<a href={ item.uri_documento } target="_blank" rel="noreferrer noopener">{ item.evento }</a>);

		      			if( item.evento != 'REGISTRO DE CASO' && item.flag_etapa == faseId && item.tipo_evento == 2 ){

		      				return(
		      					<tr id={index} onClick={ () => { }}> 
		      						<th>{ uriRender }</th> 
		      						<th><Input type="textarea" value={item.descripcion} /></th> 
		      						<th>
		      							{
		      								item.accion_estado !== "Si" 
		      								?
		      								<ButtonGroup style={{ }}>
										      <Button style={{ backgroundColor: '#c0392b' }} onClick={ () => {  } }>No</Button>
										    </ButtonGroup>
		      								: 
		      								<ButtonGroup style={{ }}>
										      <Button style={{ backgroundColor: '#17a589' }} onClick={ () => {  } }>Sí</Button>
										    </ButtonGroup>
		      							}
		      						</th>
		      						<th>{item.fecha}</th> 
		      						<th>
		      							{item.hora}
		      						</th> 
		      						<th>
		      							<Button color="primary" onClick={ () => { this.setAccionUpdateFile( item.id_evento_caso ) } }><FaFileExport style={{ color: '#FFF' }} size={15} /></Button>
		      						</th>
		      						<th style={{ position: 'relative' }}>
		      							<Button color="primary" onClick={ () => { this.setVarsAccionUpdate( item.id_evento_caso, item.descripcion, item.evento, item.fecha, item.hora, item.accion_estado, item.accion_prioridad, item.descripcion_adicional ) } }><FaAlignJustify style={{ color: '#FFF' }} size={15} /></Button>
		      							<span 
											className="boton-accion-sticky-delete"
											style={{ width: '15px', height: '15px', right: '0px', top: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
											onClick={ () => this.minusAccion(item.id_evento_caso) } 
											>
												<FaMinus style={{ color: '#FFF' }} size={9} />
										</span>
		      						</th>
		      					</tr>
		      				);
		      			}

		      		});

				}


				// incrementadores de costo y gasto por etapa
				render_costo_total = parseFloat(render_costo_total) + parseFloat(item.costo);
				render_gasto_total = parseFloat(render_gasto_total) + parseFloat(item.gasto);


				// render de toda la etapa 
      			return(
      				<div style={{ width: '100%' }}>
	      				{ control_etapa }
	      				<div style={{ width: '100%', display: this.state.ctrlFasesArr[index] ? 'block' : 'none', borderBottom: 'solid 0.6px #ddd', padding: '30px 0 10px' }} >
	      					<Form style={{ width: '100%' }}>
	      						<FormGroup row className="tabla_eventos_resumen" style={{ borderRadius: '8px', margin: '20px 0' }}>
								  	<Table striped responsive style={{ borderRadius: '8px' }}>
								      <thead>
								        <tr>
								          <th>Completado</th>
								          <th>Observación</th>
								          <th style={{ display: 'none' }} >Fecha proyectada</th>
								          <th>Costo</th>
								          <th>Gasto</th>
								          <th>Actualizar</th>
								        </tr>
								      </thead>
								      <tbody>
						      			<tr id={index}> 
						      				<th>
						      					<ButtonGroup style={{ }}>
											      <Button style={{ backgroundColor: item.estado == 'OK' ? '#17a589' : '' }} onClick={ () => { this.setState({ newEstado: 'OK' }) } }>Sí</Button>
											      <Button style={{ backgroundColor: item.estado == 'NO' ? '#c0392b' : '' }} onClick={ () => { this.setState({ newEstado: 'NO' }) } }>No</Button>
											    </ButtonGroup>
						      				</th> 
						      				<th style={{ width: '200px' }}>
												<Input
											          type="textarea"
											          placeholder="Observación"
											          value={item.descripcion_fase}
											          onChange={ (Valor) => { this.setState({ newObservacion: Valor.target.value }) } }
											        />
						      				</th> 
						      				<th style={{ maxWidth: '190px', display: 'none' }}>
						      					<Input
											          type="date"
											          placeholder="Fecha proyectada"
											          value={ item.fecha_proyeccion }
											          onChange={ (Valor) => { this.setState({ newFechaP: Valor.target.value }) } }
											        />
						      				</th> 
						      				<th>{item.costo}</th><th>{item.gasto}</th>
						      				<th>
						      					<Button color="info" onClick={ () => { this.setVarsFaseUpdate(item.id_fase, item.fase, item.estado, item.descripcion_fase, item.fecha_proyeccion, item.costo, item.gasto, item.resumen_general) } }>Actualizar</Button>
						      				</th> 
						      			</tr>
						      		  </tbody>
								    </Table>
							  	</FormGroup>
			      			</Form>
			      			<Form>
			      				<Input
			      					style={{ height: '150px' }}
									type="textarea"
									placeholder="Resumen de la etapa"
									value={item.resumen_general}
									/>
			      			</Form>
		      			</div>
		      			<div style={{ width: '100%', display: this.state.ctrlFasesArr[index] ? 'block' : 'none', padding: '5px 0 5px' }} >
		      				<Form>
								<div style={{ width: '100%', display: 'flex', }}>
					      			<h5 style={{ margin: '10px 0', textAlign: 'center', fontWeight: 'bold' }}>Acciones</h5>
					      		</div>
								<FormGroup row className="tabla_eventos_resumen" style={{ borderRadius: '8px', margin: '20px 0px' }}>
								  	<Table striped responsive style={{ borderRadius: '8px' }}>
								      <thead>
								        <tr>
								          <th>Acción</th>
								          <th>Descripción</th>
								          <th>Estado</th>
								          <th>Fecha</th>
								          <th>Hora</th>
								          <th><FaFileExport size={15} /></th>
								          <th><FaAlignJustify size={15} /></th>
								        </tr>
								      </thead>
								      <tbody>
								        { acciones_asociadas }
								      </tbody>
								    </Table>
							  	</FormGroup>
						  	</Form>
		      			</div>
		      		</div>
	      		);

      		});

      		render_idsfases_caso = this.state.fases_arr.map( (item, index) => 
				
				<option key={index} value={item.id_fase}> {item.fase} </option>
      		
      		);

      	}else {
      		render_fases_caso = null;
      	}

      	if( this.state.fases_arr.length > 0 ){
      		
  			var ToastsDisplayRender = null;
  			if( this.state.dataSourceArrayEvents.length > 1 ){

  				ToastsDisplayRender = this.state.dataSourceArrayEvents.map( (item, index) =>{
	      			
	      			if( item.evento != 'REGISTRO DE CASO' && item.tipo_evento == 2 && item.accion_estado != "Si" ){

	      				return(
	      					<UncontrolledAlert style={{ marginBottom: '10px' }} color={ item.accion_prioridad == "urgente" ? "danger" : "warning" }>
						      Pendiente: {item.evento}  
						    </UncontrolledAlert>
	      				);

	      			}else{
	      				return null;
	      			}

	      		});
	      		
			}

      		ToastsDisplay = ToastsDisplayRender;

      	}else{
      		ToastsDisplay = null;
      	} 

      	if( this.state.isLoadingCasos ){

      		render_clientes_associates = null;


      	}else if( this.state.dataSourceCasos.length > 0 ){

      		// render_clientes_associates = this.state.dataSourceCasos.map( (item, index) =>

      		// 	<tr id={index} onClick={ () => { this.viewClientOpen(index) } }> 
      		// 		<th><h3>{item.cliente}</h3></th>
      		// 	</tr>

      		// );

      		render_clientes_associates = this.state.dataSourceCasos.map( (item, index) =>{

      			// console.log( item );
      			if( index == this.state.dataSourceCasos.length - 1 ){
      				return( <h5 onClick={ () => { this.viewClientOpen(index) } }>{item.cliente}</h5> );
      			}else{
      				return( <h5 style={{ marginRight: '6px' }} onClick={ () => { this.viewClientOpen(index) } }>{item.cliente},</h5> );
      			}

      		});

      	} else {
      		render_clientes_associates = null;
      	}

      	if( this.state.dataSourceArrayHonorarios.length > 0 ){

      		render_honorarios = this.state.dataSourceArrayHonorarios.map( (item, index) =>
      			
  				
  					<tr id={index} onClick={ () => { }} style={{ position: 'relative' }}> 
  						<th>{item.concepto}</th> <th>{item.honorario}</th> 
  						<th><Input type="date" value={item.fecha_pactada} /></th> 
  						<th>
  							<ButtonGroup style={{ }}>
						      <Button style={{ backgroundColor: item.estado == 1 ? '#17a589' : '' }} onClick={ () => {  } }>Sí</Button>
						      <Button style={{ backgroundColor: item.estado == 0 ? '#c0392b' : '' }} onClick={ () => {  } }>No</Button>
						    </ButtonGroup>
  						</th> 
  					</tr>
      			
      		);

      	}else{
      		render_honorarios = null;
      	}


      	if( this.state.isLoadingRequisitos ){

      		render_requisitos = null;


      	}else if( this.state.requisitos_arr.length > 0 ){

      		render_requisitos = this.state.requisitos_arr.map( (item, index) =>{

      			var uriRender = item.uri_documento === '' || item.uri_documento === null ? item.requisito : (<a href={ item.uri_documento } target="_blank" rel="noreferrer noopener">{ item.requisito }</a>);

  				return(
  					<tr key={index} id={index} > 
  						<th onClick={ () => { } }>{ uriRender }</th>
  						<th onClick={ () => { } }>
  							<ButtonGroup style={{ }}>
						      <Button style={{ backgroundColor: item.estado_requisito == 'SI' ? '#17a589' : '' }} onClick={ () => {  } }>Sí</Button>
						      <Button style={{ backgroundColor: item.estado_requisito == 'NO' ? '#c0392b' : '' }} onClick={ () => {  } }>No</Button>
						    </ButtonGroup>
  						</th> 
  						<th>
  							<Button color="primary" onClick={ () => { this.setRequisitoUpdateFile( item.id_requisito ) } }><FaFileExport style={{ color: '#FFF' }} size={15} /></Button>
  						</th>
  						<th>
  							<Button color="primary" onClick={ () => { this.setVarsRequisitoUpdate(item.id_requisito, item.requisito, item.estado_requisito, item.observaciones); } }><FaAlignJustify style={{ color: '#FFF' }} size={15} /></Button>
  						</th>
  						<th style={{ position: 'relative' }}>
  							<Input type="text" value={item.observaciones} 
  									onClick={ () => { } }
  									/>
  							<span 
								className="boton-accion-sticky-delete"
								style={{ width: '15px', height: '15px', right: '10px', top: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
								onClick={ () => this.minusRequisito(item.id_requisito) } 
								>
									<FaMinus style={{ color: '#FFF' }} size={9} />
							</span>
  						</th> 
  					</tr>
  				);

      		});

      	} else {
      		render_requisitos = null;
      	}


		return(

			<div className="panel-just-selected-clientes-view">

				{ render_alert_status_upload_doc }

				<h3 style={ styles.titleClient } > {this.state.title_case_infoCase} </h3>
				<hr style={ styles.separatorTitle } />

				<div style={{ width: '70%', position: 'absolute', right: '20px' }}>
					{ ToastsDisplay }
				</div>

				<div className="container-resume-caso" >

					<span className="float-addfase-component" onClick={ () => { this.setState({ modalFasesAdd: !this.state.modalFasesAdd }); } }>Añadir etapa</span>
					<span className="float-addaction-component" 
							style={{ borderTopLeftRadius: this.props.casoActual.nombre_fase_II === 'ND' || this.state.nuevo_nombre_etapa2 === 'ND' ? '4px' : '0', 
									 borderBottomLeftRadius: this.props.casoActual.nombre_fase_II === 'ND' || this.state.nuevo_nombre_etapa2 === 'ND' ? '4px' : '0' }}
							onClick={ () => { this.setState({ modalActionAdd: !this.state.modalActionAdd }); } }>Añadir acción</span>
					<span className="float-additem-component" 
							style={{ display: this.props.casoActual.nombre_fase_II === 'ND' || this.state.nuevo_nombre_etapa2 === 'ND' ? 'none' : 'flex' }}
							onClick={ () => { this.setState({ modalItemAdd: !this.state.modalItemAdd }); } }>Añadir requisito</span>

					<div style={{ width: '100%', display: 'flex', marginBottom: '20px' }}>

						<Modal isOpen={this.state.modal} >
					        <ModalHeader >Añadir comentarios (Opcional)</ModalHeader>
					        <ModalBody>
					          	<Form inline>
							      <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
							        <Label for="comment" className="mr-sm-2">Comentarios:</Label>
							        <Input type="text" name="comment" id="comment" placeholder="Puede añadir comentarios." 
							        		onChange={ (text) => { this.setState({comment: text.target.value}) } }/>
							      </FormGroup>
							    </Form>
					        </ModalBody>
					        <ModalFooter>
					          <Button color="primary" onClick={ () => { this.updateStateCaso( this.props.casoActual.id_caso, 2 ); } }>Mover a inactivo</Button>
					          <Button color="secondary" onClick={ () => { this.setState({modal: !this.state.modal}); } }>Cancelar</Button>
					        </ModalFooter>
      					</Modal>

						<div style={{ width: '100%' }}>

							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
								<Button outline
									color="primary"
									style={{ float: 'inline-end', margin: 'auto 5px', backgroundColor: this.props.casoActual.estatus == 1 ? "#007bff" : "", color: this.props.casoActual.estatus == 1 ? "#fff" : "" }}
									onClick={ () => { this.updateStateCaso( this.props.casoActual.id_caso, 1 ); } }
								>
										Activo
								</Button>
								<Button outline
									color="secondary"
									style={{ float: 'inline-end', margin: 'auto 5px', backgroundColor: this.props.casoActual.estatus == 2 ? "#6c757d" : "", color: this.props.casoActual.estatus == 2 ? "#fff" : "" }}
									onClick={ () => { this.sendingCommentState(); } }
								>
										Inactivo
								</Button>
								<Button outline
									color="danger"
									style={{ float: 'inline-end', margin: 'auto 5px', backgroundColor: this.props.casoActual.estatus == 0 ? "#dc3545" : "", color: this.props.casoActual.estatus == 0 ? "#fff" : "" }}
									onClick={ () => { this.updateStateCaso( this.props.casoActual.id_caso, 0 ); } }
								>
										Cerrar caso
								</Button>
							</div>

						</div>
					</div>

					<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						<div className="resume-caso-ctrl-view caso-ctrl-view-element" style={{  }} 
								onClick={ () => { this.setState({ ctrlFaseI : !this.state.ctrlFaseI }); this.onChangeTitleViewCase('Datos Generales'); } } >
							<h5>DATOS GENERALES</h5>
							<span>{ this.state.ctrlFaseI ? '-' : '+' }</span>
						</div>
					</div>
					<div style={{ width: '100%', maxHeight: '300px', display: this.state.ctrlFaseI ? 'block' : 'none' }}>
						{
							this.state.isLoadingCasos

							?

							<Spinner color="primary" />

							:

							<Form style={{ height: '100%' }}>
								<FormGroup row className="" style={{ borderRadius: '', margin: '20px 0px', display: 'flex', justifyContent: 'center', borderBottom: 'solid 0.6px #ddd' }}>
								       <h5 style={{ marginRight: '8px' }}>CLIENTE(S): </h5> { render_clientes_associates }
							  	</FormGroup>
							  	<FormGroup row style={{ display: 'flex', justifyContent: 'center', borderBottom: 'solid 0 #ddd' }}>
							  	{
									this.props.casoActual.estatus == 2

									?
									
									<h5 style={{ textAlign: 'center', margin: '0 20px', width: '100%', borderBottom: 'solid 0.6px #ddd' }}>{ this.props.casoActual.comment_add }</h5>

									:

									null

								}
							  	</FormGroup>
						  	</Form>

						}
					</div>
					<div style={{ width: '100%', display: this.state.ctrlFaseI ? 'flex' : 'none', borderBottom: 'solid 0.6px #ddd', padding: '0 0 30px' }}>
						<div style={{ width: '50%' }}>
							<h4 style={{ margin: "10px auto", textAlign: "left", width: '100%', fontSize: '1.3rem' }}>
								Materia: { this.props.casoActual.tipo_caso }
							</h4>
							<br />
		      				<h4 style={{ textAlign: 'left', fontSize: '1.3rem', width: '100%' }}>
		      					Submateria: { this.props.casoActual.submateria }
		      				</h4>
		      				<br />
		      				<h4 style={{ textAlign: 'left', fontSize: '1.0rem', fontWeight: 'bold' }}>Descripción: </h4>
		      				<Input style={{ width: '95%', height: '175px' }} type="textarea" value={ this.props.casoActual.descripcion_general } />
		      				<Button 
		      						style={{ backgroundColor: 'rgba(0, 123, 255, 1.0)', color: '#FFF', margin: '10px auto 0' }}
		      						onClick={ () => { this.changeDescripcionCase( ); } }
		      					>
		      					Actualizar descripción
		      				</Button>
						</div>
						<div style={{ width: '50%',  }}>
							<h4 style={{ margin: "10px 0px", textAlign: "left", width: '100%', fontSize: '1.3rem', margin: '0 20px' }}>
								Ref.: { this.props.casoActual.codigo_caso }
							</h4>
							<br />
		      				{
		      					this.state.loadingApertura

		      					?

		      					null

		      					:

		      					<Spinner color="primary" />

		      				}
		      				{
		      					this.state.dataAperturaEvent.length > 0

		      					?

		      					<h6 style={{ textAlign: 'left', margin: '0 20px' }}>{ this.state.dataAperturaEvent[0].evento }  "{this.state.dataAperturaEvent[0].fecha} { this.state.dataAperturaEvent[0].hora }" </h6>

		      					:

		      					null

		      				}
		      				<br />
							{
								!this.state.isLoadingLastCaseAction

								?

								<h6 style={{ textAlign: 'left', margin: '0 20px' }}>{ this.state.dataSourceArrayLastAction[0].entrada_titulo.replace("?", "ó") } { this.state.dataSourceArrayLastAction[0].fecha_hora_entrada }</h6>

								:

								<Spinner color="primary" />

							}
							<br />
							{
								this.state.isLoadingCaseUsers

								?

								<Spinner color="primary" />

								:

								<Form style={{ height: 'auto', maxHeight: '150px',  }}>
									<FormGroup row style={{ margin: '0px 20px' }}>
								        <Label style={{ textAlign: 'left', paddingLeft: '0', paddingRight: '0', fontWeight: 'bold' }} for="usersCase" sm={12}>Participantes:</Label>
								        <Col sm={12} style={{ paddingLeft: '0' }}>
								          <Input type="select" name="usersCase" id="usersCase" multiple style={{ height: '117px' }}>
								            { render_users_caso }
								          </Input>
								        </Col>
							      	</FormGroup>
							    </Form>


							}
						</div>
					</div>

					<div style={{ width: '100%', justifyContent: 'center', display: this.props.casoActual.nombre_fase_II === 'ND' || this.state.nuevo_nombre_etapa2 === 'ND' ? 'none' : 'flex' }}>
						<div className="resume-caso-ctrl-view" style={{  }} 
								onClick={ () => { this.setState({ ctrlFaseII : !this.state.ctrlFaseII }); this.onChangeTitleViewCase('Documentación ó Requisitos'); } } >
							<h5>
								{ 
									this.props.casoActual.nombre_fase_II === '' || this.props.casoActual.nombre_fase_II === null
									?
									'ETAPA II'
									:
										this.state.nuevo_nombre_etapa2 !== ''
										?
										this.state.nuevo_nombre_etapa2
										:
										this.props.casoActual.nombre_fase_II
								}
							</h5>
							<span>{ this.state.ctrlFaseII ? '-' : '+' }</span>
						</div>
					</div>
					<div style={{ width: '100%', display: this.state.ctrlFaseII && this.state.nuevo_nombre_etapa2 !== 'ND' ? 'block' : 'none', borderBottom: 'solid 0.6px #ddd', padding: '30px 0 30px' }}>
						<div style={{ width: '100%' }} >
							{
								this.state.alert_changed_faseII
								?
								<Alert color="success">Cambio completo!</Alert>
								: 
								null
							}
							{
								this.state.alert_error_changing_faseII
								?
								<Alert color="warning">Error en el cambio!</Alert>
								: 
								null
							}
							<span style={{ color: '#138496' }} onClick={ () => { this.setState({ changeNombreEtapa: !this.state.changeNombreEtapa }) }}>
								{
									!this.state.changeNombreEtapa
									?
									'Cambiar nombre de etapa'
									:
									'Cerrar'
								}
							</span>
							<Form style={{ display: this.state.changeNombreEtapa ? 'block' : 'none' }}>
								<FormGroup row>
							        <Label for="newTitleFaseII" sm={2}>Etapa:</Label>
							        <Col sm={10}>
							          <Input type="text" name="newTitleFaseII" id="newTitleFaseII" placeholder="Nuevo nombre de etapa" 
							          			value={ this.state.newTitleFaseII }
							          			onChange={ (text) => this.setState({ newTitleFaseII: text.target.value.toUpperCase() }) }
							          			/>
							        </Col>
							    </FormGroup>
							    <FormGroup row>
							    	<Col sm={4}></Col>
							    	<Col sm={4}>
							       	 <Button style={{ backgroundColor: '#138496' }} onClick={ () => { this.changeNombreEtapa(); }}>Cambiar</Button>
							       	</Col>
							       	<Col sm={4}></Col>
							    </FormGroup>
							</Form>
						</div>
						<div style={{ width: '100%', height: '60px', display: (this.state.error_add_requisito || this.state.complete_add_requisito) ? 'flex' : 'none' }}>
							{	
								this.state.error_add_requisito
								?
								<Alert style={{ marginTop: '2px', width: '100%' }} color="danger">Error al añadir requisito!</Alert>
								:
								null
							}
							{
								this.state.complete_add_requisito
								?
								<Alert style={{ marginTop: '2px', width: '100%' }} color="success">Requisito añadido!</Alert>
								:
								null
							}
						</div>
						<div style={{ width: '100%', height: '60px', display: (this.state.error_add_requisito || this.state.complete_add_requisito) ? 'flex' : 'none' }}>
							{
								this.state.alert_error_updating_requisito

								?

								<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					      			<Alert style={{ marginTop: '2px' }} color="danger">Error actualizando requisito.</Alert>
					      		</div>

					      		:

					      		null
							}

							{
								this.state.alert_updated_requisito

								?

								<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					      			<Alert style={{ marginTop: '2px' }} color="success">Requisito actualizado.</Alert>
					      		</div>

					      		:

					      		null
							}
							{
								this.state.alert_error_deleting_requisito

								?

								<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					      			<Alert style={{ marginTop: '2px' }} color="danger">Error eliminando requisito.</Alert>
					      		</div>

					      		:

					      		null
							}

							{
								this.state.alert_deleting_requisito

								?

								<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					      			<Alert style={{ marginTop: '2px' }} color="success">Requisito eliminado.</Alert>
					      		</div>

					      		:

					      		null
							}
						</div>
						{
				      		this.state.requisitos_arr.length > 0
				      		?
				      		<Form style={{ width: '100%', maxHeight: '300px', paddingRight: '15px' }}>
								<FormGroup row className="tabla_eventos_resumen" style={{ borderRadius: '8px', margin: '20px 0px' }}>
								  	<Table responsive style={{ borderRadius: '8px' }}>
								      <thead>
								        <tr>
								          <th>Tipo</th>
								          <th>Entregado</th>
								          <th><FaFileExport style={{ color: '#000' }} size={15} /></th>
								          <th>Modificar</th>
								          <th>Observación</th>
								        </tr>
								      </thead>
								      <tbody>
								        { render_requisitos }
								      </tbody>
								    </Table>
							  	</FormGroup>
						  	</Form>
						  	:
						  	<Form style={{ width: '100%', maxHeight: '300px',  }}>
								<FormGroup row className="tabla_eventos_resumen" style={{ borderRadius: '8px', margin: '20px 0px' }}>
								  	<Table striped responsive style={{ borderRadius: '8px' }}>
								      <thead>
								        <tr>
								          <th>Sin datos agregados</th>
								        </tr>
								      </thead>
								    </Table>
							  	</FormGroup>
						  	</Form>
				    	}
					</div>

					<div style={{ width: '100%' }}>
						{
							this.state.alert_error_updating_fase

							?

							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				      			<Alert style={{ marginTop: '2px', width: '100%' }} color="danger">Error actualizando la fase.</Alert>
				      		</div>

				      		:

				      		null
						}

						{
							this.state.alert_updated_fase

							?

							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				      			<Alert style={{ marginTop: '2px', width: '100%' }} color="success">Fase Actualizada.</Alert>
				      		</div>

				      		:

				      		null
						}

						{
							this.state.actualizando_fase_load

							?

							<Spinner color="primary" />

							:

							null
						}
						<div style={{ width: '100%', height: '60px', display: (this.state.alert_error_addingfase || this.state.alert_addingfase_ok) ? 'flex' : 'none' }}>
							{	
								this.state.alert_error_addingfase
								?
								<Alert style={{ marginTop: '2px', width: '100%' }} color="danger">Error al añadir fase!</Alert>
								:
								null
							}
							{
								this.state.alert_addingfase_ok
								?
								<Alert style={{ marginTop: '2px', width: '100%' }} color="success">Fase añadida!</Alert>
								:
								null
							}
						</div>
						{
							this.state.alert_updated_estado_eve
							?
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				      			<Alert style={{ marginTop: '2px', width: '100%' }} color="success">Acción Actualizada.</Alert>
				      		</div>
				      		:
				      		null
						}
						{
							this.state.alert_error_updating
							?
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				      			<Alert style={{ marginTop: '2px', width: '100%' }} color="danger">Acción no actualizada.</Alert>
				      		</div>
				      		:
				      		null
						}
					</div>
					<div style={{ width: '100%' }}>
						<div style={{ width: '100%', display: this.state.actualizando_fase_load ? 'none' : 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: '25px' }} >
			      			{
									this.state.isLoadingFases

									?

									<Spinner color="primary" style={{ alignSelf: 'center' }} />

									:
											render_fases_caso == null

											?
											null
											:
											render_fases_caso

							}
			      		</div>
					</div>

					<div style={{ width: '100%', display: 'none', justifyContent: 'center' }}>
						<div className="resume-caso-ctrl-view" style={{  }} 
								onClick={ () => { this.setState({ ctrlFaseIV : !this.state.ctrlFaseIV }); } }>
							<h5>Fase IV</h5>
							<span>{ this.state.ctrlFaseIV ? '-' : '+' }</span>
						</div>
					</div>
					<div style={{ width: '100%', display: this.state.ctrlFaseIV ? 'block' : 'none', borderBottom: 'solid 0.6px #ddd', padding: '30px 0 30px' }}>
						<div style={{ width: '100%', height: '60px', display: (this.state.msj_done_save_edit || this.state.warning_nada_x_actualizar) ? 'flex' : 'none' }}>
							{	
								this.state.msj_done_save_edit
								?
								<Alert style={{ marginTop: '2px', width: '100%' }} color="success">Añadida la accion!</Alert>
								:
								null
							}
							{
								this.state.warning_nada_x_actualizar
								?
								<Alert style={{ marginTop: '2px', width: '100%' }} color="danger">Error añadiendo acción!</Alert>
								:
								null
							}
						</div>

						<div style={{ width: '100%', height: '60px', display: (this.state.alert_deleting_event || this.state.alert_error_deleting_event) ? 'flex' : 'none' }}>
							{	
								this.state.alert_deleting_event
								?
								<Alert style={{ marginTop: '2px', width: '100%' }} color="success">Eliminada la accion!</Alert>
								:
								null
							}
							{
								this.state.alert_error_deleting_event
								?
								<Alert style={{ marginTop: '2px', width: '100%' }} color="danger">Error eliminando acción!</Alert>
								:
								null
							}
						</div>

						<div style={{ width: '100%' }}>
							{
								this.state.isLoadingCaseEvents

								?

								<Spinner color="primary" />

								:

								null

							}
							{
								counter_acciones < 1

								?

								<Form>
									<div style={{ width: '100%', display: 'flex', }}>
						      			<h6 style={{ margin: '10px 0', textAlign: 'left', fontWeight: 'bold' }}>Fase IV</h6>
						      		</div>
									<FormGroup row className="tabla_eventos_resumen" style={{ borderRadius: '8px', margin: '20px 0px' }}>
									  	<Table striped responsive style={{ borderRadius: '8px' }}>
									      <thead>
									        <tr>
									          <th>Sin datos agregados</th>
									        </tr>
									      </thead>
									    </Table>
								  	</FormGroup>
							  	</Form>

								:

								<Form>
									<div style={{ width: '100%', display: 'flex', }}>
						      			<h6 style={{ margin: '10px 0', textAlign: 'left', fontWeight: 'bold' }}>Fase IV</h6>
						      		</div>
									<FormGroup row className="tabla_eventos_resumen" style={{ borderRadius: '8px', margin: '20px 0px' }}>
									  	<Table striped responsive style={{ borderRadius: '8px' }}>
									      <thead>
									        <tr>
									          <th>Acción</th>
									          <th>Descripción</th>
									          <th>Fecha</th>
									          <th>Hora</th>
									        </tr>
									      </thead>
									      <tbody>
									        { render_events_caso_2 }
									      </tbody>
									    </Table>
								  	</FormGroup>
							  	</Form>

							}
						</div>
					</div>

					<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						<div className="resume-caso-ctrl-view" style={{  }} 
								onClick={ () => { this.setState({ ctrlFaseV : !this.state.ctrlFaseV }); this.onChangeTitleViewCase('Honorarios'); } } >
							<h5>HONORARIOS</h5>
							<span>{ this.state.ctrlFaseV ? '-' : '+' }</span>
						</div>
					</div>
					<div style={{ width: '100%', display: this.state.ctrlFaseV ? 'block' : 'none', borderBottom: 'solid 0.6px #ddd', padding: '30px 0 30px', }}>
						<div style={{ width: '100%' }}>
						<Form style={{ width: '100%', maxHeight: '300px', paddingRight: '15px' }}>
							<FormGroup row className="tabla_eventos_resumen" style={{ borderRadius: '8px', margin: '20px 0px' }}>
							  	<Table bordered style={{ borderRadius: '8px' }}>
							      <thead>
							        <tr>
							          <th>Costos totales</th>
							          <th>Gastos totales</th>
							        </tr>
							      </thead>
							      <tbody>
							        <tr>
							          <td>{ parseFloat(render_costo_total) }</td>
							          <td>{ parseFloat(render_gasto_total) }</td>
							        </tr>
							      </tbody>
							    </Table>
						  	</FormGroup>
					  	</Form>
			      		{
					      		this.state.dataSourceArrayHonorarios.length > 0
					      		?
					      		<Form style={{ width: '100%', maxHeight: '300px', paddingRight: '15px' }}>
					      			<h6 style={{ margin: '10px auto', textAlign: 'left', fontWeight: 'bold' }}>Honorarios:</h6>
									<FormGroup row className="tabla_eventos_resumen" style={{ borderRadius: '8px', margin: '20px 0px' }}>
									  	<Table responsive style={{ borderRadius: '8px' }}>
									      <thead>
									        <tr>
									          <th>Concepto</th>
									          <th>Valor</th>
									          <th>Fecha</th>
									          <th>Pagado</th>
									        </tr>
									      </thead>
									      <tbody>
									        { render_honorarios }
									      </tbody>
									    </Table>
								  	</FormGroup>
							  	</Form>
							  	:
							  	null
					    }
					    </div>
					</div>

					<div style={{ width: '100%', display: 'none' }}>

						<div style={{ width: '50%', display: 'none' }}>
							<p style={{ justifyContent: "center", textAlign: "justify", marginTop: "10px" }}>
			      				{ space_text } 
			      				<h6>{ (this.props.casoActual.tipo_cliente == 'PERSONA NATURAL') ? 'DUI: ' + this.state.dui_vista : 'NRC: ' + this.props.casoActual.nrc }.</h6>
				      			<br />
				      			<h6>NIT: { this.state.nit_vista }.</h6>
				      			<br />
				      			<h6>Domicilio: { this.props.casoActual.direccion }.</h6>
				      			<br />
				      			{
				      				(this.props.casoActual.tipo_cliente != 'PERSONA NATURAL') 

				      				?

				      				<h6>Representante: { this.props.casoActual.representante_legal }</h6>

				      				:

				      				null
				      			}
				      			{
				      				(this.props.casoActual.tipo_cliente != 'PERSONA NATURAL') 

				      				?

				      				<br />

				      				:

				      				null
				      			}
				      			<h6>Teléfono: { this.props.casoActual.numero_telefonico }.</h6>
				      			<br />
				      			<h6>Correo: { this.props.casoActual.email }.</h6>
								<br />
								<h6>Materia: { this.props.casoActual.tipo_caso }.</h6>
								<br />
								<h6>Calidad: { this.props.casoActual.calidad }.</h6>
								<br />
								<h6>
								Descripción general:
								<br />
								<span className="box-description-case" style={{ width: '80%' }}>
									{ this.props.casoActual.descripcion_general }
								</span>
								</h6>
			      			</p>
		      			</div>

		      			<div style={{ width: '50%', borderLeft: 'solid 1px #ddd', paddingLeft: '5px', display: 'none' }}>
		      				
							<br />
							<br />
							{
								this.state.isLoadingCaseEvents

								?

								<Spinner color="primary" />

								:

								<Form>
									<div style={{ width: '100%', display: 'flex', }}>
						      			<h6 style={{ margin: '10px 0', textAlign: 'left', fontWeight: 'bold' }}>Eventos del caso</h6>
						      		</div>
									<FormGroup row className="tabla_eventos_resumen" style={{ borderRadius: '8px', margin: '20px 0px' }}>
									  	<Table responsive style={{ borderRadius: '8px' }}>
									      <thead>
									        <tr>
									          <th>Evento</th>
									          <th>Estado</th>
									          <th>Fecha</th>
									          <th>Hora</th>
									        </tr>
									      </thead>
									      <tbody>
									        { render_events_caso }
									        { counter_eventos < 1 ? <tr><th>No disponible</th></tr> : null }
									      </tbody>
									    </Table>
								  	</FormGroup>
							  	</Form>

							}
							<br />
							<br />
							
							<br />

		      			</div>
					</div>

					<div style={{ width: '100%' }}>
							<div style={{ width: '100%', height: 'auto', maxHeight: '200px', overflowY: 'scroll' }}>
								<Modal isOpen={this.state.modalViewClient} >
							        <ModalHeader >Información de cliente</ModalHeader>
							        <ModalBody style={{ justifyContent: 'end' }}>
						        		<Form inline>
									      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
									        <Label className="mr-sm-2">Cliente: { this.state.varCliente }</Label>
									      </FormGroup>
									      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
									        <Label className="mr-sm-2"><a href={this.state.varUrlNit} target="_blank" rel="noopener noreferrer">NIT: { this.state.varNit } </a></Label>
									      </FormGroup>		
									      {
									      	this.state.varTipo == "PERSONA NATURAL"

									      	?

									      	<FormGroup style={{ width: '370px', justifyContent: 'end'}}>
										    	<Label className="mr-sm-2"><a href={this.state.varUrlDui} target="_blank" rel="noopener noreferrer" >DUI: {this.state.varDui}</a></Label>
										    </FormGroup>

										    :

										    null
									      }
									      {
									      	this.state.varTipo == "PERSONA NATURAL"

									      	?
									      	null
									      	:
									      	<FormGroup style={{ width: '370px', justifyContent: 'end'}}>
										        <Label className="mr-sm-2"><a href={this.state.varUrlIva !== null && this.state.varUrlIva !== '' ? this.state.varUrlIva : null } target="_blank" rel="noopener noreferrer" >NRC: { this.state.varNrc }</a></Label>
										    </FormGroup>
									      }	
									      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
									        <Label className="mr-sm-2" style={{ width: '40%', justifyContent: 'start' }}>Telefono: </Label>
									        <Input type="text" value={ this.state.varNumero }/>
									      </FormGroup>		
									      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
									        <Label className="mr-sm-2" style={{ width: '40%', justifyContent: 'start' }}>Email: </Label>
									        <Input type="text" value={ this.state.varEmail } />
									      </FormGroup>									      
									      {
									      	this.state.varTipo == "PERSONA NATURAL"

									      	?
									      	null
									      	:
									      	<FormGroup style={{ width: '370px', justifyContent: 'end'}}>
										        <Label className="mr-sm-2" style={{ width: '40%', justifyContent: 'start' }}>Representante: </Label>
										        <Input type="text" value={ this.state.varRepresentante } />
										    </FormGroup>
									      }
									      {
									      	this.state.varTipo == "PERSONA NATURAL"

									      	?
									      	null
									      	:
									      	<FormGroup style={{ width: '370px', justifyContent: 'end'}}>
										        <Label className="mr-sm-2" style={{ width: '40%', justifyContent: 'start' }}>Telefono R.: </Label>
										        <Input type="text" value={ this.state.varNumeroRepre } />
										    </FormGroup>
									      }	
									      {
									      	this.state.varTipo == "PERSONA NATURAL"
									      	?
									      	null
									      	:
									      	<FormGroup style={{ width: '370px', justifyContent: 'end'}}>
										        <Label className="mr-sm-2" style={{ width: '40%', justifyContent: 'start' }}>Email R.: </Label>
										        <Input type="text" value={ this.state.varMailRepre } />
										    </FormGroup>
									      }		
									      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
									        <Label className="mr-sm-2" style={{ width: '40%', justifyContent: 'start' }}>Dirección: </Label>
									        <Input type="textarea" value={ this.state.varDireccion } />
									      </FormGroup>
									      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
									        <Label className="mr-sm-2" style={{ width: '40%', justifyContent: 'start' }}>Descripción: </Label>
									        <Input type="textarea" value={ this.state.varDescripA } />
									      </FormGroup>								      	      
									    </Form>
							        </ModalBody>
							        <ModalFooter style={{ }}>	
							          <Button color="secondary" onClick={ () => { this.setState({modalViewClient: !this.state.modalViewClient}); } }>Cerrar</Button>
							        </ModalFooter>
		      					</Modal>
							</div>
					</div>

					<div style={{ width: '100%', display: 'flex', justifyContent: 'center', }}>
		      			<Modal isOpen={this.state.modalFasesAdd} >
					        <ModalHeader >Añadir etapa</ModalHeader>
					        <ModalBody style={{ justifyContent: 'center' }}>
					        	{
					        		this.state.loadingAddFase

					        		?

					        		<Spinner color="primary" />

					        		:

					        		<Form inline className="ajustar-forms-modales">
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="fase" className="mr-sm-2">Etapa:</Label>
								        <Input type="text" name="fase" id="fase" placeholder="Añada una etapa" 
								        		style={{ width: '220px' }}
								        		onChange={ (text) => { this.setState({fase: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="estado" className="mr-sm-2">Completado:</Label>
							         	<ButtonGroup style={{ width: '220px' }}>
									      <Button style={{ backgroundColor: this.state.estado_fase == 'OK' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ estado_fase: 'OK' }) } }>Sí</Button>
									      <Button style={{ backgroundColor: this.state.estado_fase == 'NO' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ estado_fase: 'NO' }) } }>No</Button>
									    </ButtonGroup>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="observacion_fase" className="mr-sm-2">Observacion:</Label>
								        <Input type="textarea" name="observacion_fase" id="fase" placeholder="Añada una observacion" 
								        		style={{ width: '220px' }}
								        		onChange={ (text) => { this.setState({observacion_fase: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="fecha_proyectada" className="mr-sm-2">Fecha prevista: </Label>
								        <Input type="date" name="fecha_proyectada" id="fase" placeholder="Añada una fecha" 
								        		style={{ width: '220px' }}
								        		onChange={ (text) => { this.setState({fecha_proyectada: text.target.value}) } }/>
								      </FormGroup>
								    </Form>
					        	}
					        </ModalBody>
					        <ModalFooter style={{ display: this.state.loadingAddFase ? 'none' : '' }}>	
					          <Button color="primary" onClick={ () => { this.addFaseCaso( this.props.casoActual.id_caso ); } }>Agregar etapa</Button>
					          <Button color="secondary" onClick={ () => { this.setState({modalFasesAdd: !this.state.modalFasesAdd}); } }>Cancelar</Button>
					        </ModalFooter>
      					</Modal>
      					<Modal isOpen={this.state.modalActionAdd} >
					        <ModalHeader >Añadir acción</ModalHeader>
					        <ModalBody style={{ justifyContent: 'center' }}>
					        	{
					        		this.state.loadingAddAction

					        		?

					        		<Spinner color="primary" />

					        		:

					        		<Form inline className="ajustar-forms-modales">
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="accion" className="mr-sm-2">Acción:</Label>
								        <Input type="text" name="accion" id="fase" placeholder="Añada una accion" 
								        		style={{ width: '220px' }}
								        		onChange={ (text) => { this.setState({accion: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="accion_etapa_pertenece" className="mr-sm-2">Etapa:</Label>
								        <Input type="select" name="accion_etapa_pertenece" id="accion_etapa_pertenece"
								        		style={{ width: '220px' }}
								        		onChange={ (text) => { this.setState({accion_etapa_pertenece: text.target.value}) } }>

								        	<option value="no">Seleccione una etapa</option>
								        	{ render_idsfases_caso }
								        </Input>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="fecha_accion" className="mr-sm-2">Fecha:</Label>
							         	<Input type="date" name="fecha_accion" id="fecha_accion" placeholder="Fecha de la accion" 
								        		style={{ width: '220px' }}
								        		onChange={ (text) => { this.setState({fecha_accion: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="hora" className="mr-sm-2">Hora:</Label>
								        <Input type="time" name="hora_accion" id="hora_accion" placeholder="Hora de la accion" 
								        		style={{ width: '220px' }}
								        		onChange={ (text) => { this.setState({hora_accion: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="accion_prioridad" className="mr-sm-2">Prioridad:</Label>
								        <Input type="select" name="accion_prioridad" id="accion_prioridad"
								        		style={{ width: '220px' }}
								        		onChange={ (text) => { this.setState({accion_prioridad: text.target.value}) } }>

								        	<option value="no">Seleccione prioridad</option>
								        	<option value="urgente"> Urgente </option>
								        	<option value="no urgente"> No urgente </option>
								        	<option value="tramite"> Trámite </option>
								        </Input>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="accion_estado" className="mr-sm-2">Estado:</Label>
								        <ButtonGroup style={{ width: '220px' }}>
									      <Button style={{ backgroundColor: this.state.accion_estado == 'Si' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ accion_estado: 'Si' }) } }>Sí</Button>
									      <Button style={{ backgroundColor: this.state.accion_estado == 'No' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ accion_estado: 'No' }) } }>No</Button>
									    </ButtonGroup>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="descripcion_accion" className="mr-sm-2">Descripción: </Label>
								        <Input type="textarea" name="descripcion_accion" id="descripcion_accion" placeholder="Añada una descripción" 
								        		style={{ width: '220px' }}
								        		onChange={ (text) => { this.setState({descripcion_accion: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="user_assigned" className="mr-sm-2">Asignado a:</Label>
							          	<Input type="select" name="user_assigned" id="user_assigned" style={{ width: '220px' }}
							          			onChange={ (text) => { this.setState({user_assigned: text.target.value}) } }
							          			>
							          		<option value="no">Seleccione usuario</option>
							            	{ render_users_caso_2 }
							          	</Input>
							      	  </FormGroup>
							      	  <FormGroup style={{ width: '100%', justifyContent: 'center'}}>
							      	  	<Button style={{ 
							      	  						borderRadius: '30px',
														    fontWeight: '700',
														    marginTop: '20px' }} 
												onClick={ () => { this.setState({ modalUserCaseAdd: true }); } }
												color="info" >Agregar usuario al caso</Button>
							      	  </FormGroup>
							      	  {
											this.state.warning_nada_x_actualizar
											?
											<Alert style={{ marginTop: '10px', width: '100%' }} color="danger">Error añadiendo la acción!</Alert>
											:
											null
									  }
								    </Form>
					        	}
					        </ModalBody>
					        <ModalFooter style={{ display: this.state.loadingAddAction ? 'none' : '' }}>	
					          <Button color="primary" onClick={ () => { this.EditCase(); } }>Agregar accion</Button>
					          <Button color="secondary" onClick={ () => { this.setState({modalActionAdd: !this.state.modalActionAdd}); } }>Cancelar</Button>
					        </ModalFooter>
      					</Modal>
      					<Modal isOpen={this.state.modalItemAdd} >
					        <ModalHeader >Añadir elemento (requisito ó doc. solicitado)</ModalHeader>
					        <ModalBody style={{ justifyContent: 'center' }}>
					        	{
					        		this.state.loadingAddRequisito

					        		?

					        		<Spinner color="primary" />

					        		:

					        		<Form inline className="ajustar-forms-modales">
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="requisitoAdd" className="mr-sm-2">Elemento:</Label>
								        <Input type="text" name="requisitoAdd" id="requisitoAdd" placeholder="Añada un elemento" 
								        		style={{ width: '220px' }}
								        		onChange={ (text) => { this.setState({requisitoAdd: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="requisitoDescripcion" className="mr-sm-2">Descripción:</Label>
								        <Input type="textarea" name="requisitoDescripcion" id="requisitoDescripcion" placeholder="Añada una descripción" 
								        		style={{ width: '220px' }}
								        		onChange={ (text) => { this.setState({requisitoDescripcion: text.target.value}) } } />
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="requisitoStateAdd" className="mr-sm-2">Entregado:</Label>
							         	<ButtonGroup style={{ width: '220px' }}>
									      <Button style={{ backgroundColor: this.state.requisitoStateAdd == 'SI' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ requisitoStateAdd: 'SI' }) } }>Sí</Button>
									      <Button style={{ backgroundColor: this.state.requisitoStateAdd == 'NO' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ requisitoStateAdd: 'NO' }) } }>No</Button>
									    </ButtonGroup>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="requisitoObservacion" className="mr-sm-2">Observacion:</Label>
								        <Input type="textarea" name="requisitoObservacion" id="requisitoObservacion" placeholder="Añada una observacion" 
								        		style={{ width: '220px' }}
								        		onChange={ (text) => { this.setState({requisitoObservacion: text.target.value}) } }/>
								      </FormGroup>
								    </Form>
					        	}
					        </ModalBody>
					        <ModalFooter style={{ display: this.state.loadingAddRequisito ? 'none' : '' }}>	
					          <Button color="primary" onClick={ () => { this.addRequisitoCaso( this.props.casoActual.id_caso ); } }>Agregar elemento</Button>
					          <Button color="secondary" onClick={ () => { this.setState({modalItemAdd: !this.state.modalItemAdd}); } }>Cancelar</Button>
					        </ModalFooter>
      					</Modal>
		      		</div>

		      		<div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '0' }}>
		      			<Modal isOpen={this.state.modalFasesUpdate} >
					        <ModalHeader >Actualizar etapa</ModalHeader>
					        <ModalBody style={{ justifyContent: 'center' }}>
					        	{
					        		this.state.actualizando_fase_load

					        		?

					        		<Spinner color="primary" />

					        		:

					        		<Form inline>
					        		  <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
								        <Label for="newFase" className="mr-sm-2">Etapa:</Label>
								        <Input type="text" name="newFase" id="newFase" placeholder="Nombre de etapa" 
								        		value={ this.state.newFase }
								        		style={{ width: '267px' }}
								        		onChange={ (text) => { this.setState({newFase: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
								        <Label for="newEstado" className="mr-sm-2">Completado:</Label>
							         	<ButtonGroup style={{ width: '220px' }}>
									      <Button style={{ backgroundColor: this.state.newEstado == 'OK' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ newEstado: 'OK' }) } }>Sí</Button>
									      <Button style={{ backgroundColor: this.state.newEstado == 'NO' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ newEstado: 'NO' }) } }>No</Button>
									    </ButtonGroup>
								      </FormGroup>
								      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
								        <Label for="newObservacion" className="mr-sm-2">Observacion:</Label>
								        <Input type="textarea" name="newObservacion" id="newObservacion" placeholder="Observacion" 
								        		style={{ width: '220px' }} value={ this.state.newObservacion }
								        		onChange={ (text) => { this.setState({newObservacion: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
								        <Label for="newFechaP" className="mr-sm-2">Fecha prevista: </Label>
								        <Input type="date" name="newFechaP" id="newFechaP" placeholder="Fecha" 
								        		style={{ width: '220px' }} value={ this.state.newFechaP }
								        		onChange={ (text) => { this.setState({newFechaP: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
								        <Label for="newCosto" className="mr-sm-2">Costo fase ($):</Label>
								        <Input type="text" name="newCosto" id="newCosto" placeholder="Costo" 
								        		style={{ width: '220px' }} value={ this.state.newCosto }
								        		onChange={ (text) => { this.setState({newCosto: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
								        <Label for="newGasto" className="mr-sm-2">Gasto fase ($):</Label>
								        <Input type="text" name="newGasto" id="newGasto" placeholder="Gasto" 
								        		style={{ width: '220px' }} value={ this.state.newGasto }
								        		onChange={ (text) => { this.setState({newGasto: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
								        <Label for="newDescripcionAdicionalFase" className="mr-sm-2">Resumen fase:</Label>
								        <Input type="textarea" name="newDescripcionAdicionalFase" id="newDescripcionAdicionalFase" placeholder="Resumen de etapa" 
								        		style={{ width: '220px', height: '100px' }} value={ this.state.newDescripcionAdicionalFase }
								        		onChange={ (text) => { this.setState({newDescripcionAdicionalFase: text.target.value}) } }/>
								      </FormGroup>
								    </Form>
					        	}
					        </ModalBody>
					        <ModalFooter style={{ display: this.state.actualizando_fase_load ? 'none' : '' }}>	
					          <Button color="primary" onClick={ () => { this.changeFaseUpdate(); } }>Actualizar fase</Button>
					          <Button color="secondary" onClick={ () => { this.setState({modalFasesUpdate: !this.state.modalFasesUpdate}); } }>Cancelar</Button>
					        </ModalFooter>
      					</Modal>
      					<Modal isOpen={this.state.modalItemUpdate} >
					        <ModalHeader >Actualizar elemento (requisito ó doc. solicitado)</ModalHeader>
					        <ModalBody style={{ justifyContent: 'center' }}>
					        	{
					        		this.state.actualizando_requisito_load

					        		?

					        		<Spinner color="primary" />

					        		:

					        		<Form inline>
					        		  <FormGroup style={{ width: '370px', justifyContent: 'flex-end'}}>
								        <Label for="nombRequ" className="mr-sm-2">Requisito:</Label>
								        <Input type="text" name="nombRequ" id="nombRequ" placeholder="Requisito" 
								        		style={{ width: '220px' }} value={ this.state.nombRequ }
								        		onChange={ (text) => { this.setState({nombRequ: text.target.value}) } } />
								      </FormGroup>
								      <FormGroup style={{ width: '370px', justifyContent: 'flex-end'}}>
								        <Label for="requisitoDescripcionUpdate" className="mr-sm-2">Descripción:</Label>
								        <Input type="textarea" name="requisitoDescripcionUpdate" id="requisitoDescripcionUpdate" placeholder="Descripción" 
								        		style={{ width: '220px' }} value={ this.state.requisitoDescripcionUpdate }
								        		onChange={ (text) => { this.setState({requisitoDescripcionUpdate: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '370px', justifyContent: 'flex-end'}}>
								        <Label for="requisitoStateUpdate" className="mr-sm-2">Estado:</Label>
							         	<ButtonGroup style={{ width: '220px' }}>
									      <Button style={{ backgroundColor: this.state.requisitoStateUpdate == 'SI' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ requisitoStateUpdate: 'SI' }) } }>Sí</Button>
									      <Button style={{ backgroundColor: this.state.requisitoStateUpdate == 'NO' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ requisitoStateUpdate: 'NO' }) } }>No</Button>
									    </ButtonGroup>
								      </FormGroup>
								      <FormGroup style={{ width: '370px', justifyContent: 'flex-end'}}>
								        <Label for="requisitoObservacionUpdate" className="mr-sm-2">Observacion:</Label>
								        <Input type="textarea" name="requisitoObservacionUpdate" id="requisitoObservacionUpdate" placeholder="Observacion" 
								        		style={{ width: '220px' }} value={ this.state.requisitoObservacionUpdate }
								        		onChange={ (text) => { this.setState({requisitoObservacionUpdate: text.target.value}) } }/>
								      </FormGroup>
								    </Form>
					        	}
					        </ModalBody>
					        <ModalFooter style={{ display: this.state.actualizando_requisito_load ? 'none' : '' }}>	
					          <Button color="primary" onClick={ () => { this.changeRequisitoUpdate(); } }>Actualizar elemento</Button>
					          <Button color="secondary" onClick={ () => { this.setState({modalItemUpdate: !this.state.modalItemUpdate}); } }>Cancelar</Button>
					        </ModalFooter>
      					</Modal>
      					<Modal isOpen={this.state.modalUpdateAccion} >
					        <ModalHeader >Actualizar Acción</ModalHeader>
					        <ModalBody style={{ justifyContent: 'center' }}>
					        	{
					        		this.state.loadingUpdateAction

					        		?

					        		<Spinner color="primary" />

					        		:

					        		<Form inline className="ajustar-forms-modales">
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="eventVAccion" className="mr-sm-2">Acción:</Label>
								        <Input type="text" name="eventVAccion" id="eventVAccion" placeholder="Acción" 
								        		style={{ width: '220px' }}
								        		value={ this.state.eventVAccion } 
								        		onChange={ (text) => { this.setState({ eventVAccion: text.target.value }); } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="fechaVAccion" className="mr-sm-2">Fecha:</Label>
							         	<Input type="date" name="fechaVAccion" id="fechaVAccion" placeholder="Fecha de la acción" 
								        		style={{ width: '220px' }}
								        		value={ this.state.fechaVAccion }
								        		onChange={ (text) => { this.setState({fechaVAccion: text.target.value}) } } />
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="horaVAccion" className="mr-sm-2">Hora:</Label>
								        <Input type="time" name="horaVAccion" id="horaVAccion" placeholder="Hora de la acción" 
								        		style={{ width: '220px' }}
								        		value={ this.state.horaVAccion }
								        		onChange={ (text) => { this.setState({horaVAccion: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="prioridadVAccion" className="mr-sm-2">Prioridad:</Label>
								        <ButtonGroup style={{ width: '220px' }}>
									      <Button style={{ backgroundColor: this.state.prioridadVAccion == 'urgente' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ prioridadVAccion: 'urgente' }) } }>Urgente</Button>
									      <Button style={{ backgroundColor: this.state.prioridadVAccion == 'no urgente' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ prioridadVAccion: 'no urgente' }) } }>No urgente</Button>
									      <Button style={{ backgroundColor: this.state.prioridadVAccion == 'tramite' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ prioridadVAccion: 'tramite' }) } }>Trámite</Button>
									    </ButtonGroup>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="estateVAccion" className="mr-sm-2">Estado:</Label>
								        <ButtonGroup style={{ width: '220px' }}>
									      <Button style={{ backgroundColor: this.state.estateVAccion == 'Si' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ estateVAccion: 'Si' }) } }>Sí</Button>
									      <Button style={{ backgroundColor: this.state.estateVAccion == 'No' ? '#2c3e50' : '' }} onClick={ () => { this.setState({ estateVAccion: 'No' }) } }>No</Button>
									    </ButtonGroup>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="commentVAccion" className="mr-sm-2">Descripción: </Label>
								        <Input type="textarea" name="commentVAccion" id="commentVAccion" placeholder="Descripción de acción" 
								        		style={{ width: '220px' }}
								        		value={ this.state.commentVAccion }
								        		onChange={ (text) => { this.setState({commentVAccion: text.target.value}) } }/>
								      </FormGroup>
								      <FormGroup style={{ width: '100%', justifyContent: 'end'}}>
								        <Label for="resumenAccion" className="mr-sm-2">Resumen: </Label>
								        <Input type="textarea" name="resumenAccion" id="resumenAccion" placeholder="Resumen" 
								        		style={{ width: '220px' }}
								        		value={ this.state.resumenAccion }
								        		onChange={ (text) => { this.setState({resumenAccion: text.target.value}) } }/>
								      </FormGroup>
							      	  {
											this.state.warning_nada_x_actualizar
											?
											<Alert style={{ marginTop: '10px', width: '100%' }} color="danger">Error actualizando la acción!</Alert>
											:
											null
									  }
								    </Form>
					        	}
					        </ModalBody>
					        <ModalFooter style={{ display: this.state.loadingUpdateAction ? 'none' : '' }}>	
					          <Button color="primary" onClick={ () => { this.updateEventComment(); } }>Actualizar acción</Button>
					          <Button color="secondary" onClick={ () => { this.setState({modalUpdateAccion: !this.state.modalUpdateAccion}); } }>Cancelar</Button>
					        </ModalFooter>
      					</Modal>
      					<Modal isOpen={this.state.modalUpdateAccionFile} >
					        <ModalHeader >Actualizar doc. de acción</ModalHeader>
					        <ModalBody style={{ justifyContent: 'center' }}>
					        	{
					        		this.state.actualizando_documentoaccion_load

					        		?

					        		<Spinner color="primary" />

					        		:

					        		<Form inline>
								      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
									        <Label for="new_doc" className="mr-sm-2">Documento:</Label>
									        <Input type="file" name="new_doc" id="new_doc" onChange={this.onFileChange} style={{ width: '220px' }}/>
									        <FormText color="muted">
									          Puede cargar con este botón un documento para anexarlo al archivo de este caso.
									        </FormText>
									  </FormGroup>
								    </Form>
					        	}
					        </ModalBody>
					        <ModalFooter style={{ display: this.state.actualizando_documentoaccion_load ? 'none' : '' }}>	
					          <Button color="primary" onClick={ () => { this.updateFileAccion(); } }>Actualizar elemento</Button>
					          <Button color="secondary" onClick={ () => { this.setState({modalUpdateAccionFile: !this.state.modalUpdateAccionFile}); } }>Cancelar</Button>
					        </ModalFooter>
      					</Modal>
      					<Modal isOpen={this.state.modalUpdateRequisitoFile} >
					        <ModalHeader >Actualizar doc. de requisito</ModalHeader>
					        <ModalBody style={{ justifyContent: 'center' }}>
					        	{
					        		this.state.actualizando_documentoaccion_load

					        		?

					        		<Spinner color="primary" />

					        		:

					        		<Form inline>
								      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>
									        <Label for="new_doc" className="mr-sm-2">Documento:</Label>
									        <Input type="file" name="new_doc" id="new_doc" onChange={this.onFileChange} style={{ width: '220px' }}/>
									        <FormText color="muted">
									          Puede cargar con este botón un documento para anexarlo al archivo de este caso.
									        </FormText>
									  </FormGroup>
								    </Form>
					        	}
					        </ModalBody>
					        <ModalFooter style={{ display: this.state.actualizando_documentoaccion_load ? 'none' : '' }}>	
					          <Button color="primary" onClick={ () => { this.updateFileRequisito(); } }>Actualizar elemento</Button>
					          <Button color="secondary" onClick={ () => { this.setState({modalUpdateRequisitoFile: !this.state.modalUpdateRequisitoFile}); } }>Cancelar</Button>
					        </ModalFooter>
      					</Modal>

      					<Modal className="modalUserCaseAdd" isOpen={this.state.modalUserCaseAdd} >
					        <ModalHeader >Añadir un usuario al caso</ModalHeader>
					        <ModalBody style={{ justifyContent: 'center' }}>
					        	{
					        		this.state.anadiendo_usuario_caso

					        		?

					        		<Spinner color="primary" />

					        		:

					        		<ViewAsociarUsuarios casoActual={this.props.casoActual} closeModalUserAdd={ this.closeModalUserAdd } />
					        	}
					        </ModalBody>
					        <ModalFooter style={{ display: this.state.anadiendo_usuario_caso ? 'none' : '' }}>	
					          <Button color="secondary" onClick={ () => { this.setState({modalUserCaseAdd: !this.state.modalUserCaseAdd}); } }>Cancelar</Button>
					        </ModalFooter>
      					</Modal>
		      		</div>

		      		<div style={{ width: '100%' }}>
							<div style={{ width: '100%', height: 'auto', maxHeight: '200px', overflowY: 'scroll' }}>
								<Modal isOpen={this.state.modalUpdateDescripcion} >
							        <ModalHeader >Actualizar descripción</ModalHeader>
							        <ModalBody style={{ justifyContent: 'end' }}>
						        		<Form inline>
						        		  <FormGroup style={{ width: '370px', justifyContent: 'end'}}>	
						        		    <Label for="casoDescripcionUpdate" className="mr-sm-2">Descripción:</Label>
						        		  </FormGroup>		
									      <FormGroup style={{ width: '370px', justifyContent: 'end'}}>									        
									        <Input type="textarea" name="casoDescripcionUpdate" id="casoDescripcionUpdate" placeholder="Descripción del caso" 
									        		style={{ width: '100%' }} value={ this.state.casoDescripcionUpdate }
									        		onChange={ (text) => { this.setState({casoDescripcionUpdate: text.target.value}) } }/>
									      </FormGroup>					      	      
									    </Form>
							        </ModalBody>
							        <ModalFooter style={{ }}>	
							          <Button color="primary" onClick={ () => { this.changeDescripcionUpdate(); } }>Actualizar descripción</Button>
							          <Button color="secondary" onClick={ () => { this.setState({modalUpdateDescripcion: !this.state.modalUpdateDescripcion}); } }>Cerrar</Button>
							        </ModalFooter>
		      					</Modal>
							</div>
					</div>

		      		
					<div style={{ width: '100%', display: 'none', justifyContent: 'center' }}>
						<div className="resume-caso-ctrl-view" style={{  }} 
								onClick={ () => { this.setState({ ctrlFaseVI : !this.state.ctrlFaseVI }) } }>
							<h5>ARCHIVOS ADJUNTOS</h5>
							<span>{ this.state.ctrlFaseVI ? '-' : '+' }</span>
						</div>
					</div>
					<div style={{ width: '100%', display: this.state.ctrlFaseVI && this.state.arr_all_docs.length > 0 ? 'block' : 'none'  }}>
						<Form style={{ width: '100%'}}>
							<FormGroup>
						        <Label style={{ color: 'rgba(89, 91, 101)' }} for="buscar_cliente">Buscar documento</Label>
						        <Row sm={2} className="input-search-div-container">
							        <Col sm={10}>
								        <Input
								          type="search"
								          name="buscar_documento"
								          id="buscar_documento"
								          placeholder="Buscar un documento"
								          value={ this.state.textSearchDoc }
								          onChange={ (textS) => { this.SearchDoc( textS.target.value ) } }
								        />
							        </Col>
							        <Col sm={2}>
							        	<FaSearch />
							        </Col>
							    </Row>
					      	</FormGroup>
				      	</Form>
					</div>
					<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
		      			{ 
		      				this.state.alert_deleting_doc

		      				?

		      				<Alert style={{ margin: '50px 25px', width: '100%' }} color="success">Documento eliminado.</Alert>

		      				:

		      				null
		      			}
		      			{ 
		      				this.state.alert_error_deleting_doc

		      				?

		      				<Alert style={{ margin: '50px 25px', width: '100%' }} color="warning">Documento no eliminado.</Alert>

		      				:

		      				null
		      			}
		      		</div>
		      		<div className="docs-resume-case" style={{ display: this.state.ctrlFaseVI ? 'grid' : 'none' }}>
		      			{ render_docs_case }
		      			{ render_no_results_docs }
		      			{ render_charging_docs }
		      		</div>


		      		{
								this.props.casoActual.estatus == 0

								?

								null

								:

					      		<div className="add-docs-box" style={{ display: this.state.ctrlFaseVI ? 'block' : 'none'}} >
					      			{ render_alert_status_upload_doc }
					      			<Form className="form-add-docs">
					      				<FormGroup className="order-adddocs-box">
									        <Label for="exampleFile">Adjuntar nuevo documento</Label>
									        <Input type="file" name="new_doc" id="new_doc" onChange={this.onFileChange}/>
									        <FormText color="muted">
									          Puede cargar con este botón un documento para anexarlo al archivo de este caso.
									        </FormText>
									    </FormGroup>
									    <FormGroup className="order-adddocs-box">
									        <Label for="exampleFile">Nombre del Archivo:</Label>
									        <Input type="text" name="name_doc" id="name_doc" onChange={ (text) => this.setState({name_doc: text.target.value}) }/>
									    </FormGroup>
									    <FormGroup>

									    	{
										    	this.state.alert_status_upload_doc == 4

										    	?


										    	<Spinner color="primary" />

										    	:

										    	<Button onClick={ () => this.uploadDoc(this.props.casoActual.id_caso) }>Archivar Documento</Button>
									    	}

									    </FormGroup>
					      			</Form>
					      		</div>

					}

					<br />
					<br />

		      	</div>


			</div>


		);

	}

}


class ViewAsociarEventos extends React.Component{

	constructor(props){
		super(props);
		this.state = {

			caso_id: '',
			evento_id: '',

			usuarios_id: [],

			isLoadingCasos: true,
			dataSourceCasos: [],
			array_casos: [],

			dataSourceEventos: [],

			dataSourceUsuarios: [],

			complete_associate: false,

		};
	}


	componentDidMount(){

		var url = globalVars.url;
		// ...
		this.setVarsCurrentSession(url);

	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => {
      		this.get_casos_data(url);
      });

  	}


	get_casos_data = (url) => {

		return fetch( url + '/get-cases-all-data-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

			          if( responseJson != 'Token Error.'){

		          		if( responseJson == 'No Results.' ){
		          			return null;
		          		}else{
		          			this.setState({
					            isLoadingCasos: false,
					            dataSourceCasos: responseJson,
					            array_casos: responseJson,
					          }, function() {
					            // In this block you can do something with new state.
					            // console.log( this.state.dataSourceCasos );

					          });
		          		}

			      	}else{
			      			localStorage.removeItem('permissValid');
		          			localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          	}

			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}


	change_eventos_by_case( text ){

		var caso_id = text.target.value;
		// console.log( caso_id );

		this.setState({ caso_id: caso_id, evento_id: '', usuarios_id: [], dataSourceUsuarios: []  }, () => {  });
		this.get_eventos_by_case( caso_id );

	}

	get_eventos_by_case = ( caso_id ) => {

		var url = globalVars.url;

		fetch( url + '/get-events-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: caso_id,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

				          		this.setState({

											dataSourceEventos: responseJson

										  }, () => {  });

				          	}

				          }

				   }).catch((error) => {
				        console.log(error);
				     });

	}


	change_evento( text ){

		var evento = text.target.value;

		this.setState({ evento_id: evento, usuarios_id: [],
					  }, () => { /* console.log( this.state.caso_id, this.state.evento_id ); */ } );

		if( this.state.evento != '' && this.state.evento != "nada" ){

			this.get_users_by_events( evento );

		}

	}

	get_users_by_events = ( evento_id ) => {

		var url = globalVars.url;
		fetch( url + '/get-users-all-by-events-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idEvento: evento_id,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

						  // console.log( responseJson );
				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

				          		this.setState({

											dataSourceUsuarios: responseJson

										  }, () => {  });

				          	}

				          }

				   }).catch((error) => {
				        // console.log(error);
				     });

	}


	change_usuarios_ids( value_re ){

		var options = value_re.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
        	if (options[i].selected) {
                value.push(options[i].value);
            }
        }

        this.setState({ usuarios_id: value }, () => { /* console.log( this.state.usuarios_id); */ });

	}


	AsociarEventoUsuarios = () => {

		// console.log( this.state.usuarios_id );
		var url = globalVars.url;
		let obj = {};

		obj.idEvento = this.state.evento_id;
		obj.usuarios_id = this.state.usuarios_id;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';


		fetch( url + '/set-users-associate-by-event-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					obj: obj,
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				          if(responseJson != 'Error.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

								// console.log( responseJson );
								this.setState({
												complete_associate: true,
												evento_id: '',
												usuarios_id: []
											  }, ()=> {});

							}

				          }

				   }).catch((error) => {
				        // console.log(error);
				     });

	}

	changeLiValue = () => {

		this.props.OnChangeLiValue(1);

	}

	SearchFilterCasos = (textSearch) => {

		     const newData = this.state.array_casos.filter( function(item){
		         const itemData = item.filter.toUpperCase();
		         const textData = textSearch.toUpperCase();
		         return itemData.indexOf(textData) > -1;
		     });

		     this.setState({
		         dataSourceCasos: newData,
		         textSearch: textSearch
		     }, () => { /*console.log( this.state.dataSourceCasos )*/ });

	}

	render(){

		let render_options_caso_cliente = null;
		let render_options_caso_eventos = null;
		let render_options_usuarios = null;

		const styles = {
			
			styleIcons:{
				fontSize: "30px",
				color: "#FFF",
			},
			buttonAnadirOtherClient: {
				marginTop: '15px',
				color: '#FFF',
				fontWeight: '650',
			},

			searchTitle: {
				color: "rgba(89, 91, 101)",
				fontFamily: 'Crimson Text',
				fontSize: '25px',
				fontWeight: '600',
			},

			containerSerachIcon: {
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignContent: "center",
				alignItems: "center",
				float: "left",
			},

			searchIcone: {
				left: "5px",
				position: "absolute"
			},

		};



		if( this.state.isLoadingCasos ){

			return(

				<Alert style={{ margin: "20px 10px" }} color="success">Cargando Casos!</Alert>

			);

		}else{

			render_options_caso_cliente = this.state.dataSourceCasos.map( (item, index) =>

				<option key={index} value={ item.id_caso }>{ item.codigo_caso } - { item.cliente }</option>

			);
		}


		if( this.state.dataSourceEventos.length > 0 ){

			render_options_caso_eventos = this.state.dataSourceEventos.map( (item, index) =>

				<option key={index} value={ item.id_evento_caso }>{ item.evento }</option>

			);
		}


		if( this.state.dataSourceUsuarios.length > 0 ){

			render_options_usuarios = this.state.dataSourceUsuarios.map( (item, index) =>

				<option key={index} value={ item.id_usuario } selected={ item.estado_usuario == "asociado" ? "selected" : "" }> { item.nombre_usuario } </option>

			);

		}


		if( this.state.complete_associate ){

			return(

				<div>
					<Alert style={{ marginTop: "20px", marginHorizontal: "10px" }} color="success">Se asociaron los usuarios al evento correctamente!</Alert>
					<Form>

						<Row md={2}>
							<Col sm={6}>
								<FormGroup>
							    	<Button style={{ width: '150px', position: "absolute", right: "15px"}}
							    		className="button_addcliente" onClick={ this.changeLiValue }>Casos</Button>
							    </FormGroup>
						    </Col>
						    <Col sm={6}>
						    	<FormGroup>
							    	<Button style={{ width: '150px', position: "absolute", left: "15px"}}
							    		className="button_addcliente" onClick={ () => { this.setState({ complete_associate: false }) } }>Asociar otros</Button>
							    </FormGroup>
						    </Col>
					    </Row>

				    </Form>
			    </div>

			);
		}



		return(

			<div className="panel-associate-user-case-client-view">

				<Form className="form-relate-changes">

				      <div className="title-div-cliente-form">
				        <Label className="title-add-cliente-form"> Asociar Evento - Usuario </Label>
				      </div>

				      <div className="logo-div-add-cliente-form">
				        <FaWhmcs style={ styles.styleIcons } />
				      </div>

				      <br />

				      
					  <FormGroup>
				        <Label style={ styles.searchTitle } for="buscar_caso">Buscar caso por código, cliente ó materia</Label>
				        <Row sm={2} className="input-search-div-container">
					        <Col sm={11}>
						        <Input
						          type="search"
						          name="buscar_caso"
						          id="buscar_caso"
						          placeholder="Buscar un caso"
						          onChange={ (textS) => { this.SearchFilterCasos( textS.target.value ) } }
						        />
					        </Col>
					        <Col style={ styles.containerSerachIcon } sm={1}>
					        	<FaSearch style={ styles.searchIcone } />
					        </Col>
					    </Row>
			      	  </FormGroup>

				      <FormGroup >

				      	<Row md={1}>
				      		<Col sm={12}>
					      		<Input
							          type="select"
							          name="caso_id"
							          id="caso_id"
							          placeholder="Caso Seleccionado"
							          value={ this.state.caso_id }
							          onChange={ (text) => { this.change_eventos_by_case(text) } }
							        >
							        	<option value="nada">Seleccione un caso</option>
							        	{ render_options_caso_cliente }
							    </Input>
				      		</Col>
				      	</Row>

				      </FormGroup>
				      <FormGroup>

				      	<Row md={2}>
					      	<Col sm={6} style={{ display: 'flex', justifyContent: 'center',
					      							alignItems: 'center', alignContent: 'center'}}>
					      		<Input
						          type="select"
						          name="evento_id"
						          id="evento_id"
						          placeholder="Evento"
						          value={ this.state.evento_id }
						          onChange={ (text) => { this.change_evento(text) } }
						        >
						        	<option value="nada">Seleccione un evento</option>
						        	{ render_options_caso_eventos }
						        </Input>
					      	</Col>

					      	<Col sm={6}>
					      		<Input
						          type="select"
						          multiple
						          name="usuarios_id"
						          id="usuarios_id"
						          placeholder="Usuarios"
						          onChange={ (value) => { this.change_usuarios_ids(value) } }
						        >
						        	{ render_options_usuarios }
						        </Input>
					      	</Col>
					    </Row>

				      </FormGroup>


				      <FormGroup>
				      	<Button className="button_addcliente" onClick={ this.AsociarEventoUsuarios }>Asociar</Button>
				      </FormGroup>

			    </Form>

			</div>

		);

	}


}



class ViewAsociarCasoCliente extends React.Component{

	constructor(props){
		super(props);
		this.state = {

			caso_id: '',

			isLoadingClientes: true,
			dataSourceClientes: [],

			isLoadingCasos: true,
			dataSourceCasos: [],
			array_casos: [],

			complete_associate: false,

		};
	}


	componentDidMount(){

		var url = globalVars.url;
		// ...
		this.setVarsCurrentSession(url);

	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => {
      		this.get_casos_data(url);
      });

  	}


	get_casos_data = (url) => {

		return fetch( url + '/get-cases-all-data-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

		          	if( responseJson != 'Token Error.'){

		          		if( responseJson == 'No Results.' ){
		          			return null;
		          		}else{
		          			this.setState({
					            isLoadingCasos: false,
					            dataSourceCasos: responseJson,
					            array_casos: responseJson,
					          }, function() {
					            // In this block you can do something with new state.
					            // console.log( this.state.dataSourceCasos );

					          });
		          		}

			      	}else{

			      			localStorage.removeItem('permissValid');
		          			localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});

		          	}

			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}


	get_clientes = (url) => {
				

				return fetch( url + '/get-clientes-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

		          		if( responseJson != 'No Results Found.' ){
		          			
		          			if( responseJson == 'Token Error.' ){
		          				localStorage.removeItem('permissValid');
			          			localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          		}else{
			          			this.setState({
						            isLoadingClientes: false,
						            dataSourceClientes: responseJson,
						            array_tmp_clientes: responseJson,
						        }, function() {
						            // In this block you can do something with new state.
						            // console.log( this.state.dataSourceClientes );
						            
						        });
			          		}
		          			
		          		}
			          
			       }).catch((error) => {
		          		console.error(error);
		           	  });	


	}


	change_clientes_by_case( text ){

		var caso_id = text.target.value;
		// console.log( caso_id );

		this.setState({ caso_id: caso_id }, () => { this.get_clientes( globalVars.url ); });

	}


	change_clientes_ids( value_re ){

		var options = value_re.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
        	if (options[i].selected) {
                value.push(options[i].value);
            }
        }

        this.setState({ clientes_id: value }, () => { /*console.log( this.state.clientes_id);*/ });

	}


	AsociarCasoClientes = () => {

		// console.log( this.state.usuarios_id );
		var url = globalVars.url;
		let obj = {};

		obj.idCaso = this.state.caso_id;
		obj.clientes_id = this.state.clientes_id;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch( url + '/set-clients-associate-by-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					obj: obj,
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				  		  console.log( responseJson );

				          if(responseJson != 'Error.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

								this.setState({
												complete_associate: true,
												caso_id: '',
												usuarios_id: []
											  }, ()=> {});

				          	}

				          }

				   }).catch((error) => {
				        console.log(error);
				     });

	}

	changeLiValue = () => {

		this.props.OnChangeLiValue(1);

	}

	SearchFilterCasos = (textSearch) => {

		     const newData = this.state.array_casos.filter( function(item){
		         const itemData = item.filter.toUpperCase();
		         const textData = textSearch.toUpperCase();
		         return itemData.indexOf(textData) > -1;
		     });

		     this.setState({
		         dataSourceCasos: newData,
		         textSearch: textSearch
		     }, () => { /*console.log( this.state.dataSourceCasos )*/ });

	}

	render(){

		let render_options_caso_usuario = null;
		let render_options_clientes = null;

		const styles = {
			styleIcons:{
				fontSize: "30px",
				color: "#FFF",
			},
			buttonAnadirOtherClient: {
				marginTop: '15px',
				color: '#FFF',
				fontWeight: '650',
			},
			searchTitle: {
				color: "rgba(89, 91, 101)",
				fontFamily: 'Crimson Text',
				fontSize: '25px',
				fontWeight: '600',
			},

			containerSerachIcon: {
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignContent: "center",
				alignItems: "center",
				float: "left",
			},

			searchIcone: {
				left: "5px",
				position: "absolute"
			},
		};



		if( this.state.isLoadingCasos ){

			return(

				<Alert style={{ margin: "20px 20px" }} color="success">Cargando Casos!</Alert>

			);

		}else{
			//
			render_options_caso_usuario = this.state.dataSourceCasos.map( (item, index) =>

				<option key={index} value={ item.id_caso }>{ item.codigo_caso } - { item.cliente }</option>

			);
		}

		//

		if( this.state.dataSourceClientes.length > 0 ){

			render_options_clientes = this.state.dataSourceClientes.map( (item, index) =>

				<option key={index} value={ item.id_cliente } > { item.tipo_cliente == "PERSONA NATURAL" ? item.primer_nombre + " " + item.primer_apellido : item.razon_social } </option>

			);

		}


		if( this.state.complete_associate ){
			//
			return(

				<div>
					<Alert style={{ marginTop: "20px", marginHorizontal: "10px" }} color="success">Se asocio el cliente al caso correctamente!</Alert>
					<Form>

						<FormGroup>
					    	<Button className="button_addcliente" onClick={ this.changeLiValue }>Continuar</Button>
					    </FormGroup>

				    </Form>
			    </div>

			);
		}



		return(

			<div className="panel-associate-user-case-client-view">

				<Form className="form-relate-case-user">

				      <div className="title-div-cliente-form">
				        <Label className="title-add-cliente-form"> Asociar Caso - Cliente </Label>
				      </div>

				      <div className="logo-div-add-cliente-form">
				        <FaWhmcs style={ styles.styleIcons } />
				      </div>

				      <br />

				      <FormGroup>
				        <Label style={ styles.searchTitle } for="buscar_caso">Buscar caso por código, cliente ó materia</Label>
				        <Row sm={2} className="input-search-div-container">
					        <Col sm={11}>
						        <Input
						          type="search"
						          name="buscar_caso"
						          id="buscar_caso"
						          placeholder="Buscar un caso"
						          onChange={ (textS) => { this.SearchFilterCasos( textS.target.value ) } }
						        />
					        </Col>
					        <Col style={ styles.containerSerachIcon } sm={1}>
					        	<FaSearch style={ styles.searchIcone } />
					        </Col>
					    </Row>
			      	  </FormGroup>

				      <FormGroup>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="select"
						          name="caso_id"
						          id="caso_id"
						          placeholder="Caso Seleccionado"
						          value={ this.state.caso_id }
						          onChange={ (text) => { this.change_clientes_by_case(text) } }
						        >
						        	<option value="nada">Seleccione un caso</option>
						        	{ render_options_caso_usuario }
						        </Input>
					      	</Col>

					      	<Col sm={6}>
					      		<Input
						          type="select"
						          multiple
						          name="clientes_id"
						          id="clientes_id"
						          placeholder="Clientes"
						          onChange={ (value) => { this.change_clientes_ids(value) } }
						        >
						        	{ render_options_clientes }
						        </Input>
					      	</Col>
					    </Row>

				      </FormGroup>


				      <FormGroup>
				      	<Button className="button_addcliente" onClick={ this.AsociarCasoClientes }>Asociar</Button>
				      </FormGroup>

			    </Form>

			</div>

		);

	}


}



class ViewHonorariosCaso extends React.Component{

	constructor(props){
		super(props);
		this.state = {

			caso_id: '',

			isLoadingCasos: true,
			dataSourceCasos: [],
			array_casos: [],

			isLoadingHonorarios: false,
			dataSourceArrayHonorarios: [],

			modalAddHonorarios: false,
			concepto: '',
			honorario: 0,
			fecha_pactada: '',
			observacion: '',


		};
	}


	componentDidMount(){

		var url = globalVars.url;
		// ...
		this.setVarsCurrentSession(url);

	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => {
      		this.get_casos_data(url);
      });

  	}


	get_casos_data = (url) => {

		return fetch( url + '/get-cases-all-data-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

		          	if( responseJson != 'Token Error.'){

		          		if( responseJson == 'No Results.' ){
		          			return null;
		          		}else{
		          			this.setState({
					            isLoadingCasos: false,
					            dataSourceCasos: responseJson,
					            array_casos: responseJson,
					          }, function() {
					            // In this block you can do something with new state.
					            // console.log( this.state.dataSourceCasos );

					          });
		          		}

			      	}else{

			      			localStorage.removeItem('permissValid');
		          			localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});

		          	}

			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}


	get_honorarios_by_case = ( caso_id ) => {

		var url = globalVars.url;
		fetch( url + '/get-honorarios-by-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: caso_id,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

						  // console.log( responseJson );
				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

								this.setState({

												dataSourceArrayHonorarios: responseJson

											  }, () => {  });
							}

				          }else{
				          	this.setState({ dataSourceArrayHonorarios: [] });
				          }

				   }).catch((error) => {
				        console.log(error);
				     });

	}


	AddHonorarios = () => {

		var url = globalVars.url;
		let obj = {};

		obj.idCaso = this.state.caso_id;
		obj.concepto = this.state.concepto;
		obj.honorario = this.state.honorario;
		obj.fecha_pactada = this.state.fecha_pactada;
		obj.observacion = this.state.observacion;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log(obj);

		if( this.state.concepto !== '' && this.state.honorario !== 0 && this.state.fecha_pactada !== '' )
		{
			fetch( url + '/set-honorarios-by-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					obj: obj,
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				  		  // console.log(responseJson);
				          if(responseJson != 'Error.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

				          		// console.log( responseJson );
								this.setState({
												complete_add: true,
												concepto: '',
												honorario: 0,
												fecha_pactada: '',
												observacion: '',
												modalAddHonorarios: false
											  }, ()=> {});
								setTimeout( () => { this.setState({ complete_add: false }) }, 2500);
								this.get_honorarios_by_case( this.state.caso_id );

				          	}

				          }else{
				          		this.setState({
												error_add: true,
											  }, ()=> {});
								setTimeout( () => { this.setState({ error_add: false }) }, 2500);
				          }

				   }).catch((error) => {
				        console.log(error);
				     });
		}else{
			this.setState({
							incomplete_fields: true,
						  }, ()=> {});
			setTimeout( () => { this.setState({ incomplete_fields: false }) }, 2500);	
		}

	}

	SearchFilterCasos = (textSearch) => {

		     const newData = this.state.array_casos.filter( function(item){
		         const itemData = item.filter.toUpperCase();
		         const textData = textSearch.toUpperCase();
		         return itemData.indexOf(textData) > -1;
		     });

		     this.setState({
		         dataSourceCasos: newData,
		         textSearch: textSearch
		     }, () => { /*console.log( this.state.dataSourceCasos )*/ });

	}

	changeCaseSelected = (casoId) => {

		this.setState({ caso_id: casoId });
		this.get_honorarios_by_case(casoId);

	}

	UpdateHonorario = () => {

		this.setState({ actualizando_load: true });

		var url = globalVars.url;
		let obj = {};

		obj.idHonorario = this.state.idHonorario;
		obj.newConcepto = this.state.newConcepto;
		obj.newHonorario = this.state.newHonorario;
		obj.newFechaP = this.state.newFechaP;
		obj.newEstado = this.state.newEstado;
		obj.newObservacion = this.state.newObservacion;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log( obj );

		if( this.state.idHonorario !== '' && this.state.newConcepto !== '' && this.state.newHonorario !== '' && this.state.newFechaP !== '' )
		{

			fetch(url+'/update-honorario-caso-api.php', {
				           headers:{
				               "Accept": "application/json",
				               "Content-Type": "application/json"
				           },
				           method:'POST',
				           body: JSON.stringify({
				              obj,
				           }),

				    }).then( response => response.json() )
				      .then( responseJson => {

				      	  // console.log( responseJson );

				          if(responseJson == 'Updated.'){

				            // alert('Se registro exitosamente el estado');
				            this.setState({
											alert_updated_honorario: true,
											idHonorario: '',
											newConcepto: '',
											newHonorario: '',
											newFechaP: '',
											newEstado: '',
											newObservacion: '',
											modalHonorarioUpdate: false
				            			});
				           	setTimeout( () => { this.setState({ alert_updated_honorario: false }) }, 2000);
				           	this.get_honorarios_by_case( this.state.caso_id );

				          }
				          else{

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

				          		this.setState({ alert_error_updating_honorario: true }, () => {  });
				          		setTimeout( () => { 
				          			this.setState({ alert_error_updating_honorario: false }) 
				          		}, 2000);

				          	}

				          }

				      }).catch((error) => {
				        console.log(error);
				      });
		}else{
			this.setState({ incomplete_fields: true }, () => {  });
			setTimeout( () => { 
				this.setState({ incomplete_fields: false }) 
			}, 2000);
			
		}

		this.setState({ actualizando_fase_load: false });

	}

	setVarsHonorarioUpdate = (idV, conceptoV, honorarioV, fechaV, observacionV, estadoV ) => {

		this.setState({ 
						idHonorario: idV, newConcepto: conceptoV, 
						newHonorario: honorarioV, newFechaP: fechaV,
						newEstado: estadoV, newObservacion: observacionV, modalHonorarioUpdate: true 
					  }, () => {  });

	}


	minusHonorario = (idHonorario) => {

		var url = globalVars.url;
		let obj = {};

		obj.idHonorario = idHonorario;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch(url+'/delete-honorario-caso-api.php', {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Deleted.'){

			            // alert('Se registro exitosamente el estado');
			            this.setState({
										alert_deleting_honorario: true
			            			});
			            setTimeout( () => { this.setState({ alert_deleting_honorario: false }) }, 2000 );
			            this.get_honorarios_by_case( this.state.caso_id );

			          }
			          else{

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;

			          	}else{

			          		this.setState({ alert_error_deleting_honorario: true });
			          		setTimeout( () => { this.setState({ alert_error_deleting_honorario: false }) }, 2000 );

			          	}

			          }

			      }).catch((error) => {
			        console.log(error);
			      });

	}

	render(){

		// console.log(this.state.caso_id, this.state.concepto, this.state.honorario, this.state.fecha_pactada, this.state.observacion);
		// console.log(this.state.dataSourceArrayHonorarios.length)

		let render_options_caso_usuario = null;
		let render_honorarios = null;

		const styles = {
			styleIcons:{
				fontSize: "30px",
				color: "#FFF",
			},
			buttonAnadirOtherClient: {
				marginTop: '15px',
				color: '#FFF',
				fontWeight: '650',
			},
			searchTitle: {
				color: "rgba(89, 91, 101)",
				fontFamily: 'Crimson Text',
				fontSize: '25px',
				fontWeight: '600',
			},

			containerSerachIcon: {
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignContent: "center",
				alignItems: "center",
				float: "left",
			},

			searchIcone: {
				left: "5px",
				position: "absolute"
			},
		};



		if( this.state.isLoadingCasos ){

			return(

				<Alert style={{ margin: "20px 20px" }} color="success">Cargando Casos!</Alert>

			);

		}else{

			render_options_caso_usuario = this.state.dataSourceCasos.map( (item, index) =>

				<option key={index} value={ item.id_caso }>{ item.codigo_caso } - { item.cliente }</option>

			);
		}


		if( this.state.dataSourceArrayHonorarios.length > 0 ){

      		render_honorarios = this.state.dataSourceArrayHonorarios.map( (item, index) =>
      			
  				
  					<tr id={index} onClick={ () => { }} style={{ position: 'relative' }}> 
  						<th>{item.concepto}</th> <th>{item.honorario}</th> 
  						<th><Input type="date" value={item.fecha_pactada} /></th> 
  						<th>
  							<ButtonGroup style={{ }}>
						      <Button style={{ backgroundColor: item.estado == 1 ? '#17a589' : '' }} onClick={ () => {  } }>Sí</Button>
						      <Button style={{ backgroundColor: item.estado == 0 ? '#c0392b' : '' }} onClick={ () => {  } }>No</Button>
						    </ButtonGroup>
  						</th> 
  						<th><Button color="primary" onClick={ () => { this.setVarsHonorarioUpdate(item.id_honorario, item.concepto, item.honorario, item.fecha_pactada, item.observaciones, item.estado) }}>Actualizar</Button></th>
  						<span 
						className="boton-accion-sticky-delete"
						style={{ position: 'relative', width: '15px', height: '15px', right: '10px', top: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
						onClick={ () => this.minusHonorario(item.id_honorario) } 
						>
							<FaMinus style={{ color: '#FFF' }} size={9} />
						</span>
  					</tr>
      			
      		);

      	}else{
      		render_honorarios = null;
      	}


		return(

			<div className="panel-associate-user-case-client-view">

				<Form className="form-relate-case-user">

				      <div className="title-div-cliente-form">
				        <Label className="title-add-cliente-form"> Honorarios por caso </Label>
				      </div>

				      <div className="logo-div-add-cliente-form">
				        <FaWhmcs style={ styles.styleIcons } />
				      </div>

				      <br />

				      <FormGroup>
				        <Label style={ styles.searchTitle } for="buscar_caso">Buscar caso por código, cliente ó materia</Label>
				        <Row sm={2} className="input-search-div-container">
					        <Col sm={11}>
						        <Input
						          type="search"
						          name="buscar_caso"
						          id="buscar_caso"
						          placeholder="Buscar un caso"
						          onChange={ (textS) => { this.SearchFilterCasos( textS.target.value ) } }
						        />
					        </Col>
					        <Col style={ styles.containerSerachIcon } sm={1}>
					        	<FaSearch style={ styles.searchIcone } />
					        </Col>
					    </Row>
			      	  </FormGroup>

				      <FormGroup>

				      	<Row md={1}>
					      	<Col sm={12}>
					      		<Input
						          type="select"
						          name="caso_id"
						          id="caso_id"
						          placeholder="Caso Seleccionado"
						          value={ this.state.caso_id }
						          onChange={ (text) => { this.changeCaseSelected( text.target.value ); } }
						        >
						        	<option value="nada">Seleccione un caso</option>
						        	{ render_options_caso_usuario }
						        </Input>
					      	</Col>
					    </Row>

				      </FormGroup>

				      <div>
		      			<Modal isOpen={this.state.modalAddHonorarios} >
					        <ModalHeader >Añadir honorarios al caso</ModalHeader>
					        <ModalBody style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
				        		<Form inline style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
				        		  <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
							        <Label for="concepto" sm={4} style={{ justifyContent: 'end' }}>Concepto:</Label>
							        <Col sm={8}>
							          <Input type="text" name="concepto" id="concepto" placeholder="Concepto de honorarios" 
							          		 style={{ width: '100%' }} onChange={ (text) => { this.setState({ concepto: text.target.value }) }}/>
							        </Col>
							      </FormGroup>
							      <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
							        <Label for="honorario" sm={4} style={{ justifyContent: 'end' }}>Honorarios ($):</Label>
							        <Col sm={8}>
							          <Input type="text" name="honorario" id="honorario" placeholder="Honorarios ($)" 
							          		 style={{ width: '100%' }} onChange={ (text) => { this.setState({ honorario: text.target.value }) }}/>
							        </Col>
							      </FormGroup>
							      <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
							        <Label for="fecha_pactada" sm={4} style={{ justifyContent: 'end' }}>Fecha:</Label>
							        <Col sm={8}>
							          <Input type="date" name="fecha_pactada" id="fecha_pactada" placeholder="Fecha esperada: " 
							          		 style={{ width: '100%' }} onChange={ (text) => { this.setState({ fecha_pactada: text.target.value }) }}/>
							        </Col>
							      </FormGroup>
							      <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
							        <Label for="observacion" sm={4} style={{ justifyContent: 'end' }}>Comentarios:</Label>
							        <Col sm={8}>
							          <Input type="text" name="observacion" id="observacion" placeholder="Agregue comentarios" 
							          		 style={{ width: '100%' }} onChange={ (text) => { this.setState({ observacion: text.target.value }) }}/>
							        </Col>
							      </FormGroup>
							      {
							      	this.state.incomplete_fields
							      	?
							      	<Alert color="warning" style={{ width: '100%' }}>Campos incompletos.</Alert>
							      	:
							      	null
							      }	
							      {
							      	this.state.error_add
							      	?
							      	<Alert color="danger" style={{ width: '100%' }}>Error agregando honorarios.</Alert>
							      	:
							      	null
							      }			      	      
							    </Form>
					        </ModalBody>
					        <ModalFooter style={{ }}>	
					          <Button color="primary" onClick={ () => { this.AddHonorarios(); } }>Agregar</Button>
					          <Button color="secondary" onClick={ () => { this.setState({modalAddHonorarios: !this.state.modalAddHonorarios}); } }>Cerrar</Button>
					        </ModalFooter>
      					</Modal>
      					<Modal isOpen={this.state.modalHonorarioUpdate} >
					        <ModalHeader >Editar honorarios</ModalHeader>
					        <ModalBody style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
				        		<Form inline style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
				        		  <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
							        <Label for="newconcepto" sm={4} style={{ justifyContent: 'end' }}>Concepto:</Label>
							        <Col sm={8}>
							          <Input type="text" name="newconcepto" id="newconcepto" placeholder="Concepto de honorarios" 
							          		 style={{ width: '100%' }}
							          		 value={ this.state.newConcepto}
							          		 onChange={ (text) => { this.setState({ newConcepto: text.target.value }) }}/>
							        </Col>
							      </FormGroup>
							      <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
							        <Label for="newHonorario" sm={4} style={{ justifyContent: 'end' }}>Honorarios ($):</Label>
							        <Col sm={8}>
							          <Input type="text" name="newHonorario" id="newHonorario" placeholder="Honorarios ($)" 
							          		 style={{ width: '100%' }} 
							          		 value={this.state.newHonorario}
							          		 onChange={ (text) => { this.setState({ newHonorario: text.target.value }) }}/>
							        </Col>
							      </FormGroup>
							      <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
							        <Label for="newFechaP" sm={4} style={{ justifyContent: 'end' }}>Fecha:</Label>
							        <Col sm={8}>
							          <Input type="date" name="newFechaP" id="newFechaP" placeholder="Fecha esperada: " 
							          		 style={{ width: '100%' }} 
							          		 value={this.state.newFechaP}
							          		 onChange={ (text) => { this.setState({ newFechaP: text.target.value }) }}/>
							        </Col>
							      </FormGroup>
							      <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
							        <Label for="newObservacion" sm={4} style={{ justifyContent: 'end' }}>Comentarios:</Label>
							        <Col sm={8}>
							          <Input type="text" name="newObservacion" id="newObservacion" placeholder="Agregue comentarios" 
							          		 style={{ width: '100%' }} 
							          		 value={this.state.newObservacion}
							          		 onChange={ (text) => { this.setState({ newObservacion: text.target.value }) }}/>
							        </Col>
							      </FormGroup>
							      <FormGroup row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
							        <Label for="newEstado" sm={4} style={{ justifyContent: 'end' }}>Pagado?:</Label>
							        <Col sm={8}>
							          <ButtonGroup style={{ }}>
									      <Button style={{ backgroundColor: this.state.newEstado == 1 ? '#17a589' : '' }} onClick={ () => { this.setState({ newEstado: 1 }) } }>Sí</Button>
									      <Button style={{ backgroundColor: this.state.newEstado == 0 ? '#c0392b' : '' }} onClick={ () => { this.setState({ newEstado: 0 }) } }>No</Button>
									  </ButtonGroup>
							        </Col>
							      </FormGroup>
							      {
							      	this.state.incomplete_fields
							      	?
							      	<Alert color="warning" style={{ width: '100%' }}>Campos incompletos.</Alert>
							      	:
							      	null
							      }	
							      {
							      	this.state.error_add
							      	?
							      	<Alert color="danger" style={{ width: '100%' }}>Error actualizando honorarios.</Alert>
							      	:
							      	null
							      }	
							      {
							      	this.state.actualizando_load
							      	?
							      	<Spinner type="grow" color="primary" />
							      	:
							      	null
							      }						      	      
							    </Form>
					        </ModalBody>
					        <ModalFooter style={{ }}>	
					          <Button color="primary" onClick={ () => { this.UpdateHonorario(); } }>Actualizar</Button>
					          <Button color="secondary" onClick={ () => { this.setState({modalHonorarioUpdate: !this.state.modalHonorarioUpdate}); } }>Cerrar</Button>
					        </ModalFooter>
      					</Modal>
				      </div>
				      <div style={{ width: '100%', padding: '10px 20px' }}>
				      	{
				      		this.state.caso_id !== '' && this.state.dataSourceArrayHonorarios.length > 0
				      		?
				      		<Form style={{ width: '100%', maxHeight: '300px',  }}>
								<FormGroup row className="tabla_eventos_resumen" style={{ borderRadius: '8px', margin: '20px 0px' }}>
								  	<Table dark responsive style={{ borderRadius: '8px' }}>
								      <thead>
								        <tr>
								          <th>Concepto</th>
								          <th>Valor</th>
								          <th>Fecha</th>
								          <th>Pagado?</th>
								          <th>Actualizar</th>
								        </tr>
								      </thead>
								      <tbody>
								        { render_honorarios }
								      </tbody>
								    </Table>
							  	</FormGroup>
						  	</Form>
						  	:
						  	null
				      	}
				      	{
				      		this.state.caso_id !== '' && this.state.dataSourceArrayHonorarios.length < 1
				      		?
				      		<Alert color="warning" style={{ margin: '5px 25px' }}>No se han añadido honorarios al caso</Alert>	
				      		:
				      		null
				      	}
				      </div>
				      {
				      	!this.state.complete_add
				      	?
				      	null
				      	:
					    <Alert color="success" style={{ margin: '0 25px' }}>Honorarios agregados.</Alert>
				  	  }
				  	  {
				      	!this.state.alert_updated_honorario
				      	?
				      	null
				      	:
					    <Alert color="success" style={{ margin: '0 25px' }}>Honorarios actualizados.</Alert>
				  	  }
				  	  {
				      	!this.state.alert_deleting_honorario
				      	?
				      	null
				      	:
					    <Alert color="success" style={{ margin: '0 25px' }}>Honorarios eliminados.</Alert>
				  	  }
				  	  {
				      	!this.state.alert_error_deleting_honorario
				      	?
				      	null
				      	:
					    <Alert color="danger" style={{ margin: '0 25px' }}>Error eliminando honorario.</Alert>
				  	  }
				      {
				      	this.state.caso_id === ''
				      	?
				      	null
				      	:
					    <FormGroup>
					    	<Button className="button_addcliente" onClick={ () => { this.setState({ modalAddHonorarios: true }); } }>Agregar honorarios</Button>
					    </FormGroup>
				  	  }

			    </Form>

			</div>

		);

	}


}