import React from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import './Styles.css';
import { FaBalanceScale } from 'react-icons/fa';

import globalVars from './constants';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Redirect} from 'react-router-dom';



class AddCaso extends React.Component{


	constructor(props){
		super(props);
		this.state = {
			
			id_materia: '',
			materia_caso: '',
			descripcion_general: '',
			id_cliente: '',

			submateria: '',
			prefixSubmateria: '',

			isLoadingArrayMatsSubMats: true,
			dataSourceArrayMatsSubMats: [],

			isLoadingClientes: true,
			dataSourceClientes: [],
			array_tmp_clientes: [],

			alert_created: false,
			alert_error: false,
			redirect: false,

			warning_incomplete: false,

			id_caso_insertado: '',

		};
	}


	componentDidMount() {
		
    	var url = globalVars.url;

    	this.setVarsCurrentSession(url);		
	
	}


	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { 
      		this.get_clientes(url);
    		this.get_materias_submaterias(url);
      });

  	}



	get_materias_submaterias = (url) => {
		

		return fetch( url + '/get-materias-submaterias.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

		          		if( responseJson != 'No Results Found.'){

		          			if( responseJson == 'Token Error.' ){
		          				localStorage.removeItem('permissValid');
		          				localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
		          			}
		          			else{
		          				this.setState({
						            isLoadingArrayMatsSubMats: false,
						            dataSourceArrayMatsSubMats: responseJson
						        }, function() {
						            // In this block you can do something with new state.
						            // console.log( this.state.dataSourceArrayMatsSubMats );
						        });
		          			}
		          			
		          		}
			          
			       }).catch((error) => {
		          		console.error(error);
		           	  });


	}


	get_clientes = (url) => {
				

				return fetch( url + '/get-clientes-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

		          		// console.log(responseJson);
		          		
		          		if( responseJson != 'No Results Found.' ){
		          			
		          			if( responseJson == 'Token Error.' ){
		          				localStorage.removeItem('permissValid');
			          			localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          		}else{
			          			this.setState({
						            isLoadingClientes: false,
						            dataSourceClientes: responseJson,
						            array_tmp_clientes: responseJson,
						        }, function() {
						            // In this block you can do something with new state.
						            // console.log( this.state.dataSourceClientes );
						            
						        });
			          		}
		          			
		          		}
			          
			       }).catch((error) => {
		          		console.error(error);
		           	  });	


	}

	changeMateria(value){

		let var_index = value.target.value;

		let var_item = this.state.dataSourceArrayMatsSubMats[var_index];


		let var_id_materia = var_item.id_materia;
		let var_materia_caso = var_item.tipo_caso;


		// console.log( var_materia_caso, var_id_materia);

		this.setState({ materia_caso: var_materia_caso, id_materia: var_id_materia}, () => { /*console.log(this.state.id_materia);*/ } ); 

	}

	changeCliente(value){

		let var_id_cliente = value.target.value;

		this.setState({ id_cliente: var_id_cliente }, () => {});

	}


	changeDescripcionGeneral(text){

		let var_description = text.target.value;

		this.setState({ descripcion_general: var_description }, () => {});

	}



	SearchFilterClientes(textSearch){

		     const newData = this.state.array_tmp_clientes.filter( function(item){
		         const itemData = item.filter_text.toUpperCase();
		         const textData = textSearch.target.value.toUpperCase();
		         return itemData.indexOf(textData) > -1;
		     });

		     this.setState({
		         dataSourceClientes: newData,
		         textSearch: textSearch
		     }, () => { /*console.log( this.state.dataSourceClientes )*/ });

	}



	AddCaso = () => {

		let obj = {};
	    var url = globalVars.url;

	    obj.materia_caso = this.state.materia_caso;
	    obj.id_materia = this.state.id_materia;
		obj.descripcion_general = this.state.descripcion_general;
		obj.submateria = this.state.submateria;
		obj.prefixSubmateria = this.state.prefixSubmateria;
		obj.id_cliente = this.state.id_cliente;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		// console.log(obj);
		if( this.state.materia_caso != '' && this.state.id_cliente != '' ){

			// console.log('entro');
			fetch(url+'/add-caso-api.php', {
				           headers:{  
				               "Accept": "application/json",
				               "Content-Type": "application/json"
				           },
				           method:'POST',
				           body: JSON.stringify({
				              obj,
				           }),

				    }).then( response => response.json() )
				      .then( responseJson => {

				      	//   console.log( responseJson );
						//   console.log(obj);

				          if(responseJson != 'No Add.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;
			          			
				          	}else{

				          		responseJson.forEach( item => {
						  		
								this.setState({id_caso_insertado: item.id_caso_insertado}, () => {});

							  	});
					            // alert('Se registro exitosamente el Caso');
					            this.setState({ alert_created: true });

				          	}

				           	
				          }
				          else{

				          	this.setState({ alert_error: true });
				          	setTimeout( () => { this.setState({ alert_error: false }) }, 2500);

				          }
				          
				      }).catch((error) => {
				        console.log(error);
				      });

		}
		else{

			this.setState({ warning_incomplete: true }, () => {});
			setTimeout( () => { this.setState({ alert_error: false }) }, 2500);
			
			window.scrollTo(0, 120);

		}


	}


	navigateToEditCase = () => {

		this.setState({ redirect: true }, () => {  });
	
	}



	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}


		if (this.state.redirect) {
         	return <Redirect to={{ pathname: '/editarcaso', state: { idCaso: this.state.id_caso_insertado } }} />;
      	}


		const styleIcons = {
			fontSize: "30px",
			color: "#FFF",

		}

		var render_submats = '';
		var render_clients = '';
		var render_alerta_error = null;
		var render_warning_incomplete = null;



		if( this.state.isLoadingClientes ){

			render_clients = <option>Cargando...</option>;

		} else{

			render_clients = this.state.dataSourceClientes.map((item, key) => ( <option value={item.id_cliente} key={key}>{ item.primer_nombre != '' ? item.primer_nombre + " " + item.segundo_nombre + " " + item.primer_apellido : item.razon_social}</option> ));

		}


		if( this.state.isLoadingArrayMatsSubMats ){

			render_submats = <option>Cargando...</option>;

		} else{

			render_submats = this.state.dataSourceArrayMatsSubMats.map((item, key) => ( <option key={key} value={key}>{ item.tipo_caso }</option> ));

		}



		if( this.state.alert_error ){

			render_alerta_error = <FormGroup><Alert color="danger">Ocurrio un error en la insercion del caso!</Alert></FormGroup>;
		
		}




		if( this.state.warning_incomplete ){

			render_warning_incomplete = <FormGroup><Alert color="danger">Los campos requeridos no estan completos!</Alert></FormGroup>;
		
		}else{

			render_warning_incomplete = null;

		}



		return(

				
					this.state.alert_created == false

						?

							<div className="container-addcaso-form">

								<Form className="form-add-caso">
								      
								      <div className="title-div-caso-form">
								        <Label className="title-add-caso-form">Nuevo caso</Label>
								      </div>
								      
								      <div className="logo-div-caso-form">
								        <FaBalanceScale style={ styleIcons } />
								      </div>

								      { render_alerta_error }

								      { render_warning_incomplete }

								      <FormGroup>
								        <Label for="materia">Materia de acción</Label>
								        <Input
									          type="select"
									          name="materia"
									          id="materia"
									          onChange={ (text) => { this.changeMateria(text) } }
								        	>
								        		<option value="_no_"> Seleccione la materia </option>
								        		{ render_submats }
								        </Input>
								      </FormGroup>	

								      <FormGroup>
								        <Label for="submateria">Submateria de caso</Label>
								        <Input
									          type="text"
									          name="submateria"
									          id="submateria"
									          placeholder="Submateria del caso"
									          onChange={ (text) => { this.setState({submateria: text.target.value}) } }
								        	/>
								      </FormGroup>	


								      <FormGroup>
								        <Label for="submateria">Identificador de Submateria en el Código (Solo 2 caracteres).</Label>
								        <Input
									          type="text"
									          name="prefixSubmateria"
									          id="prefixSubmateria"
									          placeholder="Identificador de submateria en código"
									          onChange={ (text) => { this.setState({prefixSubmateria: text.target.value}) } }
								        	/>
								      </FormGroup>								   
							   

								      <FormGroup>
								        <Label for="buscar_cliente">Buscar cliente</Label>
								        <Input
								          type="search"
								          name="buscar_cliente"
								          id="buscar_cliente"
								          placeholder="Buscar un cliente en la BDD"
								          onChange={ (textS) => { this.SearchFilterClientes(textS) } }
								        />
								      </FormGroup>

								      <FormGroup>
								        <Label for="cliente">Cliente</Label>
								        <Input
									          type="select"
									          name="cliente"
									          id="cliente"
									          onChange={ (text) => { this.changeCliente(text) } }
								        	>
								        	<option value="_no_"> Seleccione un cliente </option>
								        	{ render_clients }
								        </Input>
								      </FormGroup>
								      
								      <FormGroup>
								        <Label for="descripcion_general">Resumen general del caso</Label>
								        <Input 
								        		type="textarea" 
								        		name="descripcion_general" 
								        		id="descripcion_general" 
								        		onChange={ (text) => { this.changeDescripcionGeneral(text) } }
								        	/>
								      </FormGroup>

								      <FormGroup>
								      	<Button className="button_addcaso" onClick={ this.AddCaso }>Crear caso</Button>
								      </FormGroup>
							    </Form>

							</div>

						:

							<div>
								<Alert color="success">
        							Caso agregado con Exito!
      							</Alert>
      							<Button className="button_addcaso" onClick={ this.navigateToEditCase }>Editar el Caso</Button>
							</div>


		);


	}

}


export default AddCaso;