import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import BarraNavegacion from './Components/Navbar';
import MenuSection from './Components/Main';
import LoginScreen from './Components/Login';
import AddCasoScreen from './Components/AddCaso';
import EditarCasoScreen from './Components/EditCaso';
import ClientesScreen from './Components/Clientes';
import CasosScreen from './Components/ViewCasos';
import AgendasScreen from './Components/ViewAgendas';
import AlertasScreen from './Components/Alertas';
import StatisticsScreen from './Components/Informes';
import AdminScreen from './Components/Dashboard';


class App extends React.Component{


  render(){
    return(
          <Router>
            <Switch>
              <Route exact path="/"><PantallaLogin /></Route>
              <Route path="/menu"><PantallaMenu /></Route>  
              <Route path="/addcaso"><PantallaAnadirCaso /></Route>    
              <Route path="/editarcaso" render={ (props) => <PantallaEditarCaso {...props} /> }  ></Route> 
              <Route path="/clientes"><PantallaClientes /></Route>
              <Route path="/viewcasos" ><PantallaCasos /></Route>
              <Route path="/viewcasosfind" render={ (props) => <PantallaCasosFinded {...props} /> }  ></Route> 
              <Route path="/agendas"><PantallaAgendas /></Route>
              <Route path="/alertas"><PantallaAlertas /></Route>
              <Route path="/informes"><PantallaInformes /></Route>
              <Route path="/adminpanel"><PantallaAdmin /></Route>
            </Switch>
          </Router>
    );
  }
  
}

export default App;


function PantallaLogin() {
  return (
    <div className="App">
      <LoginScreen />
    </div>
  );
}

function PantallaMenu() {
  return (
    <div className="App">
      <BarraNavegacion />
      <MenuSection />
    </div>
  );
}


function PantallaAnadirCaso() {
  return (
    <div className="App">
      <BarraNavegacion />
      <AddCasoScreen />
    </div>
  );
}

function PantallaEditarCaso(props) {
  var passingVar = props.location.state === undefined ? null : props.location.state.idCaso;
  return (
    <div className="App">
      <BarraNavegacion />
      <EditarCasoScreen idCaso={ passingVar } />
    </div>

  );
}


function PantallaClientes() {
  return (
    <div className="App">
      <BarraNavegacion />
      <ClientesScreen />
    </div>
  );
}



function PantallaCasos() {
  return (
    <div className="App">
      <BarraNavegacion />
      <CasosScreen />
    </div>
  );
}


function PantallaCasosFinded(props) {
  var passingVar = props.location.state === undefined ? null : props.location.state.CasoRedirected;
  return (
    <div className="App">
      <BarraNavegacion />
      <CasosScreen CasoRedirected={ passingVar } />
    </div>
  );
}


function PantallaAgendas(){
  return (
    <div className="App">
      <BarraNavegacion />
      <AgendasScreen />
    </div>
  );
}


function PantallaAlertas(){
  return (
    <div className="App">
      <BarraNavegacion />
      <AlertasScreen />
    </div>
  );
}


function PantallaInformes(){
  return (
    <div className="App">
      <BarraNavegacion />
      <StatisticsScreen />
    </div>
  );
}


function PantallaAdmin(){
  return(
    <div className="App">
      <BarraNavegacion />
      <AdminScreen />
    </div>
  );
}