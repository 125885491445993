import React from 'react';
import { Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import { FaKeyboard } from 'react-icons/fa';
import { IoIosAddCircleOutline, IoIosSearch, IoIosList } from "react-icons/io";
import './Styles.css';


import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink, Redirect } from 'react-router-dom';



class MenuSection extends React.Component {

  constructor(props){
    super(props);
    this.state = {

      

    };
  }

  componentDidMount(){
    // console.log(JSON.parse(localStorage.getItem('currentUser')));
    // console.log(JSON.parse(localStorage.getItem('currentToken')));
    // localStorage.removeItem('currentUser');
    // localStorage.removeItem('currentToken');
    this.setVarsCurrentSession();
    this.setVarsPermissSession();
  }


  setVarsCurrentSession = () => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { });

  }
  
  setVarsPermissSession = () => {
    
    let arr_permiss = JSON.parse(localStorage.getItem('permissValid')) == null ? [] : JSON.parse(localStorage.getItem('permissValid'));
    if( arr_permiss.includes("1") == false ){
      this.setState({ blocked_informes: true }, () => {  } );
    }
      
  }

  render(){

      if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

        return <Redirect to={{ pathname: '/'}} />;
        //

      }      

      const styleIcons = {
        fontSize: "30px",
      };
      
      return (
        <div className="container-menu-options">
          <div className="autoMargin-option">
            <Row className="margin-top-row">
              <Col sm="4">
                <NavLink className="no-text-decoration" to="/addcaso">
                  <Card body>
                    <CardTitle>Crear nuevo caso</CardTitle>
                    <CardText> <IoIosAddCircleOutline style={ styleIcons } /> </CardText>
                    <Button>Agregar</Button>
                  </Card>
                </NavLink>
              </Col>
              <Col sm="3" style={{ display: "none" }} >
                <NavLink className="no-text-decoration" to="/viewcasos">
                  <Card body>
                    <CardTitle>Editar casos</CardTitle>
                    <CardText> <IoIosSearch style={ styleIcons } /> </CardText>
                    <Button>Ir a visualizar casos</Button>
                  </Card>
                </NavLink>
              </Col>
              <Col sm="4">
                <NavLink className="no-text-decoration" to="/agendas">
                  <Card body>
                    <CardTitle>Agendas</CardTitle>
                    <CardText> <IoIosList style={ styleIcons } /> </CardText>
                    <Button>Ir a agenda</Button>
                  </Card>
                </NavLink>
              </Col>
              <Col sm="4">
                <NavLink className="no-text-decoration" to="/adminpanel"> 
                  <Card body>
                    <CardTitle>Panel Administrativo</CardTitle>
                    <CardText> <FaKeyboard style={ styleIcons } /> </CardText>
                    <Button>Ir al panel</Button>
                  </Card>
                </NavLink>
              </Col>
            </Row>

            <h5 style={{ color: '#FFF', fontFamily: 'Crimson Text', fontStyle: 'italic',
                          position: 'absolute', bottom: '5px' }}>ideaTech Studio©</h5>
          </div>

        </div>
      );

      
  }


}


export default MenuSection;

