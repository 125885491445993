import React from 'react';
import { Col, Form,
			FormGroup, Label, Input,
			Button, Spinner, Table,
			Alert, CustomInput
	   } from 'reactstrap';
import './Styles.css';
import { FaBalanceScale } from 'react-icons/fa';

import globalVars from './constants';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Redirect} from 'react-router-dom';



class EditCaso extends React.Component{


	constructor(props){
		super(props);
		this.state = {

			idCaso: '',
			codigo_caso: '',
			id_materia_caso: '',
			materia_caso: '',
			submateria: '',
			descripcion_general: '',
			id_cliente: '',


			isLoadingCase: true,
			dataSourceArrayCase: [],


			isLoadingCaseEvents: true,
			dataSourceArrayEvents: [],

			isLoadingCaseUsers: true,
			dataSourceArrayUsersCase: [],

			eventos_a_anadir: [],

			anadir_evento_caso: '',
			fecha_evento_caso_anadir: '',
			hora_evento_caso_anadir: '',
			descripcion_evento_anadir: '',

			redirect_to_main: false,
			redirect_to_cases_view: false,

			warning_fields_add_event: false,
			warning_nada_x_actualizar: false,


			msj_done_save_edit: false,
			events__anadidos: [],
			msj__added: false,
			events_no_anadidos: [],
			msj__no__added: false,


			status_send_parts: 0,
			msj_partes_added: false,
			msj_partes_no_added: false,
			has_more_parts: 'off',

			parte_caso: '',
			another_parte: '',
			nombre_another_parte: '',


		};

	}

	componentDidMount(){

		var url = globalVars.url;

		this.setVarsCurrentSession(url);

	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => {
      		// console.log( this.state.currentUser );
      		this.setState({ idCaso: this.props.idCaso }, () => {

      			if( this.state.idCaso !== null ){
      				// alert( 'es el id: ' + this.state.idCaso );
					this.getInfoLastCaso( url, this.state.idCaso );
					this.get_parts_case( url, this.state.idCaso );
      			}else{
      				this.setState({ redirect_to_main: true });
      			}

			});

      });

  	}

	getInfoLastCaso = (url, idCaso) => {
		fetch( url + '/get-case.php', {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: idCaso,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),

		    	}).then( response => response.json() )
				  .then( responseJson => {

				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{
					          	responseJson.map( (item) =>{

				            		// console.log( item );

									this.setState({
										idCaso: item.id_caso,
										codigo_caso: item.codigo_caso,
										descripcion_general: item.descripcion_general,
										materia_caso: item.tipo_caso,
										submateria: item.submateria,
										id_materia_caso: item.id_materia,


									}, () => { /*console.log( this.state.idCaso )*/ });

				            	});

								this.setState({ isLoadingCase: false, });

					            // se llama al método que renderizará los eventos del caso
					            this.get_events_case(url, idCaso);
					            this.get_users_case(url, idCaso);
				        	}


				          }
				          else{

				          	this.setState({ redirect_to_main: true });

				          }

				   }).catch((error) => {
				        // console.log(error);
				     });

	}

	get_events_case = (url, idCaso) => {

		fetch( url + '/get-events-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: idCaso,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				          if(responseJson != 'No Results.'){

							if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{
								this.setState({ isLoadingCaseEvents: false, dataSourceArrayEvents: responseJson }, () => {  });
				            }

				          }

				   }).catch((error) => {
				        // console.log(error);
				     });

	}

	get_users_case = (url, idCaso) =>{

		fetch( url + '/get-users-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: idCaso,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				          if(responseJson != 'No Results.'){

							if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{
								this.setState({ isLoadingCaseUsers: false, dataSourceArrayUsersCase: responseJson }, () => {  });
								// console.log( responseJson );
							}

				          }

				   }).catch((error) => {
				        // console.log(error);
				     });

	}

	caption_info_evento_add = () => {

		let obj = {};

		let arr_tmp = this.state.eventos_a_anadir;

		obj.anadir_evento_caso = this.state.anadir_evento_caso;
		obj.fecha_evento_caso_anadir = this.state.fecha_evento_caso_anadir;
		obj.descripcion_evento_anadir = this.state.descripcion_evento_anadir;
		obj.hora_evento_caso_anadir = this.state.hora_evento_caso_anadir;

		if( obj.anadir_evento_caso != '' && obj.fecha_evento_caso_anadir != '' && obj.descripcion_evento_anadir != '' && obj.hora_evento_caso_anadir != '' ){

			arr_tmp.push(obj);
			this.setState({ eventos_a_anadir: arr_tmp }, () => {  });
			this.setState({ warning_fields_add_event: false });
			this.setState({
							anadir_evento_caso: '',
							fecha_evento_caso_anadir: '',
							descripcion_evento_anadir: '',
							hora_evento_caso_anadir: '',
						 }, () => { /*console.log();*/ } );

		}
		else{

			this.setState({ warning_fields_add_event: true });
			window.scrollTo(0, 120);

		}



	}


	render_eventos_anadidos_function(){

		var eventos_anadidos_render = null;

		if( this.state.eventos_a_anadir.length > 0 ){

      		eventos_anadidos_render = this.state.eventos_a_anadir.map( (item, index) =>

      			<tr id={index}> <th>{index + 1}</th> <th>{item.anadir_evento_caso}</th> <th>{item.fecha_evento_caso_anadir}</th> <th>{item.hora_evento_caso_anadir}</th> </tr>

      		);

      		return(

      					<FormGroup row className="tabla_de_eventos_a_anadir">
						  	<Table responsive>
						      <thead>
						        <tr>
						          <th>#</th>
						          <th>Evento</th>
						          <th>Fecha</th>
						          <th>Hora</th>
						        </tr>
						      </thead>
						      <tbody>

						        { eventos_anadidos_render }

						      </tbody>
						    </Table>
						</FormGroup>

      		);


      	}

      	else{

      		eventos_anadidos_render = null;

      		return eventos_anadidos_render;

      	}
      	//

	}


	get_parts_case = (url, idCaso) => {

		fetch( url + '/get-parts-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: idCaso,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				          if(responseJson != 'Sin Resultados.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{
								this.setState({
											status_send_parts: 1,
											parte_caso: responseJson[0]["parte"],
											another_parte:  responseJson[0]["contraparte"],
											nombre_another_parte: responseJson[0]["nombre_contraparte"]
										}, () => {  });
								// console.log( responseJson );
							}

				          }

				   }).catch((error) => {
				        // console.log(error);
				     });

	}


	render_has_more_parts_preview(){

		return (

      					<div>

	      					<FormGroup row>
								<Label for="parte_caso" sm={3}>Este caso es:</Label>
								<Col sm={9}>
							        <Input
							        		type="select"
							        		name="parte_caso"
							        		id="parte_caso"
							        		value={ this.state.parte_caso }
							        >
							        	<option value="0">{ this.state.parte_caso }</option>
							        </Input>
								</Col>
							</FormGroup>

							<FormGroup row>
								<Label for="another_parte" sm={3}>Contraparte:</Label>
								<Col sm={9}>
							        <Input
							        		type="select"
							        		name="another_parte"
							        		id="another_parte"
							        		value={ this.state.another_parte }
							        >
							        	<option value="0">{ this.state.another_parte }</option>
							        </Input>
								</Col>
							</FormGroup>

							<FormGroup row>
								<Label for="nombre_another_parte" sm={3}>Nombre(s):</Label>
								<Col sm={9}>
							        <Input
							        		type="text"
							        		name="nombre_another_parte"
							        		id="nombre_another_parte"
							        		placeholder="Nombre Contraparte"
							        		value={ this.state.nombre_another_parte }
							        />
								</Col>
							</FormGroup>

						</div>


      		);


	}


	render_has_more_parts(){

		if( this.state.has_more_parts == "off" ){

      		return(

      					<FormGroup row style={{ display: 'flex', justifyContent: 'center' }}>
					        <div>
					          <Label for="switchHasOtherPart">¿El caso tiene otras partes materiales?</Label>
					          <CustomInput
					          		type="switch"
					          		id="switchHasOtherPart"
					          		name="switchHasOtherPart"
					          		label="Enciendase, sí existen"
					          		onChange={ (text) => { this.setState({ has_more_parts: text.target.value }); }}
					          />
					        </div>
					    </FormGroup>

      		);


      	}

      	else{

      		return (

      					<div>

	      					<FormGroup row>
								<Label for="parte_caso" sm={3}>Este caso es:</Label>
								<Col sm={9}>
							        <Input
							        		type="select"
							        		name="parte_caso"
							        		id="parte_caso"
							        		value={ this.state.parte_caso }
							        		onChange={ (text)=>{ this.setState({ parte_caso: text.target.value }); } }
							        >
							        	<option value="0">Elija el rol</option>
							        	<option value="demandante">Demandante</option>
							        	<option value="demandado">Demandada</option>
							        	<option value="solicitante">Solicitante</option>
							        </Input>
								</Col>
							</FormGroup>

							<FormGroup row>
								<Label for="another_parte" sm={3}>Contraparte:</Label>
								<Col sm={9}>
							        <Input
							        		type="select"
							        		name="another_parte"
							        		id="another_parte"
							        		value={ this.state.another_parte }
							        		onChange={ (text)=>{ this.setState({ another_parte: text.target.value }); } }
							        >
							        	<option value="0">Elija el rol</option>
							        	<option value="demandante" style={{ display: this.state.parte_caso == 'demandante' ? 'none' : 'block' }}>Demandante</option>
							        	<option value="demandado" style={{ display: this.state.parte_caso == 'demandado' ? 'none' : 'block' }}>Demandada</option>
							        	<option value="solicitante" style={{ display: this.state.parte_caso == 'solicitante' ? 'none' : 'block' }}>Solicitante</option>
							        </Input>
								</Col>
							</FormGroup>

							<FormGroup row>
								<Label for="nombre_another_parte" sm={3}>Nombre(s):</Label>
								<Col sm={9}>
							        <Input
							        		type="text"
							        		name="nombre_another_parte"
							        		id="nombre_another_parte"
							        		placeholder="Nombre Contraparte"
							        		value={ this.state.nombre_another_parte }
							        		onChange={ (text)=>{ this.setState({ nombre_another_parte: text.target.value }); } }
							        />
								</Col>
							</FormGroup>

						</div>


      		);

      	}

	}


	EditCase = () => {

		var { idCaso } = this.state;
		var url = globalVars.url;
		//.....

		if( this.state.eventos_a_anadir.length > 0 || ( this.state.has_more_parts == "on" && this.state.parte_caso != '' && this.state.another_parte != '' && this.state.nombre_another_parte != '' ) ){

			let obj_eve = this.state.eventos_a_anadir;

			let objeto_partes = {};


			objeto_partes.parte_caso = this.state.parte_caso != '' ? this.state.parte_caso : null;
			objeto_partes.another_parte = this.state.another_parte != '' ? this.state.another_parte : null;
			objeto_partes.nombre_another_parte = this.state.nombre_another_parte != '' ? this.state.nombre_another_parte : null;
			objeto_partes.status_send_parts = this.state.status_send_parts;


			fetch( url + '/set-events-case-api.php',{
					   headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					idCaso: idCaso,
				              					eventsObj: obj_eve,
				              					objPartes: objeto_partes,
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'

				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {
				  		// console.log(responseJson);
				  		if( responseJson == "Token Error." ){
				  			localStorage.removeItem('permissValid');
				  			localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;
				  		}else{

				  		  if( responseJson == "key error." ){
				  		  	return null;
				  		  }

				          if( responseJson.length > 0 && responseJson[0] == 'Complete rutine.'){

							if( responseJson[1].length )
							{
								this.setState({ events__anadidos: responseJson[1], msj__added: true });
							}
							if( responseJson[2].length ){

								this.setState({ events_no_anadidos: responseJson[2], msj__no__added: true });

							}

							if( responseJson[3] != 'Datos Existen.' )
							{
								if( responseJson[3] == 'Partes Añadidas.' ){
									this.setState({ msj_partes_added: true });
								}
								if( responseJson[3] == 'Partes no Añadidas.' ){
									this.setState({ msj_partes_no_added: true });
								}

							}

							this.setState({ msj_done_save_edit: true });

							// console.log( responseJson );

				          }

				      	}

				   }).catch((error) => {
				        // console.log(error);
				     });

		}
		else{

			this.setState({ warning_nada_x_actualizar: true });
			setTimeout(() => {

			  this.setState({ warning_nada_x_actualizar: false });

			}, 5000);

			window.scrollTo(0, 110);

		}

	}



	navigateToViewCases = () => {

		this.setState({ redirect_to_cases_view: true }, () => {  });

	}



	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}

		if ( this.state.redirect_to_main ) {
         	return <Redirect to="/menu" />;
      	}

      	if( this.state.redirect_to_cases_view ){
      		return <Redirect to="/viewcasos" />;
      	}

		var render_loading_caso = null;
		var render_events_caso = null;
		var render_users_caso = null;
		var render_warning_fields_add_event = null;
		var render_warning_nada_x_actualizar = null;
		var render_eventos__added = null;
		var render_eventos_no__added = null;
		var render_warning_partes_add = null;
		var render_warning_partes_no_add = null;
		let render_conditional_eventos_add = this.render_eventos_anadidos_function();
		let render_has_more_parts = this.state.status_send_parts != 1 ? this.render_has_more_parts() : this.render_has_more_parts_preview();

		const styles = {

			styleIcons: {
				fontSize: "30px",
				color: "#FFF",
			},

			styleLabelAddEvnt: {
				fontWeight: "bold",
				fontSize: "20px"
			},
		};


      	if( this.state.isLoadingCase ){
      		render_loading_caso = <div><Spinner color="success" /></div>;
      	}


      	if( this.state.isLoadingCaseEvents ){

      		render_events_caso = null;

      	}else{

      		render_events_caso = this.state.dataSourceArrayEvents.map( (item, index) =>

      			<tr id={index}> <th>{index + 1}</th> <th>{item.evento}</th> <th style={{ color: item.estado == 'COMPLETADO' ? " #58d68d" : '#FFF' }}>{item.estado}</th> <th>{item.fecha}</th> <th>{item.hora}</th> </tr>

      		);

      	}

      	if( this.state.isLoadingCaseUsers ){

      		render_users_caso = <option> Sin usuarios añadidos </option>;

      	}else{

      		render_users_caso = this.state.dataSourceArrayUsersCase.map( (item, index) =>

      			<option key={index} selected> {item.username} </option>

      		);

      	}

      	if( this.state.warning_fields_add_event ){
      		render_warning_fields_add_event = <Alert style={{marginTop: "15px"}} color="warning">Completa los campos para añadir un nuevo evento!</Alert>;
      	}

      	if( this.state.warning_nada_x_actualizar ){
      		render_warning_nada_x_actualizar = <Alert style={{marginTop: '10px'}} color="warning">No hay nada que actualizar</Alert>;
      	}

      	if( this.state.msj__added ){

      		render_eventos__added = this.state.events__anadidos.map( (item, index) =>

      				<Alert key={index} style={{marginTop: "15px", }} color="primary">Evento "{item.evento}" añadido!</Alert>

      		);

      	}

      	if( this.state.msj__no__added ){

      		render_eventos_no__added = this.state.events_no_anadidos.map( (item, index) =>

      				<Alert key={index} style={{marginTop: "15px"}} color="danger">Error evento "{item.evento}" no añadido!</Alert>

      		);

      	}

      	if( this.state.msj_partes_added ){

      		render_warning_partes_add = <Alert style={{marginTop: "15px"}} color="primary">Se añadieron las partes del caso!</Alert>;

      	}

      	if( this.state.msj_partes_no_added ){

      		render_warning_partes_no_add = <Alert style={{marginTop: "15px"}} color="danger">No se añadieron las partes del caso, Error de Red!</Alert>;

      	}


		return(

			this.state.msj_done_save_edit == true

			?

				<div style={{ width: "100%", backgroundColor: "#FFF", padding: "25px" }}>
					{ render_eventos__added }
					{ render_eventos_no__added }
					{ render_warning_partes_add }
					{ render_warning_partes_no_add }
					<Button className="button_redirect_cases" onClick={ this.navigateToViewCases }>Continuar</Button>
				</div>

			:

				<div className="container-editcaso-form">

					<div className="columna-de-form-edit-caso">

						{ render_warning_nada_x_actualizar }

						<Form>

							  <div className="title-div-caso-form">
								<h3 className="title-add-caso-form">Editando caso</h3>
							  </div>

							  <div className="">
								<FaBalanceScale style={ styles.styleIcons } />
							  </div>

							  <hr style={{backgroundColor: "#FFF", height: "1px" }} />

							  { render_loading_caso }

						      <FormGroup row>
						        <Label for="codigo_caso" sm={3}>Codigo:</Label>
						        <Col sm={9}>
						          <Input
						          			type="text"
						          			name="codigo_caso"
						          			id="codigoCaso"
						          			placeholder="Codigo del caso"
						          			value={ this.state.codigo_caso }
						          />
						        </Col>
						      </FormGroup>

						      <FormGroup row>
						        <Label for="materia" sm={3}>Materia:</Label>
						        <Col sm={9}>
						          <Input
						          			type="text"
						          			name="materia"
						          			id="materiaCaso"
						          			placeholder="Materia de accion"
						          			value={ this.state.materia_caso }
						          />
						        </Col>
						      </FormGroup>

						      <FormGroup row>
						        <Label for="submateria" sm={3}>Submateria:</Label>
						        <Col sm={9}>
						          <Input
						          			type="text"
						          			name="submateria"
						          			id="submateria"
						          			placeholder="Submateria del caso"
						          			value={ this.state.submateria }
						          />
						        </Col>
						      </FormGroup>

						      <FormGroup row>
								<Label for="descripcion_general" sm={3}>Resumen:</Label>
								<Col sm={9}>
							        <Input
							        		type="textarea"	
							        		name="descripcion_general"
							        		id="descripcion_general"
							        		value={ this.state.descripcion_general }
							        />
								</Col>
							  </FormGroup>

							  <FormGroup row>
						        <Label for="usersCase" sm={3}>Participantes:</Label>
						        <Col sm={9}>
						          <Input type="select" name="usersCase" id="usersCase" multiple>
						            { render_users_caso }
						          </Input>
						        </Col>
						      </FormGroup>

							  <FormGroup row className="tabla_de_eventos_ya_anadidos">
							  	<Table responsive>
							      <thead>
							        <tr>
							          <th>#</th>
							          <th>Evento</th>
							          <th>Estado</th>
							          <th>Fecha</th>
							          <th>Hora</th>
							        </tr>
							      </thead>
							      <tbody>
							        { render_events_caso }
							      </tbody>
							    </Table>
							  </FormGroup>

					    </Form>
				    </div>

				    <div className="columna-de-form-edit-caso">
				    	<Form>

					    <FormGroup row className="anadir_evento">
					       { render_warning_fields_add_event }
						   <Label for="descripcion_general" sm={12} style={ styles.styleLabelAddEvnt }>Añadir evento nuevo</Label>
						   <hr />

						   <Col className="anadir_evento_caso_label" sm={4}>
						   		<Label className="anadir_evento_caso_label" for="anadir_evento_caso">Evento a añadir:</Label>
						   </Col>
						   <Col sm={8}>
							   <Input
							        		type="text"
							        		name="anadir_evento_caso"
							        		id="anadir_evento_caso"
							        		placeholder="Evento"
							        		value={ this.state.anadir_evento_caso }
							        		onChange={(text) => { this.setState({anadir_evento_caso: text.target.value}); }}
							        />
						   </Col>
						   <Col sm={12}><br /></Col>
						   <Col className="anadir_evento_caso_label" sm={4}>
						        <Label className="anadir_evento_caso_label" for="fecha_evento_caso_anadir">Fecha evento:</Label>
						   </Col>
							<Col sm={8}>
						        <Input
						        		name="fecha_evento_caso_anadir"
						        		id="fecha_evento_caso_anadir"
						        		type="date"
							            placeholder="Seleccione una fecha"
							            value={ this.state.fecha_evento_caso_anadir }
							            onChange={(text) => { this.setState({fecha_evento_caso_anadir: text.target.value}); }}
						        />
							</Col>
							<Col sm={12}><br /></Col>
							<Col className="anadir_evento_caso_label" sm={4}>
						        <Label className="anadir_evento_caso_label" for="hora_evento_caso_anadir">Hora evento:</Label>
						   	</Col>
							<Col sm={8}>
						        <Input
						        		name="hora_evento_caso_anadir"
						        		id="hora_evento_caso_anadir"
						        		type="time"
							            placeholder="Seleccione una hora"
							            value={ this.state.hora_evento_caso_anadir }
							            onChange={(text) => { this.setState({hora_evento_caso_anadir: text.target.value}); }}
						        />
							</Col>
							<Col sm={12}><br /></Col>
							<Col className="anadir_evento_caso_label" sm={4}>
								<Label className="anadir_evento_caso_label" for="fecha_evento_caso_anadir">Finalidad Evento:</Label>
							</Col>
							<Col sm={8}>
								<Input
						          type="textarea"
						          name="descripcion_evento_anadir"
						          id="descripcion_evento_anadir"
						          placeholder="Describa el evento"
						          value={ this.state.descripcion_evento_anadir }
						          onChange={(text) => { this.setState({descripcion_evento_anadir: text.target.value}); }}
						        />
							</Col>
							<Col sm={12}><br /></Col>
							<Button sm={12} className="button_addcaso" onClick={ this.caption_info_evento_add }>+</Button>
					    </FormGroup>

					    { render_conditional_eventos_add }

					    { render_has_more_parts }

					    <FormGroup>
					    	<div className="game_buttons">
								<Button className="button_edit_case" onClick={ this.EditCase }>Editar Caso</Button>
								<Button className="button_edit_case" onClick={ this.navigateToViewCases }>Ir a casos</Button>
							</div>
						</FormGroup>

					    </Form>
				    </div>

				    <br /><br />

				</div>

		);

	}



}



export default EditCaso;
