import React from 'react';
import { 
			Col,
			Spinner
		} from 'reactstrap';
import './Styles.css';
import { FaRegChartBar } from 'react-icons/fa';
import { IoIosNotifications, IoIosStats } from "react-icons/io";
import { Bar, Line } from 'react-chartjs-2';

import globalVars from './constants';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Redirect} from 'react-router-dom';


export default class Estadisticas extends React.Component{


	constructor(props){
		super(props);
		this.state = {

			url: globalVars.url,
			isLoading: true,
			dataSource: [],

			apagar_estadisticas_casos_cerrados: false,
			chartCasosCerrados: {},

			apagar_estadisticas_acciones_por_mes: false,
			chartAccionesPorMes: {},

			arrayDataGrapics: [],
			apagar_estadisticas_acciones_x_dia: true,

			charBarData: {
				labels: ['dato 1', 'dato 2', 'dato 3', 'dato 4', 'dato 5', 'dato 6'],
				datasets: [
					{
						label: 'Población',
						data: [
							16000,
							16500,
							17000,
							19000,
							20100,
							22500
						],
						backgroundColor: [
							'rgba(255, 99, 132, 0.6)',
							'rgba(54, 162, 235, 0.6)',
							'rgba(255, 206, 86, 0.6)',
							'rgba(75, 192, 192, 0.6)',
							'rgba(255, 159, 64, 0.6)',
              				'rgba(255, 99, 132, 0.6)'
						],
						borderColor: [
							'rgba(0, 0, 0, 0.5)',
							'rgba(0, 0, 0, 0.5)',
							'rgba(0, 0, 0, 0.5)',
							'rgba(0, 0, 0, 0.5)',
							'rgba(0, 0, 0, 0.5)',
							'rgba(0, 0, 0, 0.5)'
						],
					}
				],
			},

			charLineData: {
				labels: ['dato 1', 'dato 2', 'dato 3', 'dato 4'],
				datasets: [
					
					{
						label: 'Población1',
						data: [
							0,
							16000,
							16500,
							17000,
							19000
						],
						backgroundColor: "rgba(255, 0, 255, 0.75)",
						borderColor: 'rgba(0, 0, 0, 0.2)',
						borderWidth: 2
					},
					{
						label: 'Población2',
						data: [
							0,
							8000,
							6500,
							12000,
							17200
						],
						backgroundColor: "rgba(0, 255, 0, 0.75)",
						borderColor: 'rgba(0, 0, 0, 0.5)',
						borderWidth: 2,

					},

				],
			}

		};
	}


	componentDidMount(){

    	this.setVarsCurrentSession();

	}


	setVarsCurrentSession = () => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { 
      		this.get_plane_information( this.state.url );
      });

  	}


	get_plane_information = (url) => {

		return fetch( url + '/get-statistics-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

		          		if( responseJson != 'No Results.' ){

		          			if( responseJson == 'Token Error.' ){

		          				localStorage.removeItem('permissValid');
		          				localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			
		          			}else{

		          				this.setState({
						            isLoading: false,
						            dataSource: responseJson,
						        }, function() {
						            // In this block you can do something with new state.
						            // console.log( responseJson );
						            // console.log( this.state.dataSource["casos_cerrados"] );
						            this.get_casos_cerrados( this.state.dataSource["casos_cerrados"] );
						            this.get_acciones_por_mes( this.state.dataSource["acciones_por_mes"] );
						            
						            
						        });

		          			}

		          		}

			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}


	get_casos_cerrados = (array_data) => {

		if( array_data.length < 2 ){
			this.setState({ apagar_estadisticas_casos_cerrados: true });
			return 0;
		}

		var labels_tmp = [];
        array_data.map((item, key) => {
        	labels_tmp.push( item.mes_name );
        });
        // console.log( labels_tmp );

        var backgroundColor_arr = [];
        var borderColor_arr = [];
        var data_arr_tmp = [];
        array_data.map((item, key) => {
        	var new_color = '';
        	data_arr_tmp.push( item.casos_cerrados );
        	new_color = ( 'rgba(' + (Math.floor(Math.random() * 256)) + ',' 
                                             + (Math.floor(Math.random() * 256)) + ',' 
                                             + (Math.floor(Math.random() * 256))
                                     );
        	backgroundColor_arr.push( new_color + ', 0.3)' );
        	borderColor_arr.push( new_color + ', 1.0)' );
        });

        this.setState({
        	chartCasosCerrados: {
        		labels: labels_tmp,
        		datasets: [{
        			label: 'Casos cerrados',
        			data: data_arr_tmp,
        			backgroundColor: backgroundColor_arr,
        			borderColor: borderColor_arr,
        			borderWidth: 2
        		}],
        		
        	}
        }, () => { /* console.log(this.state.chartCasosCerrados); */ });

	}


	get_acciones_por_mes = (array_data) => {

		if( array_data.length < 2 ){
			this.setState({ apagar_estadisticas_acciones_por_mes: true });
			return 0;
		}

		var labels_tmp = [];
        array_data.map((item, key) => {
        	labels_tmp.push( item.mes_name );
        });
        // console.log( labels_tmp );

        var data_arr_tmp = [];
        array_data.map((item, key) => {
        	data_arr_tmp.push( item.acciones_por_mes );
        });

        this.setState({
        	chartAccionesPorMes: {
        		labels: labels_tmp,
        		datasets: [{
        			label: 'Acciones por mes',
        			data: data_arr_tmp,
        			backgroundColor: 'rgba(174, 182, 191, 0.2)',
        			borderColor: 'rgba(174, 182, 191, 1.0)',
        			borderWidth: 2
        		}],
        		
        	}
        }, () => {  /* console.log(this.state.chartAccionesPorMes); */  });

        this.get_current_data_db(this.state.url);

	}


	get_current_data_db = (url) => {

		return fetch( url + '/get-current-user-activity.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {
		          		// console.log( responseJson );
		          		if( responseJson != 'No Results.' ){

		          			if( responseJson == 'Token Error.' ){

		          				localStorage.removeItem('permissValid');
		          				localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});

		          			}else{

						          this.setState({
						            dataSourceData: responseJson,

						          }, function() {
						            // In this block you can do something with new state.
						            // console.log( responseJson );
						            var longit = this.state.dataSourceData.length;
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 7] );
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 6] );
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 5] );
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 4] );
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 3] );
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 2] );
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 1] );

						            // console.log( this.state.arrayDataGrapics );

						            var labels_tmp = [];
							        this.state.arrayDataGrapics.map((item, key) => {
							        	labels_tmp.push( item.fecha );
							        });
							        // console.log( labels_tmp );

							        var data_arr_tmp = [];
							        this.state.arrayDataGrapics.map((item, key) => {
							        	data_arr_tmp.push( item.conteo );
							        });

							        this.setState({
							        	chartOperacionesPorDia: {
							        		labels: labels_tmp,
							        		datasets: [{
							        			label: 'Acciones por día',
							        			data: data_arr_tmp,
							        			backgroundColor: 'rgba( 26, 188, 156, 0.2)',
							        			borderColor: 'rgba( 26, 188, 156, 1.0)',
							        			borderWidth: 2
							        		}],
							        		
							        	},
							        	apagar_estadisticas_acciones_x_dia: false
							        }, () => {  /* console.log(this.state.chartAccionesPorMes); */  });
						            
						          });

						    }

						}

			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}


	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}

		const styles = {

			styleIcons: {
				fontSize: "30px",
				color: " #34495e ",
			},

			fontTitle: {
				color: '#34495e',
			},

			cardAlert: {
				fontFamily: 'Crimson Text',
				width: '75%',
				boxShadow: '0px 0px 25px #0000004F',
				borderRadius: '3px',
				margin: '0px auto 40px',
				fontWeight: 700,
				height: 'max-content',

			},

			topCard: {
				background: 'linear-gradient(135deg, rgba(52,156,247,1) 0%, rgba(147,234,240,1) 100%)',
				height: '120px',
				color: '#fff',
				padding: '15px',
				borderTopLeftRadius: '3px',
				borderTopRightRadius: '3px',
				fontSize: '20px',
			},

			footerCard: {
				color: '#FF5B5B',
				padding: '5px 15px',
				textAlign: 'right',
				height: '50px',
				borderBottomLeftRadius: '3px',
				borderBottomRightRadius: '3px',
			},

			notifyIcon: {
				margin: '0 10px',
				fontSize: '18px'
			},

			cardTop: {
				fontFamily: 'Crimson Text',
				width: '75%',
				boxShadow: '0px 0px 25px #0000004F',
				borderRadius: '3px',
				margin: '0px auto 40px',
				fontWeight: 700,
				fontSize: '20px'
			},

			topCardTop: {
				background: 'linear-gradient(135deg, rgba(121,60,181,1) 0%, rgba(152,87,217,1) 100%)',
				height: '120px',
				color: '#fff',
				padding: '15px',
				fontSize: '25px',
				borderTopLeftRadius: '3px',
				borderTopRightRadius: '3px',
			},

			footerCardTop: {
				color: '#783CB5',
				padding: '5px 15px',
				textAlign: 'right',
				height: '50px',
				borderBottomLeftRadius: '3px',
				borderBottomRightRadius: '3px',
			},

		};


		var events_status_cards = null;


		if( this.state.isLoading ){
			
			return(

				<div className="container-first control-panel-chart">

					<Col sm={12} className="sidebar-4-header">
						<h3 style={ styles.fontTitle }> Informes y Análisis </h3>
						<FaRegChartBar style={ styles.styleIcons } />
						<hr />
					</Col>

					<Col sm={12} className="statistics-spinners-loading">
						<Spinner type="grow" color="primary" />
      					<Spinner type="grow" color="primary" />
      					<Spinner type="grow" color="primary" />
					</Col>

				</div>
			);

		}

		if( this.state.dataSource["estado_eventos"].length > 0 ){

			events_status_cards = this.state.dataSource["estado_eventos"].map( (item, index) => 
				<div style={ styles.cardAlert }>
					<div style={ styles.topCard }>
						<p>{ item.estado }</p>
						<p style={{ fontSize: '35px' }}>{ item.CONTEO }</p>
					</div>
					<div style={ styles.footerCard }>
						<IoIosStats style={ styles.notifyIcon } />
						estado eventos
					</div>
				</div>
			);

		}

		return(

			<div className="container-first control-panel-chart">

				<Col sm={12} className="sidebar-4-header">
					<h3 style={ styles.fontTitle }> Informes y Análisis </h3>
					<FaRegChartBar style={ styles.styleIcons } />
					<hr />
				</Col>

				<div className="chart-container ">
					<div style={ styles.cardTop }>
						<div style={ styles.topCardTop }>
							<p>Casos activos</p>
							<p> { this.state.dataSource["total_casos_activos"].total_casos } </p>
						</div>
						<div style={ styles.footerCardTop }>
							<IoIosNotifications style={ styles.notifyIcon } />
							Actualizado justo ahora
						</div>
					</div>
				</div>

				<Col sm={12} className="sidebar-4-header">
					<h3 style={ styles.fontTitle }> Estado de Eventos </h3>
					<hr />
				</Col>

				<div className="chart-container auto-height-panel dashboard-cards-container">
					
					{ events_status_cards }

				</div>

				<Col sm={12} className="sidebar-4-header">
					<h3 style={ styles.fontTitle }> Estadísticas </h3>
					<hr />
				</Col>

				{
					this.state.apagar_estadisticas_casos_cerrados

					?

					null

					:

					<div className="chart-container">
						<Bar 
							data={ this.state.chartCasosCerrados }
							options={{
								title: {
									display: true,
									text: 'Casos cerrados',
									fontSize: 25
								},
								legend:{
									display: false,
									position: 'right'
								},
								maintainAspectRatio: false 
							}}
						/>
					</div>

				}
				
				{
					this.state.apagar_estadisticas_acciones_por_mes

					?

					null

					:

					<div className="chart-container">
						<Line 
							options={{
								responsive: true,
								maintainAspectRatio: false,
								title: {
									display: true,
									text: 'Acciones por mes',
									fontSize: 25
								},
								legend:{
									display: true,
									position: 'right'
								}, 
							}}
							data={ this.state.chartAccionesPorMes }
						/>
					</div>

				}
				
				
				{
					this.state.apagar_estadisticas_acciones_x_dia

					?

					null

					:

					<div className="chart-container">
						<Line 
							options={{
								responsive: true,
								maintainAspectRatio: false,
								title: {
									display: true,
									text: 'Operaciones en base de datos por día - últimos 7 días',
									fontSize: 25
								},
								legend:{
									display: true,
									position: 'right'
								}, 
							}}
							data={ this.state.chartOperacionesPorDia }
						/>
					</div>

				}

				

			</div>

		);
	
	}


}