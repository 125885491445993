import React from 'react';
import './Styles.css';

import globalVars from './constants';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Redirect} from 'react-router-dom';
import { Alert } from 'reactstrap';


import {
  Container, Col, Form,
  FormGroup, Label, Input,
  Button, FormFeedback,
} from 'reactstrap';


class LoginScreen extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      username: '',
      password: '',
      redirect: false,
      view_pass: false,
      message_error_validating: false,
      alert_error_login: false,
    };
  }


  componentDidMount(){

    this.changeStateRedirect();

  }


  changeUsername(username){
    this.setState({username: username.target.value});
  }

  changePassword(password){
    this.setState({password: password.target.value});
  }

  getPermissOpertions = () => {

    let obj = {};
    var url = globalVars.url;

    obj.username  = this.state.username;

    if( obj.username != '' ){

      fetch(url+'/get-userpermit-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba', {
                 headers:{  
                     "Accept": "application/json",
                     "Content-Type": "application/json"
                 },
                 method:'POST',
                 body: JSON.stringify({
                    username: obj.username,
                 }),

          }).then( response => response.json() )
            .then( responseJson => {

                if(responseJson != 'Incorrecto'){
                  
                  if( responseJson != 'Sin resultados.' ){

                    let arr_actions = [];
                    responseJson.map((item, index) => {

                      arr_actions.push(item.accion);

                    });

                    this.saveUserPermiss(arr_actions);

                  }else{

                    let arr_actions = [];
                    this.saveUserPermiss(arr_actions);                

                  }

                }
                else{
                  // console.log( responseJson );
                  // this.setState({alert_error_login: true });
                }
                
            }).catch((error) => {
              // console.log(error);
            });

    }
    else{

      // console.log('Error');

    }

  }

  login = () => {    

    // console.log('entro');
    let obj = {};
    var url = globalVars.url;

    obj.username  = this.state.username;
    obj.password = this.state.password;

    // var data = new FormData();

    // data.append('username', obj.username);
    // data.append('password', obj.password);

    if( obj.username != '' && obj.password != '' ){

      fetch(url+'/login-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba', {
                 headers:{  
                     "Accept": "application/json",
                     "Content-Type": "application/json"
                 },
                 method:'POST',
                 body: JSON.stringify({
                    username: obj.username,
                    password: obj.password,
                 }),

          }).then( response => response.json() )
            .then( responseJson => {

                if(responseJson != 'Incorrecto'){
                  // console.log( responseJson );
                  this.getPermissOpertions();
                  this.saveToken( responseJson );
                }
                else{
                  // console.log( responseJson );
                  this.setState({alert_error_login: true });
                }
                
            }).catch((error) => {
              // console.log(error);
            });

    }
    else{

      this.setState({ message_error_validating: true });

    }


  }


  saveToken = (token) => {

    localStorage.setItem( 'currentUser', JSON.stringify(this.state.username) );
    localStorage.setItem( 'currentToken', JSON.stringify(token) );
    localStorage.removeItem('currentNav');
    this.setState({ redirect: true });

  }

  verifyToken = () => {

    let token = JSON.parse(localStorage.getItem('currentToken'));
    let response = (token !== null) ? true : false;

    return response;

  }

  saveUserPermiss = (arr_permiss) => {

    localStorage.setItem( 'permissValid', JSON.stringify(arr_permiss) );
    // console.log( JSON.parse(localStorage.getItem('permissValid')) );

  }

  changeStateRedirect = () => {

    this.setState({ redirect: this.verifyToken() }, () => { } );

  }
  
  render(){

      if (this.state.redirect) {
         return <Redirect to='/menu' />;
      }

      var render_message_error_validating = null;
      var render_message_error_login = null;

      if( this.state.message_error_validating ){

        render_message_error_validating = <Alert color="danger">Debe ingresar un usuario y una clave.</Alert>;

      }

      if( this.state.alert_error_login ){

        render_message_error_login = <FormFeedback style={{display: "block", }}>Hay un error en el usuario o el password!!</FormFeedback>;

      }


      return (
        <div className="loginwrapper">
          <Container className="container-login-form">

            { render_message_error_validating }

            <h2>Iniciar Sesión</h2>
            
            <Form className="form">
              <Col>
                <FormGroup>
                  <Label>Usuario</Label>
                  <Input
                    className="input-login"
                    type="username"
                    name="username"
                    id="username"
                    placeholder="Nombre de Usuario"
                    value={ this.state.username }
                    onChange={ (text) => { this.changeUsername(text) } }
                  />
                  
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="passwordApp">Contraseña</Label>
                  <p style={{ display: this.state.view_pass ? 'block' : 'none', color: 'yellow' }} >{ this.state.password }</p>
                  <p style={{ fontSize: '10px', cursor: 'pointer' }} onClick={ () => this.setState({ view_pass: !this.state.view_pass }) } >Ver Contraseña</p>
                  <Input
                    className="input-login"
                    type="password"
                    name="password"
                    id="userPassword"
                    placeholder="Mi Clave"
                    value={this.state.password}
                    onChange={(text) => { this.changePassword(text) }}
                  />
                </FormGroup>
              </Col>
              <FormGroup>
                { render_message_error_login }
              </FormGroup>
              <FormGroup>
                <Button onClick={ this.login }>Acceder</Button>
              </FormGroup>
            </Form>

          </Container>
        </div>
      );
  }


}


export default LoginScreen;

