import React from 'react';
import './Styles.css';

import globalVars from './constants';

import 'bootstrap/dist/css/bootstrap.min.css';

import {NavLink, Redirect} from 'react-router-dom';
import { FaPowerOff } from 'react-icons/fa';


class BarraNavegacion extends React.Component {
  

  constructor(props){
    super(props);
    this.state = {
      selection: 0,
      url: globalVars.url,
      blocked_informes: false,
    };
  }

  componentDidMount(){

    this.setVarsCurrentSession();

    let select = JSON.parse(localStorage.getItem('currentNav'));
    if( select != null ){

      this.setState({ selection: select});

    }

    this.setVarsPermissSession();

  }

  setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { });

    }

  change_selection = (estatus) => {

    this.setState({ selection: estatus });
    localStorage.setItem( 'currentNav', JSON.stringify(estatus) );
    

  }

  setVarsPermissSession = () => {
    
    if( JSON.parse(localStorage.getItem('permissValid')) == null ){

      this.setState({ validando_permisos: true });

    }else{

      let arr_permiss = JSON.parse(localStorage.getItem('permissValid')) == null ? [] : JSON.parse(localStorage.getItem('permissValid'));
      if( arr_permiss.includes("1") == false ){
        this.setState({ blocked_informes: true, validando_permisos: false }, () => { /*console.log( arr_permiss, this.state.blocked_informes );*/ } );
      }else{
        this.setState({ validando_permisos: false });
      }

    }
      
  }


  logOutSession = () => {

    let obj = {};

    var url = this.state.url; 

    obj.token = this.state.currentToken;
    obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

    fetch( url + '/logout-process-api.php',{
             headers:{  
                     "Accept": "application/json",
                     "Content-Type": "application/json"
                 },
                 method:'POST', 
                 body: JSON.stringify({
                                obj: obj,
                  }),
        }).then( response => response.json() )
          .then( responseJson => {

                // console.log( responseJson );
                  localStorage.removeItem('permissValid');
                  localStorage.removeItem('currentUser');
                  localStorage.removeItem('currentToken');
                  this.setVarsCurrentSession();
                  
           }).catch((error) => {
                // 
             });

  }
  
  render(){

      if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

        return <Redirect to={{ pathname: '/'}} />;
        //

      }

      var informes_option = null;

      if( this.state.validando_permisos ){

        informes_option = <li><a className="effect-bottom-navbar-link"><NavLink disabled style={{ fontWeight: this.state.selection == 4 ? "700" : '400', color: this.state.selection == 4 ? "#FFF" : "#DDD" }} to="">Validando Permisos</NavLink></a></li>;

      }else{

        if ( this.state.blocked_informes ){

          informes_option = <li><a className="effect-bottom-navbar-link"><NavLink disabled style={{ fontWeight: this.state.selection == 4 ? "700" : '400', color: this.state.selection == 4 ? "#FFF" : "#DDD" }} to="">No disponible</NavLink></a></li>;
          //

        }else{

          informes_option = <li onClick={ () => this.change_selection(4) }><a className="effect-bottom-navbar-link"><NavLink style={{ fontWeight: this.state.selection == 4 ? "700" : '400', color: this.state.selection == 4 ? "#FFF" : "#DDD" }} to="/informes">Visualizar Informes</NavLink></a></li>;

        }

        //

      }


      return (
        <div className="navwrapper">
          <div className="nav">        
            <ul className="navlist">
              <li onClick={ () => this.change_selection(3) }><a className="effect-bottom-navbar-link"><NavLink style={{ fontWeight: this.state.selection == 3 ? "700" : '400', color: this.state.selection == 3 ? "#FFF" : "#DDD" }} to="/menu">Menú</NavLink></a></li>
              <li onClick={ () => this.change_selection(2) }><a className="effect-bottom-navbar-link"><NavLink style={{ fontWeight: this.state.selection == 2 ? "700" : '400', color: this.state.selection == 2 ? "#FFF" : "#DDD" }} to="/clientes">Control de Clientes</NavLink></a></li>
              <li onClick={ () => this.change_selection(5) }><a className="effect-bottom-navbar-link"><NavLink style={{ fontWeight: this.state.selection == 5 ? "700" : '400', color: this.state.selection == 5 ? "#FFF" : "#DDD" }} to="/viewcasos">Casos</NavLink></a></li>
              <li onClick={ () => this.change_selection(1) }><a className="effect-bottom-navbar-link"><NavLink style={{ fontWeight: this.state.selection == 1 ? "700" : '400', color: this.state.selection == 1 ? "#FFF" : "#DDD" }} to="/alertas">Bitácora</NavLink></a></li>
              {/* { informes_option } */}
            </ul>
          </div>
          <span className="float-out-component" onClick={ () => { this.logOutSession(); } }><FaPowerOff style={{ color: ' #cb4335 ' }}/></span>
        </div>




      );
  }


}


export default BarraNavegacion;
