import React from 'react';
import { 
			Form, 
			FormGroup, 
			FormText,
			Label, 
			Input, 
			Button, 
			ButtonGroup, 
			Alert, 
			Col,
			Row,
			Nav, 
			NavItem, 
			NavLink,
			Table,
			Spinner
		} from 'reactstrap';
import './Styles.css';
import { Line } from 'react-chartjs-2';
import { FaPlus, FaUserCog, FaUserEdit, FaMinus, FaFileExport, FaSearch } from 'react-icons/fa';

import globalVars from './constants';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Redirect} from 'react-router-dom';


export default class Dashboard extends React.Component{


	constructor(props){
		super(props);
		this.state = {

			li_value: 1,

			redirect: false,

			blocked_informes: false,

		};
	}


	componentDidMount(){

		this.setVarsCurrentSession();

	}


	setVarsCurrentSession = () => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { this.setVarsPermissSession(); });

  	}


  	setVarsPermissSession = () => {
    
	    let arr_permiss = [];
	    arr_permiss = JSON.parse(localStorage.getItem('permissValid'));
	    if( arr_permiss.includes("1") == false ){
	      this.setState({ blocked_informes: true });
	    }
	      
	}


	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}


		const styles = {

			styleIcons: {
				fontSize: "30px",
				color: "#FFF",
			},

			styleActiveNav: {
				backgroundColor: "rgba(0,0,0,.2)",
				color: "#FFF",
				borderLeft: "solid 5px #ef8700",
				borderTopLeftRadius: '5px',
				borderBottomLeftRadius: '5px',

			}

		};


		var init_spinner = null;
		var render_panel = null;


		if( this.state.li_value === 1 ){

      		render_panel = <ViewCurrentUserProfile />;

      	}

		if( this.state.li_value === 6 ){

      		render_panel = <ViewCurrentUserOptions />;

      	}


      	if( this.state.li_value === 2 ){

      		render_panel = <ViewCurrentUserActivityPanel />;

      	}


      	if( this.state.li_value === 3 ){

      		render_panel = <ViewDailyPersonal />;

      	}


      	if( this.state.li_value === 4 ){

      		render_panel = <ViewAddUser />;

      	}


      	if( this.state.li_value === 5 ){

      		render_panel = <ViewPersonalSpace />;

      	}


      	if( this.state.li_value === 7 ){
      		render_panel = <ViewBorradoresDoc />
      	}

      	

		return(

			<div className="container-clientes-options">
				<div className="contenedor-de-cajas-clientes-options">
					
					<Col md={3} className="sidebar-4-clientes-options">
						<Col sm={12} className="sidebar-4-header-clientes-options">
							<h3 className="admin-title-style"> Administrador </h3>
							<FaUserCog style={ styles.styleIcons } />
							<hr />
						</Col>
						<Col sm={12}>
							<Nav className="sidebar-4-links-option-clientes-options" vertical>
								<NavItem style={ this.state.li_value == 1 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 1 }); } }>
						          <NavLink style={{ color: this.state.li_value == 1 ? "#FFF" : "#98cde6" }} href="#">Mi cuenta</NavLink>
						        </NavItem>
        						<NavItem style={ this.state.li_value == 6 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 6 }); } }>
						          <NavLink style={{ color: this.state.li_value == 6 ? "#FFF" : "#98cde6" }} href="#">Opciones de usuario</NavLink>
						        </NavItem>

						        { 

							        this.state.blocked_informes

							        ?

							        <NavItem style={ this.state.li_value == 2 ? styles.styleActiveNav : null } >
							          <NavLink style={{ color: this.state.li_value == 2 ? "#FFF" : "#98cde6" }} href="#">No disponible</NavLink>
							        </NavItem>

							        :

							        <NavItem style={ this.state.li_value == 2 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 2 }); } }>
							          <NavLink style={{ color: this.state.li_value == 2 ? "#FFF" : "#98cde6" }} href="#">Panel de actividad</NavLink>
							        </NavItem>

						        }
						        
						        <NavItem style={ this.state.li_value == 3 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 3 }); } }>
						          <NavLink style={{ color: this.state.li_value == 3 ? "#FFF" : "#98cde6" }} href="#">Mi agenda</NavLink>
						        </NavItem>
						        {
						        	this.state.blocked_informes 

						        	?

						        	<NavItem style={ this.state.li_value == 4 ? styles.styleActiveNav : null } >
							          <NavLink style={{ color: this.state.li_value == 4 ? "#FFF" : "#98cde6" }} href="#">No disponible</NavLink>
							        </NavItem>

						        	:

						        	<NavItem style={ this.state.li_value == 4 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 4 }); } }>
							          <NavLink style={{ color: this.state.li_value == 4 ? "#FFF" : "#98cde6" }} href="#">Añadir Usuario</NavLink>
							        </NavItem>

						        }
						        
						        <NavItem style={ this.state.li_value == 5 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 5 }); } }>
						          <NavLink style={{ color: this.state.li_value == 5 ? "#FFF" : "#98cde6" }} href="#">Espacio personal</NavLink>
						        </NavItem>
						        <NavItem style={ this.state.li_value == 7 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 7 }); } }>
						          <NavLink style={{ color: this.state.li_value == 7 ? "#FFF" : "#98cde6" }} href="#">Borradores</NavLink>
						        </NavItem>
						        <br />
						        <br />
						        <div style={{ width: '100%', height: '0.6px', 
						        				backgroundColor: '#FFF' }} />
						        <br />
						        <br />
						        <NavItem style={{ }} >
						          <h6 style={{ fontFamily: 'Crimson Text', fontStyle: 'italic', color: '#FFF' }}>Desarrollado por</h6>
						          <h5 style={{ fontFamily: 'Crimson Text', fontStyle: 'italic', color: '#FFF' }}>ideaTech Studio ©</h5>
						          <h6 style={{ fontFamily: 'Crimson Text', fontStyle: 'italic', color: '#FFF', fontSize: '0.7rem' }}>Licencia: ****-****-****-XZSD16</h6>
						          <h6 style={{ fontFamily: 'Crimson Text', fontStyle: 'italic', color: '#FFF', fontSize: '0.7rem' }}>Prohibida la distribución libre de este producto</h6>
						        </NavItem>
						    </Nav>
						</Col>
					</Col>

        			<Col md={9} className="panel-8-clientes-options">

						{ init_spinner }
        				{ render_panel }

        			</Col>

				</div>
			</div>

		);
	
	}


}



class ViewCurrentUserProfile extends React.Component{

	constructor(props){
		
		super(props);
		this.state= {

			isLoadingArrayUsuario: true,
			dataSourceArrayUsuario: [],

			email: '',
			numero_telefonico: '',

		};

	}

	componentDidMount(){
		//
		var url = globalVars.url;

    	this.setVarsCurrentSession(url);

	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { 
      		this.get_current_user_data(url);
      });

  	}

  	get_current_user_data = (url) => {

  		return fetch( url + '/get-current-user-data.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken+'&cu_u='+this.state.currentUser, {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {
		          		// console.log( responseJson )
		          		if( responseJson != 'No Results.'){

		          			if( responseJson == 'Token Error.' ){
			          			localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
	    						localStorage.removeItem('permissValid');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          		}else{

			          			this.setState({
						            isLoadingArrayUsuario: false,
						            dataSourceArrayUsuario: responseJson
						        }, function() {
						            // In this block you can do something with new state.
						            // console.log( this.state.dataSourceArrayUsuario );
						            this.setState({ 
						            	id_usuario: this.state.dataSourceArrayUsuario[0].id_usuario_info,
						            	email: this.state.dataSourceArrayUsuario[0].correo_usuario,
						            	numero_telefonico: this.state.dataSourceArrayUsuario[0].cel_contacto

						            });

						        });

			          		}
		          			
		          		}else{
		          			
		          			this.setState({ error_capture_user: true });

		          		}
			          
			       }).catch((error) => {
		          		// console.error(error);
		           	  });

  	}


  	UpdateUserData = () => {

		let url = globalVars.url;
		let obj = {};

		obj.id_usuario = this.state.id_usuario;
		obj.usuarioNick = this.state.currentUser;
		obj.numero_telefonico = this.state.numero_telefonico;
		obj.email = this.state.email;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch(url+'/update-user-data-api.php', {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Updated.'){

			            // alert('Se registro exitosamente el Cliente');
			            this.setState({ 
			            				id_usuario: '',
										numero_telefonico: '',
										email: '',
										alert_updated_client: true
			            			});
			            this.get_current_user_data(globalVars.url);

			          }
			          else{

			          		if( responseJson == 'Token error.' ){

				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
	    						localStorage.removeItem('permissValid');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;
			          			
				          	}else{

					          	this.setState({ alert_error_client_creation: true });
					          	setTimeout(() => {
				  
								  this.setState({ alert_error_client_creation: false });

								}, 3500);
								
								window.scrollTo(0, 120);
							}

			          }
			          
			      }).catch((error) => {
			        console.log(error);
			      });

		

	}



	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}


		const styles = {

			titleClient: {
				color: "rgb(234,83,63)",
				fontFamily: 'Crimson Text',
				fontSize: '30px',
				fontWeight: '700',
				margin: "25px auto 0",
			},
			containerCardsInfo: {
				
			},

			separatorTitle: {
				marginTop: '8px',
				boxShadow: "0 0 9px rgba(0, 0, 0, 0.3)",
			},

			styleIcons:{
				fontSize: "30px",
				color: "#FFF",
			},

		};


		// var space_text = " ";
		var render_alert_error_client_creation = null;	


		if( this.state.isLoadingArrayUsuario ){

			return(
				<div>
					<Spinner type="grow" style={{ marginTop: '35px' }} color="info" />
				</div>
			);

		}

		if( this.state.error_capture_user ){
			//
			return(
				<div>
					<Alert color="danger" style={{ margin: '0 auto', width: '80%' }}> Sin acciones por mostrar </Alert>
				</div>
			);

		}

		if( this.state.alert_error_client_creation ){

			render_alert_error_client_creation = <Alert style={{ margin: 10 }} color="warning">No se actualizaron los datos.</Alert>;

		}

		if( this.state.alert_updated_client ){

			render_alert_error_client_creation = <Alert style={{ margin: 10 }} color="success">Se actualizaron los datos.</Alert>;

		}

		return(
			
			<div className="panel-just-selected-clientes-view">


				<h3 style={ styles.titleClient } > Perfil del usuario </h3>
				<hr style={ styles.separatorTitle } />

				<div className="container-resume-caso" >

					<div style={{ width: '100%', display: 'flex' }}>
						<h4 style={{ margin: "10px 0px", textAlign: "left", width: '90%' }}>
							{ this.state.dataSourceArrayUsuario[0].username } 
						</h4>
					</div>
		      		
		      		<p style={{ justifyContent: "center", textAlign: "justify", marginTop: "10px", lineHeight: "3.0" }}>
		      			Nombre: { this.state.dataSourceArrayUsuario[0].primer_nombre } { this.state.dataSourceArrayUsuario[0].segundo_nombre } { this.state.dataSourceArrayUsuario[0].primer_apellido } { this.state.dataSourceArrayUsuario[0].segundo_apellido }
		      			<br />
		      			Función: { this.state.dataSourceArrayUsuario[0].funcion }
		      		</p>

		      		<Form className="form-add-client form-panel-user-edit-info">
		      			<div className="title-div-cliente-form">
					        <Label className="title-add-cliente-form">Editar información</Label>
					    </div>
					      
					    <div className="logo-div-add-cliente-form">
					    	<FaUserEdit style={ styles.styleIcons } />
					    </div>

					    <br />

					    <FormGroup>

					      	<Row md={2}>
						      	<Col sm={6}>
						      		<Input
							          type="text"
							          name="numero_telefonico"
							          id="numero_telefonico"
							          placeholder="Telefono"
							          pattern="[0-9]{1,8}"
        							  title="El número de telefono solo contiene 8 números"
							          value={ this.state.numero_telefonico }
							          onChange={ (text) => { this.setState({ numero_telefonico: text.target.value }); } }
							        />
						      	</Col>	

						      	<Col sm={6}>
						      		<Input
							          type="text"
							          name="email"
							          id="email"
							          placeholder="Email"
							          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
							          title="Cuenta de correo debe ser como correo@dominio.com"
							          value={ this.state.email }
							          onChange={ (text) => { this.setState({ email: text.target.value }); } }
							        />
						      	</Col>	
						    </Row>
					        
					    </FormGroup>

				      	<FormGroup>
				        	<hr />
				      	</FormGroup>

				      	<FormGroup>
					      	<Button className="button_addcliente" onClick={ this.UpdateUserData }>Actualizar datos</Button>

					      	{ render_alert_error_client_creation }

					    </FormGroup>
		      		</Form>

		      	</div>


			</div>

		);
	}

}


class ViewCurrentUserOptions extends React.Component{


	constructor(props){
		super(props);
		this.state = {

			opcion: 0,
			blocked_informes: false,

			contrasena_anterior: '',
			contrasena_nueva: '',
			contrasena_confirmar: '',

			isLoadingUsuarios: true,
			dataSourceUsuarios: [],
			usuario_elejido: '',


		};
	}


	componentDidMount(){
		//
		var url = globalVars.url;

    	this.setVarsCurrentSession(url);
    	this.setVarsPermissSession();

	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { 
      		this.getUsersAll();
      });

  	}

  	setVarsPermissSession = () => {
    
	    let arr_permiss = [];
	    arr_permiss = JSON.parse(localStorage.getItem('permissValid'));
	    if( arr_permiss.includes("1") == false ){
	      this.setState({ blocked_informes: true });
	    }
	      
	}

  	UpdateUserPasskey = () => {

  		let url = globalVars.url;
		let obj = {};

		if( this.state.contrasena_nueva == '' || this.state.contrasena_anterior == '' || this.state.contrasena_confirmar == '' ){

			this.setState({ alert_updated_pass: 4 });
			setTimeout(() => {
				this.setState({ alert_updated_pass: 0 });
			}, 5500);
			return null;

		}

		if( this.state.contrasena_nueva == this.state.contrasena_confirmar && this.state.contrasena_nueva != this.state.contrasena_anterior ){

			obj.usuarioNick = this.state.currentUser;
			obj.clave_anterior = this.state.contrasena_anterior;
			obj.clave_nueva = this.state.contrasena_nueva;
			obj.confirme_clave = this.state.contrasena_confirmar;
			obj.token = this.state.currentToken;
			obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

			fetch(url+'/update-user-passkey-api.php', {
				           headers:{  
				               "Accept": "application/json",
				               "Content-Type": "application/json"
				           },
				           method:'POST',
				           body: JSON.stringify({
				              obj,
				           }),

				    }).then( response => response.json() )
				      .then( responseJson => {

				      	  // console.log( responseJson );
				      	  
				          if(responseJson == 'Updated.'){

				            // alert('Se registro exitosamente el Cliente');
				            this.setState({ 
				            				contrasena_anterior: '',
											contrasena_nueva: '',
											contrasena_confirmar: '',
											alert_updated_pass: 1
				            			});

				            setTimeout(() => {
					  			
					  			localStorage.removeItem('permissValid');
								localStorage.removeItem('currentUser');
			    				localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});

							}, 3500);


				          }
				          else{

				          		if( responseJson == 'Clave incorrecta.' ){

				          			this.setState({ alert_updated_pass: 5 });
									setTimeout(() => {
										this.setState({ alert_updated_pass: 0 });
									}, 2500);
									setTimeout(() => {
										localStorage.removeItem('permissValid');
										localStorage.removeItem('currentUser');
			    						localStorage.removeItem('currentToken');
					          			this.setState({
					          				currentUser: null,
					          				currentToken: null
					          			});
				          			}, 2600);
									return null;

				          		}

				          		if( responseJson == 'Token error.' ){

				          			localStorage.removeItem('permissValid');
					          		localStorage.removeItem('currentUser');
		    						localStorage.removeItem('currentToken');
				          			this.setState({
				          				currentUser: null,
				          				currentToken: null
				          			});
				          			return null;
				          			
					          	}else{

						          	this.setState({ alert_updated_pass: 2 });
						          	setTimeout(() => {
					  
									  this.setState({ alert_updated_pass: 0 });

									}, 3500);
									
									window.scrollTo(0, 120);
								}

				          }
				          
				      }).catch((error) => {
				        // console.log(error);
				      });
		}else{

			
			this.setState({ alert_updated_pass: 3 });
			setTimeout(() => {
				this.setState({ alert_updated_pass: 0 });
			}, 5500);

		}


  	}


  	getUsersAll = () => {

  		var url = globalVars.url;
		fetch( url + '/get-users-all-with-info-api.php',{
					   headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST', 
			           body: JSON.stringify({
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {
						  
						  // console.log( responseJson );
				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{

				          		this.setState({ 
 											isLoading: false,
											dataSourceUsuarios: responseJson 

										  }, () => {  });

				          	}											           	
				            
				          }
				          
				   }).catch((error) => {
				        // console.log(error);
				     });
  	}



  	assignedPermisses = () => {
  		

  		if( this.state.usuario_elejido == '' ){

  			this.setState({ error_usuario_elejido : true });
  			return 0;

  		}

  		var url = globalVars.url;
		let obj = {};

		obj.usuarioId = this.state.usuario_elejido;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch( url + '/set-users-permiss-api.php',{
					   headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST', 
			           body: JSON.stringify({
				              					obj: obj,
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {
						  console.log( responseJson );
				          if(responseJson != 'Unassigned.'){

				          	if( responseJson == 'Token Error.' ){
				          		
				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{
							
								console.log( responseJson );
								this.setState({ 
												complete_associate: true,
												usuario_elejido: ''
											  }, ()=> {});

							}
				            
				          }
				          
				   }).catch((error) => {
				        // console.log(error);
				     });

  	}



	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}

		const styles = {

			styleIcons:{
				fontSize: "30px",
				color: "#FFF",
			},

			separatorTitle: {
				marginTop: '8px',
				boxShadow: "0 0 9px rgba(0, 0, 0, 0.3)",
			}

		};

		var render_alert_error_update_pass = null;
		var render_options_usuarios = null;
		var render_message_ok = null;
		var render_message_error = null;

		if( this.state.alert_updated_pass == 1 ){

			render_alert_error_update_pass = <Alert style={{ marginTop: "10px" }} color="success">Se cambió su contraseña, se requiere reiniciar sesión.</Alert>;
			//
		}
		if( this.state.alert_updated_pass == 2 ){

			render_alert_error_update_pass = <Alert style={{ marginTop: "10px" }} color="danger">Error en el cambio de contraseña.</Alert>;
			//
		}

		if( this.state.alert_updated_pass == 3 ){

			render_alert_error_update_pass = <Alert style={{ marginTop: "10px" }} color="warning">La contraseña nueva debe coincidir al confirmarse y debe ser diferente a la anterior.</Alert>;
			//
		}

		if( this.state.alert_updated_pass == 4 ){

			render_alert_error_update_pass = <Alert style={{ marginTop: "10px" }} color="warning">Todos los campos son requeridos.</Alert>;
			//
		}

		if( this.state.alert_updated_pass == 5 ){

			render_alert_error_update_pass = <Alert style={{ marginTop: "10px" }} color="warning">La clave anterior brindada es incorrecta.</Alert>;
			//
		}



		if( this.state.dataSourceUsuarios.length > 0 ){

			render_options_usuarios = this.state.dataSourceUsuarios.map( (item, index) => 

				<option key={index} value={ item.id_usuario } > { item.username } </option>

			);

		}

		if( this.state.complete_associate ){

			render_message_ok = <Alert style={{ marginTop: "10px" }} color="success">Se cambió la lista de permisos.</Alert>;

		}


		if( this.state.error_usuario_elejido ){

			render_message_error = <Alert style={{ marginTop: "10px" }} color="warning">No elijío ningun usuario.</Alert>;

		}



		return(

			<div className="panel-8">
				<div className="title-div-panel">
		        	<h1 className="title-panel">Opciones</h1>
		        	<hr />
				</div>

				<div className="render-in-panel">

					  { render_alert_error_update_pass }

				      <Alert 	className="options-user" 
				      			onClick={ () => { (this.state.opcion == 1) ? this.setState({ opcion: 0 }) : this.setState({ opcion: 1 }); } }
				      			>
				      				Cambiar contraseña 
				      				{ 
				      					this.state.opcion == 1

				      					?

				      					<FaMinus className="options-user-icon" />

				      					:

				      					<FaPlus className="options-user-icon" />
				      				}
				      				
				      </Alert>	

				      {

				      	this.state.opcion == 1

				      	?

				      	<Form className="form-base">
								      
					      <div className="title-form">
					        <Label className="">Editando clave</Label>
					      </div>
					      
					      <div className="icon-form">
					        <FaUserEdit style={ styles.styleIcons } />
					      </div>

					      <br />
					      <br />

					      <Col>
			                <FormGroup>
			                  <Label for="passwordApp">Contraseña anterior</Label>
			                  <Input
			                    className="input-login"
			                    type="password"
			                    name="contrasena_anterior"
			                    id="contrasena_anterior"
			                    placeholder="Clave anterior"
			                    value={this.state.contrasena_anterior}
			                    onChange={(text) => { this.setState({ contrasena_anterior: text.target.value }); }}
			                  />
			                </FormGroup>
			              </Col>

			              <Col>
			                <FormGroup>
			                  <Label for="passwordApp">Nueva Contraseña</Label>
			                  <Input
			                    className="input-login"
			                    type="password"
			                    name="contrasena_nueva"
			                    id="contrasena_nueva"
			                    placeholder="Clave nueva"
			                    value={this.state.contrasena_nueva}
			                    onChange={(text) => { this.setState({ contrasena_nueva: text.target.value }); }}
			                  />
			                </FormGroup>
			              </Col>

			              <Col>
			                <FormGroup>
			                  <Label for="passwordApp">Confirmar Contraseña</Label>
			                  <Input
			                    className="input-login"
			                    type="password"
			                    name="contrasena_confirmar"
			                    id="contrasena_confirmar"
			                    placeholder="Confirme clave"
			                    value={this.state.contrasena_confirmar}
			                    onChange={(text) => { this.setState({ contrasena_confirmar: text.target.value }); }}
			                  />
			                </FormGroup>
			              </Col>

			              <FormGroup>
					      	<Button className="button_addcliente" onClick={ this.UpdateUserPasskey }>Actualizar</Button>

					      	{ render_alert_error_update_pass }

					      </FormGroup>

					    </Form>

				      	:

				      	null

				      }	  


				      {

				      this.state.isLoading

				      ?

				      null

				      :

				      <Alert 	className="options-user" 
				      			onClick={ () => { (this.state.opcion == 2) ? this.setState({ opcion: 0 }) : this.setState({ opcion: 2 }); } }
				      			>
				      				Actualizar permisos de usuario
				      				{ 
				      					this.state.opcion == 2

				      					?

				      					<FaMinus className="options-user-icon" />

				      					:

				      					<FaPlus className="options-user-icon" />
				      				}
				      				
				      </Alert>  

				      }



				      {
				      	this.state.opcion == 2 && !this.state.blocked_informes

				      	?

				      	<Form className="form-base">
								      
					      <div className="title-form">
					        <Label className="">Cambiar permisos de usuario</Label>
					      </div>
					      
					      <div className="icon-form">
					        <FaUserEdit style={ styles.styleIcons } />
					      </div>

					      <br />
					      <br />
					      <br />
					      <br />

			              <Col>
			                <FormGroup>
			                  <Label for="passwordApp">Seleccione usuario</Label>
			                  <Input
			                    className="input-login"
			                    type="select"
			                    name="usuario_elejido"
			                    id="usuario_elejido"
			                    placeholder="Usuario"
			                    value={this.state.usuario_elejido}
			                    onChange={(text) => { this.setState({ usuario_elejido: text.target.value }); }}
			                  >
			                  <option value="">Elija usuario</option>
			                  { render_options_usuarios }
			                  </Input>
			                </FormGroup>
			              </Col>


			              <FormGroup>
					      	<Button className="button_addcliente" onClick={ this.assignedPermisses }>Asignar control total</Button>

					      	{ render_message_ok }
					      	{ render_message_error }

					      </FormGroup>

					    </Form>

				      	:

				      	null

				      } 		
				 
				</div>
			</div>

		);

	}

}


class ViewCurrentUserActivityPanel extends React.Component{

	constructor(props){
		super(props);
		this.state = {

			isLoading: true,
			dataSourceData: [],
			arrayDataGrapics: [],

		};
	}

	componentDidMount(){
		//
		var url = globalVars.url;

    	this.setVarsCurrentSession(url);

	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { 
      		this.get_current_data_db(url);
      });

  	}


	get_current_data_db = (url) => {

		return fetch( url + '/get-current-user-activity.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

		          		if( responseJson != 'No Results.' ){

		          			if( responseJson == 'Token Error.' ){

		          				localStorage.removeItem('permissValid');
		          				localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});

		          			}else{

						          this.setState({
						          	isLoading: false,
						            dataSourceData: responseJson,

						          }, function() {
						            // In this block you can do something with new state.
						            // console.log( responseJson );
						            var longit = this.state.dataSourceData.length;
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 1] );
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 2] );
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 3] );
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 4] );
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 5] );
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 6] );
						            this.state.arrayDataGrapics.push( this.state.dataSourceData[longit - 7] );

						            // console.log( this.state.arrayDataGrapics );

						            var labels_tmp = [];
							        this.state.arrayDataGrapics.map((item, key) => {
							        	labels_tmp.push( item.fecha );
							        });
							        // console.log( labels_tmp );

							        var data_arr_tmp = [];
							        this.state.arrayDataGrapics.map((item, key) => {
							        	data_arr_tmp.push( item.conteo );
							        });

							        this.setState({
							        	chartOperacionesPorDia: {
							        		labels: labels_tmp,
							        		datasets: [{
							        			label: 'Acciones por día',
							        			data: data_arr_tmp,
							        			backgroundColor: 'rgba(174, 182, 191, 0.2)',
							        			borderColor: 'rgba(174, 182, 191, 1.0)',
							        			borderWidth: 2
							        		}],
							        		
							        	}
							        }, () => {  /* console.log(this.state.chartAccionesPorMes); */  });
						            
						          });

						    }

						}

			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}



	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}

		var render_table_data = null;

		if( this.state.isLoading ){

			return(
				<div>
					<Spinner type="grow" style={{ marginTop: '35px' }} color="info" />
				</div>
			);

		}


		if( this.state.dataSourceData.length ){

			render_table_data = this.state.dataSourceData.map((item, index) => 
				<tr key={index}> <th scope="row">{ item.tipo }</th> <td>{ item.conteo }</td> <td>{ item.fecha }</td>  </tr>
			);
		}



		return(
			<div className="panel-8">
				<div className="title-div-panel">
		        	<h1 className="title-panel">Operaciones en el sistema y BDD</h1>
		        	<hr />
				</div>

				<div className="render-in-panel">
					<Row >
						<Col sm={8}>
							<div className="adminpanel tabla-activity-panel">
								<Table hover>
							      <thead>
							        <tr>
							          <th>Tipo</th>
							          <th># Operaciones</th>
							          <th>Día</th>
							        </tr>
							      </thead>
							      <tbody>
							      	{ render_table_data }
							      </tbody>
							    </Table>
						    </div>
					    </Col>
					    <Col sm={4}>
					    	<div className="chart-container">
								<Line 
									options={{
										responsive: true,
										maintainAspectRatio: false,
										title: {
											display: true,
											text: 'Operaciones últimos 7 días',
											fontSize: 25
										},
										legend:{
											display: false,
											position: 'right'
										}, 
									}}
									data={ this.state.chartOperacionesPorDia } 
								/>
							</div>
					    </Col>
				    </Row>

				</div>
			</div>
		);

	}

}


class ViewDailyPersonal extends React.Component{

	constructor(props){
		super(props);
		this.state = {

			isLoading: true,
			dateVar: '',

		};

	}

	componentDidMount(){
		//
		var url = globalVars.url;

		this.setVarsCurrentSession(url);
	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { 
      		this.get_id_current_user(url);
      });

  	}

  	get_id_current_user = (url) => {

  		let obj = {};

		obj.userCurrent = this.state.currentUser;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log( obj );

		fetch( url + '/get-current-user-id-api.php',{
					   headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST', 
			           body: JSON.stringify({
				              					obj: obj,
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				  		  // console.log( responseJson );

				          if(responseJson != 'No Return.'){

				          	if( responseJson == 'Token Error.' ){
				          		// console.log(1);
				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;
			          			
				          	}else{
								
								this.setState({ id_usuario: responseJson[0].id_usuario });
								this.captionDailyUser(responseJson[0].id_usuario, this.state.dateVar, globalVars.url);

							}

				          }
				          else{

				          	this.setState({

				          		alert_busqueda: true
				          	
				          	});
				          }
				          
				   }).catch((error) => {
				        console.log(error);
				     });

  	}

	get_actual_date(){

		var fecha = new Date();
		var anio = fecha.getFullYear();
		var mes = ( fecha.getMonth() + 1 ) < 10 ? ( "0" + ( fecha.getMonth() + 1 ) ) : ( fecha.getMonth() + 1 );
		var dia = fecha.getDate() < 10 ? ( "0" + fecha.getDate() ) : fecha.getDate();

		fecha = anio + '-' + mes + '-' + dia;
		
		return fecha;

	}

	captionDailyUser = (value, dateF, url) => {

		let obj = {};

		var actual_date = (dateF == '') ? this.get_actual_date() : dateF;

		obj.idCaso = '';
		obj.idUsuario = value;
		obj.fecha = actual_date;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log( obj );

		fetch( url + '/get-cases-events-users-by-filters-api.php',{
					   headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST', 
			           body: JSON.stringify({
				              					obj: obj,
				       		}),
				}).then( response => response.json() )
				  .then( responseJson => {

				  		  // console.log( responseJson );

				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;
			          			
				          	}else{
							
								this.setState({ 
	 											
	 											isLoading: false,
												dataSourceAgendas: responseJson,
												alert_busqueda: false

											  }, () => {  });
							}

				          }
				          else{

				          	this.setState({

				          		alert_busqueda: true
				          	
				          	});
				          	setTimeout( () => {
				          		this.setState({ isLoading: false });
				          	}, 3500);
				          }
				          
				   }).catch((error) => {
				        // console.log(error);
				     });

	}

	onUpdateStateEvent = ( id_estado_evento, estado_txt ) => {

		var url = globalVars.url;
		let obj = {};

		obj.id_estado_evento = id_estado_evento;
		obj.estado = estado_txt;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch(url+'/update-events-api.php', {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Updated.'){

			            // alert('Se registro exitosamente el Cliente');
			            this.setState({ 
										alert_updated_estado_eve: true
			            			});
			            setTimeout(() => {
		  
						  this.setState({ alert_updated_estado_eve: false });

						}, 3500);

						this.captionDailyUser(this.state.id_usuario, this.state.dateVar, globalVars.url);


			          }
			          else{

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;

			          	}else{

			          		this.setState({ alert_error_updating: true });
				          	setTimeout(() => {
			  
							  this.setState({ alert_error_updating: false });

							}, 3500);
							
							window.scrollTo(0, 120);

			          	}

			          }
			          
			      }).catch((error) => {
			        console.log(error);
			      });

	}

	changeDateVar = (varValue) => {

		this.setState({ dateVar: varValue }, () => { this.captionDailyUser(this.state.id_usuario, this.state.dateVar, globalVars.url); });

	}

	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}


		var render_panel = null;


		if( this.state.isLoading ){

			return(
				<div>
					<Spinner type="grow" style={{ marginTop: '35px' }} color="info" />
				</div>
			);

		}

		if( this.state.alert_busqueda ){

			return(
				<div>
					<Alert style={{ margin: 10 }} color="danger">No se encontraron resultados</Alert>
					<Col sm={12} style={{ padding: '0 200px'}}>
						<Form>
							<FormGroup row>
						        <Label for="dateVar" sm={3}>Fecha: </Label>
						        <Col sm={9}>
						          <Input 
						           		name="dateVar" 
						           		id="dateVar" 
						           		type="date" 
						           		onChange={ (textS) => { this.changeDateVar( textS.target.value ) } }
						           		/>
						        </Col>
						    </FormGroup>
						</Form>
					</Col>
				</div>
			);

		}

		if( this.state.alert_updated_estado_eve ){

			return(
				<div>
			 		<Alert sm={12} style={{ width: '100%', margin: 10 }} color="success">Se actualizo el evento correctamente</Alert>
				</div>
			);

		}

		if( this.state.alert_error_updating ){

			return(
				<div>
					<Alert sm={12} style={{ width: '100%', margin: 10 }} color="success">Error actualizando el estado</Alert>
				</div>
			);

		}

		if( this.state.dataSourceAgendas.length > 0 ){

			render_panel = this.state.dataSourceAgendas.map( (item, index) => 

					<Alert className="item-events" color="light" 
								style={{ 
											borderTop: item.estado == "LISTO" ? 
												'solid 10px #28a745' : 
												( item.estado == "PENDIENTE" ? 
													'solid 10px #ffc107' : 
													( item.estado == "ATRASADO" ? 'solid 10px #dc3545' : '' ) ),

										}}> 
						<h4 style={{ marginBottom: 0, marginTop: "20px" }}> { item.evento }, caso: { item.codigo_caso } </h4> 
						<hr style={{ marginTop: 0 }} />
						<p style={{ textAlign: 'left', marginLeft: '5%' }}> { item.descripcion } </p>
						<p style={{ textAlign: 'left', marginLeft: '5%' }}> { item.primer_nombre } { item.primer_apellido } </p>
						<p style={{ textAlign: 'left', marginLeft: '5%' }}> { item.fecha.slice(0, 10) } </p>
						<p style={{ textAlign: 'left', marginLeft: '5%', 
									borderRadius: '8px', width: '88px',
									padding: 5, border: 'solid 2px #17a2b8' }}> { item.hora.slice(11, 19) } </p>
						
						<ButtonGroup className="group-buttons">
					      	<Button 
					      		color={ item.estado == "PENDIENTE" ? "warning" : "info" } 
					      		onClick={ () => { this.onUpdateStateEvent( item.id_estado_evento, "PENDIENTE" ) } }
					      		>Pendiente</Button>
					      	<Button 
					      		color={ item.estado == "ASIGNADO" ? "primary" : "info" } 
					      		onClick={ () => { this.onUpdateStateEvent( item.id_estado_evento, "ASIGNADO" ) } }
					      		>Asignado</Button>
					      	<Button 
					      		color={ item.estado == "PROGRESO" ? "primary" : "info" } 
					      		onClick={ () => { this.onUpdateStateEvent( item.id_estado_evento, "PROGRESO" ) } }
					      		>En proceso</Button>
					      	<Button 
					      		color={ item.estado == "ATRASADO" ? "danger" : "info" } 
					      		onClick={ () => { this.onUpdateStateEvent( item.id_estado_evento, "ATRASADO" ) } }
					      		>Atrasado</Button>
					      	<Button 
					      		color={ item.estado == "ESPERA" ? "primary" : "info" } 
					      		onClick={ () => { this.onUpdateStateEvent( item.id_estado_evento, "ESPERA" ) } }
					      		>Por Comprobar</Button>
					      	<Button 
					      		color={ item.estado == "LISTO" ? "success" : "info" }
					      		onClick={ () => { this.onUpdateStateEvent( item.id_estado_evento, "LISTO" ) } }
					      		>Finalizado</Button>
						</ButtonGroup>
					</Alert>

			);
		}

		return(

			<div className="panel-8">
				<div className="title-div-panel">
			        	<h1 className="title-panel">Mi agenda diaria</h1>
			        	<hr />
			        	<Col sm={12} style={{ padding: '0 200px', marginBottom: '20px' }}>
							<Form>
								<FormGroup row>
							        <Label for="dateVar" sm={3}>Fecha: </Label>
							        <Col sm={9}>
							          <Input 
							           		name="dateVar" 
							           		id="dateVar" 
							           		type="date" 
							           		onChange={ (textS) => { this.changeDateVar( textS.target.value ) } }
							           		/>
							        </Col>
							    </FormGroup>
							</Form>
						</Col>

			      	</div>
				<div className="render-in-panel ">
					{ render_panel }
				</div>
			</div>

		);

	}
}


class ViewAddUser extends React.Component{

	constructor(props){
		super(props);
		this.state = {

			username: '',
			password: '',
			confirm_pass: '',
			primer_nombre: '',
			segundo_nombre: '',
			primer_apellido: '',
			segundo_apellido: '',
			funcion: '',
			cel_contacto: '',
			correo_usuario: '',

		};
	}

	componentDidMount(){
		//

		this.setVarsCurrentSession();	

	}

	setVarsCurrentSession = () => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => {  });

  	}

  	addUserToDB = () => {

  		let obj = {};
  		var url = globalVars.url;

  		if( this.state.primer_nombre != '' && this.state.primer_apellido != ''){
  			if( this.state.cel_contacto != '' && this.state.correo_usuario != '' ){

  				if( this.state.username != '' && this.state.username.length >= 8 && ( (/^([^\s]+[A-Za-z.@_0-9]{1,15})$/.test(this.state.username)) ) ){

  					if( this.state.password == this.state.confirm_pass && (this.state.password != '') && (this.state.password.length > 7) && ( (/^([^\s]+[A-Za-z.@_!#%*+0-9]{8,15})$/.test(this.state.password)) ) ){

  						if( this.state.username == this.state.password ){

  							this.setState({ error_inputs_pass: true });
	  						setTimeout( () => {
	  							this.setState({ error_inputs_pass: false });
	  						}, 3500);

  						}else{

		  						obj.primer_nombre = this.state.primer_nombre;
		  						obj.segundo_nombre = this.state.segundo_nombre;
		  						obj.primer_apellido = this.state.primer_apellido;
		  						obj.segundo_apellido = this.state.segundo_apellido;
		  						obj.username = this.state.username;
		  						obj.password = this.state.password;
		  						obj.funcion = this.state.funcion;
		  						obj.cel_contacto = this.state.cel_contacto;
		  						obj.correo_usuario = this.state.correo_usuario;
		  						obj.token = this.state.currentToken;
								obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
								console.log(obj);

		  						fetch(url+'/add-user-api.php', {
								           headers:{  
								               "Accept": "application/json",
								               "Content-Type": "application/json"
								           },
								           method:'POST',
								           body: JSON.stringify({
								              obj,
								           }),

								    }).then( response => response.json() )
								      .then( responseJson => {

								      	  console.log( responseJson );

								          if(responseJson != 'No Added.'){

								          	if( responseJson == 'Token Error.' ){

								          		localStorage.removeItem('permissValid');
								          		localStorage.removeItem('currentUser');
					    						localStorage.removeItem('currentToken');
							          			this.setState({
							          				currentUser: null,
							          				currentToken: null
							          			});
							          			return null;
							          			
								          	}else{

									            // alert('Se registro exitosamente el Caso');
									            this.setState({ alert_created: true });

								          	}

								           	
								          }
								          else{

								          	this.setState({ alert_error: true });

								          }
								          
								      }).catch((error) => {
								        console.log(error);
								      });

							this.setState({ alert_created: true });

						}


  					}else{
  						this.setState({ error_inputs_pass: true });
  						setTimeout( () => {
  							this.setState({ error_inputs_pass: false });
  						}, 2500);
  					}

  				}else{
  					this.setState({ error_inputs_username: true });
  					setTimeout( () => {
  						this.setState({ error_inputs_username: false });
  					}, 2500);
  				}

  			}else{
  				this.setState({ error_inputs: true });
  				setTimeout( () => {
  						this.setState({ error_inputs: false });
  					}, 2500);
  			}
  		}else{
  			this.setState({ error_inputs: true });
  			setTimeout( () => {
  						this.setState({ error_inputs: false });
  					}, 2500);
  		}

  	}

  	
	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}

		const styles = {

			titleClient: {
				color: "rgb(234,83,63)",
				fontFamily: 'Crimson Text',
				fontSize: '30px',
				fontWeight: '700',
				margin: "25px auto 0",
			},
			containerCardsInfo: {
				
			},

			styleIcons:{
				fontSize: "30px",
				color: "#FFF",
			},

			separatorTitle: {
				marginTop: '8px',
				boxShadow: "0 0 9px rgba(0, 0, 0, 0.3)",
			}

		};


		if( this.state.alert_created ){

			return(
				<div>
					<Alert color="success" style={{ margin: 10 }}>Se insertó el nuevo usuario al sistema.</Alert>
				</div>
			);
		
		}

		if( this.state.alert_error ){

			return(
				<div>
					<Alert color="danger" style={{ margin: 10 }}>Error agregando el nuevo usuario al sistema.</Alert>
				</div>
			);
		
		}

		if( this.state.error_inputs ){

			return(
				<div>
					<Alert color="warning" style={{ margin: 10 }}>Los campos deben completarse.</Alert>
				</div>
			);
		
		}

		if( this.state.error_inputs_username ){

			return(
				<div>
					<Alert color="warning" style={{ margin: 10 }}>El campo nombre de usuario no cumple los requisitos mínimos.</Alert>
				</div>
			);
		
		}

		if( this.state.error_inputs_pass ){

			return(
				<div>
					<Alert color="warning" style={{ margin: 10 }}>El campo "clave" de usuario no cumple los requisitos mínimos.</Alert>
				</div>
			);
		
		}


		return(

			<div className="panel-insert-client-view">

				<Form className="form-add-client">
								      
				      <div className="title-div-cliente-form">
				        <Label className="title-add-cliente-form">Agregar usuario</Label>
				      </div>
				      
				      <div className="logo-div-add-cliente-form">
				        <FaUserEdit style={ styles.styleIcons } />
				      </div>

				      <br />

				      <FormGroup>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="primer_nombre"
						          id="primer_nombre"
						          placeholder="Primer nombre"
						          value={ this.state.primer_nombre }
						          onChange={ (text) => { this.setState({ primer_nombre: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="segundo_nombre"
						          id="segundo_nombre"
						          placeholder="Segundo nombre"
						          value={ this.state.segundo_nombre }
						          onChange={ (text) => { this.setState({ segundo_nombre: text.target.value }); } }
						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="primer_apellido"
						          id="primer_apellido"
						          placeholder="Primer apellido"
						          value={ this.state.primer_apellido }
						          onChange={ (text) => { this.setState({ primer_apellido: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="segundo_apellido"
						          id="segundo_apellido"
						          placeholder="Segundo apellido"
						          value={ this.state.segundo_apellido }
						          onChange={ (text) => { this.setState({ segundo_apellido: text.target.value }); } }
						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>
				      
				      <FormGroup>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="username"
						          id="username"
						          placeholder="Nombre de usuario único"
						          title="El nombre de usuario debe ir sin espacios y puede contener hasta 15 caracteres"
						          value={ this.state.username }
						          onChange={ (text) => { this.setState({ username: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="number"
						          name="cel_contacto"
						          id="cel_contacto"
						          placeholder="cel_contacto"
						          value={ this.state.cel_contacto }
						          onChange={ (text) => { this.setState({ cel_contacto: text.target.value }); } }
						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="email"
						          id="email"
						          placeholder="Email"
						          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
						          title="Cuenta de correo debe ser como correo@dominio.com"
						          value={ this.state.correo_usuario }
						          onChange={ (text) => { this.setState({ correo_usuario: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="funcion"
						          id="funcion"
						          placeholder="Función desempeñada"
						          value={ this.state.funcion }
						          onChange={ (text) => { this.setState({ funcion: text.target.value }); } }
						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>
				        <hr />
				      </FormGroup>

				      <FormGroup>

				      	<Row md={1}>
					      	<Col sm={12}>
					      		<Input
						          type="password"
						          name="password"
						          id="password"
						          placeholder="Su clave de acceso"
						          title="La clave debe ir sin espacios y puede contener hasta 15 caracteres"
						          value={ this.state.password }
						          onChange={ (text) => { this.setState({ password: text.target.value }); } }
						        />
					      	</Col>		
					    </Row>
				        
				      </FormGroup>
				      
				      <FormGroup>

				      	<Row md={1}>
					      	<Col sm={12}>
					      		<Input
						          type="password"
						          name="confirm_pass"
						          id="confirm_pass"
						          placeholder="Confirmación de clave"
						          title="La clave debe ir sin espacios y puede contener hasta 15 caracteres"
						          value={ this.state.confirm_pass }
						          onChange={ (text) => { this.setState({ confirm_pass: text.target.value }); } }
						        />
					      	</Col>		
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>
				      	<Button className="button_addcliente" onClick={ this.addUserToDB }>Agregar usuario</Button>
				      </FormGroup>

			    </Form>


			</div>
		
		);

	}
}


class ViewPersonalSpace extends React.Component{

	constructor(props){
		super(props);
		this.state = {

			isLoading: true,
			dataSourceData: [],

			titulo_nota: '',
			texto_nota: '',

		};
	}

	componentDidMount(){
		//

    	this.setVarsCurrentSession();

	}

	setVarsCurrentSession = () => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { 
      		this.get_user_sticky_notes();
      });

  	}


  	get_user_sticky_notes = () => {

  		let obj = {};
	    var url = globalVars.url;

	    obj.currentUser = this.state.currentUser;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch(url+'/get-user-sticky-notes-api.php', {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson != 'No Results.'){

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
							localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;
		          			
			          	}else{

			          		
				            // alert('Se registro exitosamente el Caso');
				            // console.log(responseJson);
				            this.setState({
				            	isLoading: false,
				            	alert_no_results: false,
				            	dataSourceData: responseJson
				            });

			          	}

			           	
			          }
			          else{

			          	this.setState({ alert_no_results: true });
			          	setTimeout( () => {
			          		this.setState({ isLoading: false });
			          	}, 2000);

			          }
			          
			      }).catch((error) => {
			        // console.log(error);
			      });

		

  	}


  	plusSticky = () => {

  		let obj = {};
	    var url = globalVars.url;

	    if( this.state.titulo_nota != '' && this.state.texto_nota != ''){

	    	obj.currentUser = this.state.currentUser;
		    obj.titulo = this.state.titulo_nota;
		    obj.texto_nota = this.state.texto_nota;
			obj.token = this.state.currentToken;
			obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

			fetch(url+'/set-user-sticky-notes-api.php', {
				           headers:{  
				               "Accept": "application/json",
				               "Content-Type": "application/json"
				           },
				           method:'POST',
				           body: JSON.stringify({
				              obj,
				           }),

				    }).then( response => response.json() )
				      .then( responseJson => {

				      	  // console.log( responseJson );

				          if(responseJson != 'No Added.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
								localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;
			          			
				          	}else{

					            // alert('Se registro exitosamente el Caso');
					            this.setState({ alert_created: true, titulo_nota: '', texto_nota: '' });
					            this.get_user_sticky_notes();
					            setTimeout( () => {
					            	this.setState({ alert_created: false });
					            }, 3500);

				          	}

				           	
				          }
				          else{

				          	this.setState({ alert_error: true });
				          	setTimeout( () => {
				          		this.setState({ alert_error: false });
				          	}, 3500);

				          }
				          
				      }).catch((error) => {
				        // console.log(error);
				      });

	    }else{

	    	this.setState({ alert_incomplete_inputs: true });
	    	setTimeout( () => {
	    		this.setState({ alert_incomplete_inputs: false });
	    	}, 3500);

	    }

	    

  	}


  	minusSticky = ( id_sticky_nota, id_usuario_nota ) => {

  		let obj = {};
	    var url = globalVars.url;


    	obj.id_sticky_nota = id_sticky_nota;
	    obj.id_usuario_nota = id_usuario_nota;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch(url+'/delete-user-sticky-notes-api.php', {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson != 'No deleted.'){

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
							localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;
		          			
			          	}else{

				            // alert('Se registro exitosamente el Caso');
				            this.setState({ alert_deleted: true, dataSourceData: [] });
				            this.get_user_sticky_notes();
				            setTimeout( () => {
				            	this.setState({ alert_deleted: false });
				            }, 3500);

			          	}

			           	
			          }
			          else{

			          	this.setState({ alert_error: true });
			          	setTimeout( () => {
			          		this.setState({ alert_error: false });
			          	}, 3500);

			          }
			          
			      }).catch((error) => {
			        // console.log(error);
			      });


  	}



	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}

		const styles = {

			titleClient: {
				color: "rgb(234,83,63)",
				fontFamily: 'Crimson Text',
				fontSize: '30px',
				fontWeight: '700',
				margin: "25px auto 0",
			},

			separatorTitle: {
				marginTop: '8px',
				boxShadow: "0 0 9px rgba(0, 0, 0, 0.3)",
			},

			styleIcons:{
				fontSize: "30px",
				color: "#FFF",
			},

		};

		var render_stickys = null;
		var render_messages_no_results = null;
		var render_alert_created = null;
		var render_error_alert = null;
		var render_aviso_inputs = null;
		var render_alert_deleted = null;
		


		if( this.state.isLoading ){

			return (
				<div style={{ marginTop: "15px" }}>
					<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="success" />
				</div>
			);

		}


		if( this.state.alert_no_results ){

			render_messages_no_results = (<div className="sticky-note"><p>Aún no se añaden recordatorios.</p></div>);

		}		


		if( this.state.dataSourceData.length > 0 ){

			render_stickys = this.state.dataSourceData.map((item, index) => 

				<div className="sticky-note">
						
						<div className="title">
							<h3>{item.titulo}</h3>
						</div>	

						<div className="contenido">
							<p>{item.resumen}</p>
						</div>

						<span 
							className="boton-accion-sticky-delete" 
							onClick={ () => this.minusSticky(item.id_sticky_nota , item.id_usuario_nota) } 
							>
								<FaMinus style={{ color: '#FFF' }} />
						</span>

				</div>

			);
		}else{
			render_stickys = null;
		}

		if( this.state.alert_created ){

			render_alert_created = <Alert style={{ marginTop: '10px' }} color="success">Nota agregada.</Alert>;

		}

		if( this.state.alert_error ){

			render_error_alert = <Alert style={{ marginTop: '10px' }} color="danger">Ocurrió un error.</Alert>;

		}

		if( this.state.alert_deleted ){

			render_alert_deleted = <Alert style={{ marginTop: '10px' }} color="success">Se eliminó correctamente la nota.</Alert>;

		}

		if( this.state.alert_incomplete_inputs ){

			render_aviso_inputs = <Alert style={{ marginTop: '10px' }} color="warning">Campos de nota incompletos.</Alert>;

		}
		
		return(

			<div className="panel-just-selected-clientes-view">


				<h3 style={ styles.titleClient } > Notas Personales </h3>
				<hr style={ styles.separatorTitle } />
				{ render_alert_created }
				{ render_error_alert }
				{ render_aviso_inputs }
				{ render_alert_deleted }


				<div className="container-sticky-notes" >
					<div className="sticky-note">
						
						<div className="title">
							<Input
						          type="text"
						          name="titulo_nota"
						          id="titulo_nota"
						          placeholder="Recordatorio"
						          value={ this.state.titulo_nota }
						          onChange={ (text) => { this.setState({ titulo_nota: text.target.value }); } }
						    />
						</div>	

						<div style={{ height: '75%' }}>
							<Input
						          type="textarea"
						          name="texto_nota"
						          id="texto_nota"
						          placeholder="Notas del recordatorio"
						          value={ this.state.texto_nota }
						          onChange={ (text) => { this.setState({ texto_nota: text.target.value }); } }
						        />
						</div>

						<span 
							className="boton-accion-sticky-ok"
							onClick={ this.plusSticky }
							>
								<FaPlus style={{ color: '#FFF' }} />
						</span>

					</div>
					{ render_messages_no_results }
					
					
					{ render_stickys }
				</div>

			</div>

		);

	}
}



class ViewBorradoresDoc extends React.Component{

	constructor(props){
		super(props);
		this.state = {

			isLoadingDocs: true,
			arr_all_docs: [],
			docsCase: [],

			textSearch: '',

			ctrlUploadDoc: false,

		};
	}

	componentDidMount(){

		//
		this.setVarsCurrentSession();

	}


	setVarsCurrentSession = () => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { 
      		this.get_documents_borradores();
      });

  	}


  	get_documents_borradores = () => {

	    var url = globalVars.url;


		fetch( url + '/get-documents-borradores-api.php', {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
				              					token: this.state.currentToken,
												key: '90732020f187b3124eda60d942ad2020cada2efdxyzcba'
				       		}),

		    	}).then( response => response.json() )
				  .then( responseJson => {

				  		// console.log(responseJson);

				          if(responseJson != 'No Results.'){

				          	if( responseJson == 'Token Error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;

				          	}else{


					          	// responseJson.map( (item) =>{

				            // 		console.log( item );

				            // 	});

								this.setState({
												isLoadingDocs: false,
												docsCase: responseJson,
												arr_all_docs: responseJson,
												no_results_docs: false,
											  });

				        	}


				          }
				          else{

				          	this.setState({ no_results_docs: true, isLoadingDocs: false, 
				          					docsCase: [], arr_all_docs: [] });

				          }

				   }).catch((error) => {
				        // console.log(error);
				     });

  	}

  	onFileChange = event => {

      // Update the state
      this.setState({ new_doc: event.target.files[0] });
      // console.log(event.target.files[0]);

  	};

  	uploadDoc = () => {

		var url = globalVars.url;

		var data = new FormData();
		data.append('codeCase', 'BORRADOR');
		data.append('nameDoc', this.state.name_doc);
		data.append('fileU', this.state.new_doc);
		data.append('token', this.state.currentToken);
		data.append('key', '90732020f187b3124eda60d942ad2020cada2efdxyzcba');

		this.setState({ alert_status_upload_doc: 4 });

		if( this.state.new_doc == '' || this.state.new_doc == null || this.state.name_doc == '' ){

			this.setState({ alert_status_upload_doc: 3 });
	        setTimeout( () => {
	        	this.setState({ alert_status_upload_doc: 0 });
	        }, 3500);

		}else{

			fetch(url+'/upload-borrador-file-api.php', {
			           mode: 'no-cors',
			           method:'POST',
			           body: data

			    }).then( res => {

			    	// console.log(res);
		    		this.setState({ alert_status_upload_doc: 1 });
			        setTimeout( () => {
			        	this.setState({ alert_status_upload_doc: 0 });
			        }, 3500);
			        this.get_documents_borradores();

				      if (res.ok) {

				        console.log("Perfect! ");
				        this.setState({ alert_status_upload_doc: 1 });
				        setTimeout( () => {
				        	this.setState({ alert_status_upload_doc: 0 });
				        }, 3500);

				      } else if (res.status == 401) {

				        // console.log("Oops! ");
				        this.setState({ alert_status_upload_doc: 2 });
				        setTimeout( () => {
				        	this.setState({ alert_status_upload_doc: 0 });
				        }, 3500);

				      }
			    }, function (e) {
			      	// console.log("Error submitting form!", e);
			    }).catch((error) => {
			        // console.log(error);
			    });

		}


	}

	minusDocument = (idDocumento) => {

		var url = globalVars.url;
		let obj = {};

		obj.idDocumento = idDocumento;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		fetch(url+'/delete-doc-caso-api.php', {
			           headers:{
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Deleted.'){

			            // alert('Se registro exitosamente el estado');
			            this.setState({
										alert_deleting_doc: true
			            			});
			            setTimeout( () => { this.setState({ alert_deleting_doc: false }) }, 2000 );
			            this.get_documents_borradores();

			          }
			          else{

			          	if( responseJson == 'Token Error.' ){

			          		localStorage.removeItem('permissValid');
			          		localStorage.removeItem('currentUser');
    						localStorage.removeItem('currentToken');
		          			this.setState({
		          				currentUser: null,
		          				currentToken: null
		          			});
		          			return null;

			          	}else{

			          		this.setState({ alert_error_deleting_doc: true });
			          		setTimeout( () => { this.setState({ alert_error_deleting_doc: false }) }, 2000 );

			          	}

			          }

			      }).catch((error) => {
			        console.log(error);
			      });

	}

	SearchDoc = (text) => {

		// console.log(this.state.dataSourceArrayEvents);
		var textSearch = text;

		const newData = this.state.arr_all_docs.filter( function(item){
		         const itemData = item.estatus.toUpperCase();
		         const textData = textSearch.toUpperCase();
		         return itemData.indexOf(textData) > -1;
		     });

		     this.setState({
		         docsCase: newData,
		         textSearch: textSearch
		     }, () => { /* console.log( this.state.docsCase ) */ });

	}

  	render(){

  		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}

		var render_alert_status_upload_doc = null;
		var render_docs_case = null;
		var render_no_results_docs = null;
		var render_charging_docs = null;

		if( this.state.alert_status_upload_doc == 1 ){

			render_alert_status_upload_doc = <Alert style={{ marginTop: '2px' }} color="success">Se agregó el documento!</Alert>;

		}

		if( this.state.alert_status_upload_doc == 2 ){

			render_alert_status_upload_doc = <Alert style={{ marginTop: '2px' }} color="danger">Error al subir el documento!</Alert>;
			//
		}

		if( this.state.no_results_docs ){

			render_no_results_docs = <p style={{ marginTop: '12px' }} >Sin documentos archivados!</p>;
			//

		}

		if( this.state.alert_status_upload_doc == 3 ){

			render_alert_status_upload_doc = <Alert style={{ marginTop: '2px' }} color="warning">Seleccione un documento y asigne un nombre!</Alert>;
			//

		}


		if( this.state.isLoadingDocs ){

			render_charging_docs = <Spinner style={{ width: '3rem', height: '3rem', margin: '15px auto' }} type="grow" color="primary" />;

		}

		if( this.state.docsCase.length > 0 ){

			render_docs_case = this.state.docsCase.map((item, index) =>

				<span style={{ margin: 'auto 20px', position: 'relative' }}>
					<a href={item.url_documento} target="_blank" rel="noopener noreferrer" className="ellipsisStyle">
						<FaFileExport />
						{item.estatus}
					</a>
					<span 
							className="boton-accion-sticky-delete"
							style={{ width: '15px', height: '15px', right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
							onClick={ () => this.minusDocument(item.id_documento) } 
							>
								<FaMinus style={{ color: '#FFF' }} size={9} />
					</span>
				</span>

			);

		} else{
			render_docs_case = null;
		}


  		return(

  			<div style={{ width: '100%', height: '100%', padding: '15px', overflowY: 'scroll' }}>

  				{ render_charging_docs }
  				
  				<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<div className="resume-caso-ctrl-view" style={{  }} 
							onClick={ () => { this.setState({ ctrlViewDoc : !this.state.ctrlViewDoc }) } }>
						<h5>Formatos de documentos</h5>
						<span>{ this.state.ctrlViewDoc ? '-' : '+' }</span>
					</div>
				</div>
				<div style={{ width: '100%', display: this.state.ctrlViewDoc && this.state.arr_all_docs.length > 0 ? 'block' : 'none'  }}>
					<Form style={{ width: '100%'}}>
						<FormGroup>
					        <Label style={{ color: 'rgba(89, 91, 101)' }} for="buscar_cliente">Buscar documento</Label>
					        <Row sm={2} className="input-search-div-container">
						        <Col sm={10}>
							        <Input
							          type="search"
							          name="buscar_documento"
							          id="buscar_documento"
							          placeholder="Buscar un documento"
							          value={ this.state.textSearch }
							          onChange={ (textS) => { this.SearchDoc( textS.target.value ) } }
							        />
						        </Col>
						        <Col sm={2}>
						        	<FaSearch />
						        </Col>
						    </Row>
				      	</FormGroup>
			      	</Form>
				</div>
				<div style={{ display: this.state.ctrlViewDoc ? 'block' : 'none' }}>
					{ 
	      				this.state.alert_deleting_doc

	      				?

	      				<Alert style={{ margin: '50px 25px', width: '100%' }} color="success">Documento eliminado.</Alert>

	      				:

	      				null
	      			}
	      			{ 
	      				this.state.alert_error_deleting_doc

	      				?

	      				<Alert style={{ margin: '50px 25px', width: '100%' }} color="warning">Documento no eliminado.</Alert>

	      				:

	      				null
	      			}
				</div>
				<div className="docs-resume-case" style={{ display: this.state.ctrlViewDoc ? 'grid' : 'none' }}>
	      			{ render_docs_case }
	      			{ render_no_results_docs }
	      		</div>

  				<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<div className="resume-caso-ctrl-view" style={{  }} 
							onClick={ () => { this.setState({ ctrlUploadDoc : !this.state.ctrlUploadDoc }) } }>
						<h5>Añadir documento</h5>
						<span>{ this.state.ctrlUploadDoc ? '-' : '+' }</span>
					</div>
				</div>
  				<div className="add-docs-box" style={{ display: this.state.ctrlUploadDoc ? 'block' : 'none'}} >
	      			{ render_alert_status_upload_doc }
	      			<Form className="form-add-docs">
	      				<FormGroup className="order-adddocs-box">
					        <Label for="exampleFile">Adjuntar nuevo documento</Label>
					        <Input type="file" name="new_doc" id="new_doc" onChange={this.onFileChange}/>
					        <FormText color="muted">
					          Puede cargar con este botón un documento para anexarlo al archivo de borradores.
					        </FormText>
					    </FormGroup>
					    <FormGroup className="order-adddocs-box">
					        <Label for="exampleFile">Nombre del Archivo:</Label>
					        <Input type="text" name="name_doc" id="name_doc" onChange={ (text) => this.setState({name_doc: text.target.value}) }/>
					    </FormGroup>
					    <FormGroup>

					    	{
						    	this.state.alert_status_upload_doc == 4

						    	?


						    	<Spinner color="primary" />

						    	:

						    	<Button onClick={ () => this.uploadDoc() }>Archivar Documento</Button>
					    	}

					    </FormGroup>
	      			</Form>
	      		</div>

  			</div>

  		);

  	}


}