import React from 'react';
import { 
			Form, 
			FormGroup, 
			FormText,
			ButtonGroup,
			Label, 
			Input,  
			Button, 
			Alert, 
			Card, 
			CardTitle, 
			CardText,
			Col,
			Row,
			Nav, 
			NavItem, 
			NavLink,
			Table,
			Spinner,
			UncontrolledAlert,
			Modal,
			ModalHeader, ModalBody, ModalFooter
		} from 'reactstrap';
import './Styles.css';
import { FaUserTie, FaSearch, FaUserPlus, FaUserEdit } from 'react-icons/fa';

import globalVars from './constants';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Redirect} from 'react-router-dom';



class Clientes extends React.Component{


	constructor(props){
		super(props);
		this.state = {
			
			li_value: 1,

			redirect: false,

			textSearch: '',

			isLoadingClientes: true,
			dataSourceClientes: [],

			array_clientes: [],

			item_index_arr: null,

			display_init_spinner: true,

		};
	}


	componentDidMount() {
		
    	var url = globalVars.url;		

    	this.setVarsCurrentSession(url);	
	
	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { 
      		this.get_cases_by_clients(url);
      });

  	}


	get_cases_by_clients = (url) => {

		return fetch( url + '/get-clientes-all-api.php?entry=90732020f187b3124eda60d942ad2020cada2efdxyzcba&to_k='+this.state.currentToken, {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'GET'

		    	}).then((response) => response.json())
		          .then((responseJson) => {

		          		// console.log(responseJson);
		          		if( responseJson != 'No Results.'){

		          			if( responseJson == 'Token error.' ){

		          				localStorage.removeItem('permissValid');
		          				localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});

		          			}else{

		          				this.setState({
						            isLoadingClientes: false,
						            dataSourceClientes: responseJson,
						            array_clientes: responseJson,
						        }, function() {
						            // In this block you can do something with new state.
						            // console.log( this.state.dataSourceClientes );
						            
						        });

		          			}

		          		}

			          
			       }).catch((error) => {
		          		console.error(error);
		           	  });

	}

	
	SearchFilterClientes = (textSearch) => {

		     const newData = this.state.array_clientes.filter( function(item){
		         const itemData = item.filter_text.toUpperCase();
		         const textData = textSearch.toUpperCase();
		         return itemData.indexOf(textData) > -1;
		     });

		     this.setState({
		         dataSourceClientes: newData,
		         textSearch: textSearch
		     }, () => { /*console.log( this.props.dataSourceClientes )*/ });

	}

	goToViewClient = (index) => {

		this.setState({ li_value: 3, item_index_arr: index });

	}

	goToViewClientUpdate = (index) => {

		this.setState({ li_value: 4, item_index_arr: index });

	}


	changeLiValue = (value) => {

		var url = globalVars.url;
		this.setState({ li_value: 1 });
		this.get_cases_by_clients(url);

	}


	render(){

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}

		var render_panel = null;

		if (this.state.redirect) {
         	return <Redirect to={{ pathname: '/menu', state: { idCaso: this.state.id_caso_insertado } }} />;
      	}

      	if( this.state.li_value === 1 ){
      		//
      		render_panel = <Viewclientes isLoadingClientes={ this.state.isLoadingClientes } dataSourceClientes={ this.state.dataSourceClientes } textSearch={ this.state.textSearch } onDataSourceClientesChange={ this.SearchFilterClientes } goToViewClient={ this.goToViewClient } goToViewClientUpdate={ this.goToViewClientUpdate } />;

      	}


      	if( this.state.li_value === 2 ){

      		render_panel = <Addcliente />;
      	}


      	if( this.state.li_value === 3 ){

      		render_panel = <ViewSelectedCliente clienteActual={ this.state.dataSourceClientes[this.state.item_index_arr] } />;
      	}

      	if( this.state.li_value === 4 ){

      		render_panel = <UpdateInfoCliente clienteActual={ this.state.dataSourceClientes[this.state.item_index_arr] } />;

      	}


      	const styles = {

			styleIcons: {
				fontSize: "30px",
				color: "#FFF",
			},

			styleActiveNav: {
				backgroundColor: "rgba(0,0,0,.2)",
				color: "#FFF",
				borderLeft: "solid 5px #ef8700",
				borderTopLeftRadius: '5px',
				borderBottomLeftRadius: '5px',

			}

		};

		setTimeout(() => {
			  
			  this.setState({ display_init_spinner: false });

		}, 1000);

		var init_spinner = <Spinner style={{ display: this.state.display_init_spinner ? "" : "none", width: '3rem', height: '3rem', margin: "15px", }} type="grow" color="primary" />;


		return(
				
			<div className="container-clientes-options">
				<div className="contenedor-de-cajas-clientes-options">
					
					<Col md={4} className="sidebar-4-clientes-options">
						<Col sm={12} className="sidebar-4-header-clientes-options">
							<h3> Area de clientes </h3>
							<FaUserTie style={ styles.styleIcons } />
							<hr />
						</Col>
						<Col sm={12}>
							<Nav className="sidebar-4-links-option-clientes-options" vertical>
        						<NavItem style={ this.state.li_value == 1 ? styles.styleActiveNav : null } onClick={ () => { this.changeLiValue(1); } }>
						          <NavLink style={{ color: this.state.li_value == 1 ? "#FFF" : "#98cde6" }} href="#">Clientes</NavLink>
						        </NavItem>
						        <NavItem style={ this.state.li_value == 2 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 2 }); } }>
						          <NavLink style={{ color: this.state.li_value == 2 ? "#FFF" : "#98cde6" }} href="#">Añadir cliente</NavLink>
						        </NavItem>
						        <NavItem style={ this.state.li_value == 4 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 4 }); } }>
						          <NavLink style={{ display: this.state.li_value == 4 ? "" : "none", color: this.state.li_value == 4 ? "#FFF" : "#98cde6" }} href="#">Editar cliente</NavLink>
						        </NavItem>
						        <NavItem style={ this.state.li_value == 3 ? styles.styleActiveNav : null } onClick={ () => { this.setState({ li_value: 3 }); } }>
						          <NavLink style={{ display: this.state.li_value == 3 ? "" : "none", color: this.state.li_value == 3 ? "#FFF" : "#98cde6" }} href="#">{ this.state.item_index_arr != null ? "Cliente: " + this.state.dataSourceClientes[this.state.item_index_arr].codigo_cliente : "" }</NavLink>
						        </NavItem>
						    </Nav>
						</Col>
					</Col>

        			<Col md={8} className="panel-8-clientes-options">

						{ init_spinner }
        				{ render_panel }

        			</Col>

				</div>
			</div>


		);


	}

}


export default Clientes;






class Viewclientes extends React.Component{


	constructor(props){
		super(props);
		this.state = {
			
			cliente_actual: [],

			confirmDelete: false,
			identificación_cliente: '', 
			indexDelArray: ''

		};

	}

	componentDidMount(){

		//
		var url = globalVars.url;	
		this.setVarsCurrentSession(url);

	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => {  });

  	}

	handleInputChangeTextSearch = (textSearch) => {
    	
    	this.props.onDataSourceClientesChange( textSearch );
  	
  	}

  	sendIndex = (key) => {

  		this.props.goToViewClient( key );
  	}

  	sendIndexGoToUpdate = (key) => {

  		this.props.goToViewClientUpdate( key );

  	}

  	deleteCliente = (idCli, indexArr) => {

		let url = globalVars.url;
		let obj = {};

		obj.id_cliente = idCli;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log(obj);

		fetch(url+'/delete-clients-api.php', {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Deleted.'){

			            // alert('Se registro exitosamente el Cliente');
			            this.setState({ 
										alert_deleted_client: true,
										confirmDelete: false
			            			});
			            this.props.dataSourceClientes.splice(indexArr, 1);

			            setTimeout( () => {
			            	this.setState({ 
										alert_deleted_client: false
			            			});
			            }, 2000);

			          }
			          else{

			          	if( responseJson == 'Token error.' ){

			          			localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;
			          			
				          	}else{

					          	this.setState({ alert_error_client_deleted: true });
					          	setTimeout(() => {
				  
								  this.setState({ alert_error_client_deleted: false });

								}, 3500);
								
								window.scrollTo(0, 120);
							}

			          }
			          
			      }).catch((error) => {
			        console.log(error);
			      });

	}

	render_clientes_table(){

		if( this.props.dataSourceClientes.length > 0 ){

			// console.log( this.props.dataSourceClientes );
			var render_table = null;

			render_table = this.props.dataSourceClientes.map( (item, index) => 
						
						<tr key={index} > <th scope="row" onClick={ () => this.sendIndex( index ) }>{ item.codigo_cliente }</th> <td onClick={ () => this.sendIndex( index ) }>{ item.nombre_completo }</td> <td onClick={ () => this.sendIndex( index ) }>{ item.total_casos }</td> <td onClick={ () => this.sendIndex( index ) }>{ item.casos_activos }</td> <td style={{ position : 'relative' }}><Button onClick={ () => this.sendIndexGoToUpdate( index ) } color="info">Editar</Button> <span className="boton-accion-sticky-delete" style={{ width: '15px', height: '15px', right: '5px', top: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: 'center' }} onClick={ () => { this.confirmDelete( item.id_cliente, index ); }}>x</span></td> </tr>
			
			);
	

			return render_table;

		}

	}


	confirmDelete = (idCli, indexArr) => {

		this.setState({
			confirmDelete: true,
			identificación_cliente: idCli, 
			indexDelArray: indexArr
		}, () => { });

	}

 

	render(){
		
		// console.log(this.props.dataSourceClientes);
		var render_clientes_table = this.render_clientes_table();

		const styles = {

			searchTitle: {
				color: "rgba(89, 91, 101)",
				fontFamily: 'Crimson Text',
				fontSize: '25px',
				fontWeight: '600',
			},

			containerSerachIcon: {
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignContent: "center",
				alignItems: "center",
				float: "left",
			},

			searchIcone: {
				left: "5px",
				position: "absolute"
			},

			tableStyle: {
				maxHeight: "80%",
				height: "80%",
				overflowY: "scroll"
			}

		};


		if( this.state.isLoadingClientes ){

			return (
				<div style={{ marginTop: "15px" }}>
					<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="success" />
				</div>
			);

		}


		if( this.state.alert_deleted_client ){

			return (
				<div style={{ marginTop: "15px" }}>
					<UncontrolledAlert color="danger">
				    	Eliminando 1 cliente...
				    </UncontrolledAlert>
				</div>
			);

		}


		if( this.state.alert_error_client_deleted ){

			return (
				<div style={{ marginTop: "15px" }}>
					<UncontrolledAlert color="warning">
				      Error eliminando cliente.
				    </UncontrolledAlert>
				</div>
			);

		}


		return(

			<div className="panel-all-clientes-view">


				<Form className="form-panel-all-clientes-view">
					<FormGroup>
				        <Label style={ styles.searchTitle } for="buscar_cliente">Buscar cliente por nombre ó código</Label>
				        <Row sm={2} className="input-search-div-container">
					        <Col sm={10}>
						        <Input
						          type="search"
						          name="buscar_cliente"
						          id="buscar_cliente"
						          placeholder="Buscar un cliente"
						          value={ this.props.textSearch }
						          onChange={ (textS) => { this.handleInputChangeTextSearch( textS.target.value ) } }
						        />
					        </Col>
					        <Col style={ styles.containerSerachIcon } sm={2}>
					        	<FaSearch style={ styles.searchIcone } />
					        </Col>
					    </Row>
			      	</FormGroup>
		      	</Form>


		      	<div className="table-panel-all-clientes-view" style={ styles.tableStyle }>

		      		<Table dark>
				      <thead>
				        <tr>
				          <th>CÓDIGO</th>
				          <th>CLIENTE</th>
				          <th># CASOS</th>
				          <th>ACTIVOS</th>
				          <th>ACTUALIZAR</th>
				        </tr>
				      </thead>
				      <tbody>
				        
				      	{ render_clientes_table }
				        
				      </tbody>
				    </Table>

		      	</div>


		      	<Modal isOpen={ this.state.confirmDelete } >
			        
			        <ModalHeader >Seguro que desea eliminar?</ModalHeader>
			        <ModalFooter>
			          <Button color="danger" onClick={ () => this.deleteCliente(this.state.identificación_cliente, this.state.indexDelArray ) }>Eliminar</Button>
			          <Button color="secondary" onClick={ () => this.setState({ confirmDelete: false }) }>Cancelar</Button>
			        </ModalFooter>
			    
			    </Modal>


			</div>

		);
	
	}


}



class Addcliente extends React.Component{

	constructor(props){
		//
		super(props);
		this.state = {

			primer_nombre: '',
			segundo_nombre: '',
			primer_apellido: '',
			segundo_apellido: '',
			dui: '',
			nit: '',
			numero_telefonico: '',
			email: '',
			calidad: '',
			direccion: '',
			referencia_direccion: '',
			descripcion_adicional: '',
			nrc: '',
			representante_legal: '',
			razon_social: '',
			tipo: 0,
			dui_representante: '',
			nit_representante: '',
			telefono_representante: '',
			mail_representante: '',

			alert_created_client: false,
			alert_error_client_creation: false,		
			warning_incomplete: false,	

		};
	}

	componentDidMount(){
		//
		var url = globalVars.url;

    	this.setVarsCurrentSession(url);
	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { });

  	}


	AddCliente = () =>{

		let url = globalVars.url;
		let obj = {};
		// ...

	    obj.primer_nombre = this.state.primer_nombre;
		obj.segundo_nombre = this.state.segundo_nombre;
		obj.primer_apellido = this.state.primer_apellido;
		obj.segundo_apellido = this.state.segundo_apellido;
		obj.dui = this.state.dui;
		obj.nit = this.state.nit;
		obj.numero_telefonico = this.state.numero_telefonico;
		obj.email = this.state.email;
		obj.calidad = this.state.calidad;
		obj.direccion = this.state.direccion;
		obj.referencia_direccion = this.state.referencia_direccion;
		obj.descripcion_adicional = this.state.descripcion_adicional;
		obj.nrc = this.state.nrc;
		obj.razon_social = this.state.razon_social;
		obj.representante_legal = this.state.representante_legal;
		obj.tipo = this.state.tipo;
		obj.dui_representante = this.state.dui_representante;
		obj.nit_representante = this.state.nit_representante;
		obj.telefono_representante = this.state.telefono_representante;
		obj.mail_representante = this.state.mail_representante;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';

		if( ( this.state.tipo == 2 && this.state.primer_nombre != '' && this.state.primer_apellido != '' ) || ( this.state.tipo == 1 && this.state.razon_social != '' && this.state.representante_legal != '' ) ){

			fetch(url+'/set-clients-api.php', {
				           headers:{  
				               "Accept": "application/json",
				               "Content-Type": "application/json"
				           },
				           method:'POST',
				           body: JSON.stringify({
				              obj,
				           }),

				    }).then( response => response.json() )
				      .then( responseJson => {

				      	  // console.log( responseJson );

				          if(responseJson == 'Added.'){

				            // alert('Se registro exitosamente el Cliente');
				            this.setState({ 
				            				alert_created_client: true,
				            				primer_nombre: '',
											segundo_nombre: '',
											primer_apellido: '',
											segundo_apellido: '',
											dui: '',
											nit: '',
											numero_telefonico: '',
											email: '',
											calidad: '',
											direccion: '',
											referencia_direccion: '',
											descripcion_adicional: '',
											nrc: '',
											representante_legal: '',
											razon_social: '',
											tipo: 0
				            			});

				          }
				          else{

				          	if( responseJson == 'Token error.' ){

				          		localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;
			          			
				          	}else{

				          		this.setState({ alert_error_client_creation: true });
					          	setTimeout(() => {
				  
								  this.setState({ alert_error_client_creation: false });

								}, 3500);
								
								window.scrollTo(0, 120);

				          	}

				          }
				          
				      }).catch((error) => {
				        console.log(error);
				      });

		}
		else{

			this.setState({ warning_incomplete: true }, () => {});
			setTimeout(() => {
			  
			  this.setState({ warning_incomplete: false });

			}, 3500);
			
			window.scrollTo(0, 120);

		}

	}


	newClient = () => {

		this.setState({ alert_created_client: false, });

	}



	render(){

		// var render_created_client = null;
		var render_warning_fields = null;
		var render_alert_error_client_creation = null;

		const styles = {
			styleIcons:{
				fontSize: "30px",
				color: "#FFF",
			},
			buttonAnadirOtherClient: {
				marginTop: '15px',
				color: '#FFF',
				fontWeight: '650',
			}
		};


		if( this.state.alert_created_client ){

			return(

				<div className="panel-insert-client-view">
					<Alert color="success" style={{ marginTop: "30px", }} >
	        			<h4 className="alert-heading">Completado!</h4>
				        <p>
				          Se creó el cliente con éxito.
				          <br />
				          Puede crear un nuevo cliente, si lo desea!
				        </p>
				        <hr />
				        <p className="mb-0">
				          ¿Crear nuevo cliente?
				        </p>
				        <Button style={ styles.buttonAnadirOtherClient } onClick={ this.newClient } color="info" >Añadir cliente</Button>
				    </Alert>
				</div>

			);
		}


		if( this.state.warning_incomplete){

			
			render_warning_fields = <Alert color="warning" style={{ marginTop: "20px" }}>Hay campos sin completar!</Alert>;

		}


		if( this.state.alert_error_client_creation){

			render_alert_error_client_creation = <Alert color="danger">Ocurrio un error creando el cliente - Revisar!</Alert>;

		}


		if( this.state.tipo == 0 ){

			return(

			<div className="panel-insert-client-view">

				<Form className="form-add-client" style={{ background: 'linear-gradient(to bottom, rgba(89, 91, 101) 42%, #FFF 42%)' }}>
								      
				      <div className="title-div-cliente-form">
				        <Label className="title-add-cliente-form">Nuevo cliente</Label>
				      </div>
				      
				      <div className="logo-div-add-cliente-form">
				        <FaUserPlus style={ styles.styleIcons } />
				      </div>

				      <br />

				      <FormGroup>
				      	<ButtonGroup size="lg">
						  <Button onClick={ () => { this.setState({ tipo: 1 }); }}>P. JURÍDICA</Button>
						  <Button onClick={ () => { this.setState({ tipo: 2 }); }}>P. NATURAL</Button>
						</ButtonGroup>
				      </FormGroup>

				      <br />

				</Form>

			</div>);

		}


		return(

			<div className="panel-insert-client-view">

				{ render_alert_error_client_creation }

				<Form className="form-add-client">
								      
				      <div className="title-div-cliente-form">
				        <Label className="title-add-cliente-form">Nuevo cliente</Label>
				      </div>
				      
				      <div className="logo-div-add-cliente-form">
				        <FaUserPlus style={ styles.styleIcons } />
				      </div>

				      <br />

				      <FormGroup style={{ display: this.state.tipo == 1 ? 'none' : '' }}>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="primer_nombre"
						          id="primer_nombre"
						          placeholder="Primer nombre"
						          value={ this.state.primer_nombre }
						          onChange={ (text) => { this.setState({ primer_nombre: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="segundo_nombre"
						          id="segundo_nombre"
						          placeholder="Segundo nombre"
						          onChange={ (text) => { this.setState({ segundo_nombre: text.target.value }); } }
						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>

				      <FormGroup style={{ display: this.state.tipo == 1 ? 'none' : '' }}>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="primer_apellido"
						          id="primer_apellido"
						          placeholder="Primer apellido"
						          onChange={ (text) => { this.setState({ primer_apellido: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="segundo_apellido"
						          id="segundo_apellido"
						          placeholder="Segundo apellido"
						          onChange={ (text) => { this.setState({ segundo_apellido: text.target.value }); } }
						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>

				      <FormGroup style={{ display: this.state.tipo == 2 ? 'none' : '' }}>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="razon_social"
						          id="razon_social"
						          placeholder="Razón social"
						          onChange={ (text) => { this.setState({ razon_social: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6} >
					      		<Input
						          type="text"
						          name="nrc"
						          id="nrc"
						          placeholder="NRC"
						          onChange={ (text) => { this.setState({ nrc: text.target.value }); } }
						        />
					      	</Col>						      		
					    </Row>
				        
				      </FormGroup>
				      
				      
				      <FormGroup>

				      	<Row md={2}>
					      	<Col sm={6} style={{ display: this.state.tipo == 1 ? 'none' : '' }}>
					      		<Input
						          type="text"
						          name="dui"
						          id="dui"
						          placeholder="DUI"
						          pattern="[0-9]{9}"
						          title="El número de DUI se compone de 9 números sin letras ni caracteres especiales"
						          onChange={ (text) => { this.setState({ dui: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="nit"
						          id="nit"
						          placeholder="NIT"
						          pattern="[0-9]{14}"
						          title="El número de NIT se compone de 14 números sin letras ni caracteres especiales"
						          onChange={ (text) => { this.setState({ nit: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6} style={{ display: this.state.tipo == 2 ? 'none' : '' }}>
					      		<Input
						          type="text"
						          name="representante_legal"
						          id="representante_legal"
						          placeholder="Nombre representante legal"
						          onChange={ (text) => { this.setState({ representante_legal: text.target.value }); } }
						        />
					      	</Col>
					    </Row>
				        
				      </FormGroup>

				      <FormGroup style={{ display: this.state.tipo == 2 ? 'none' : '' }}>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="dui_representante"
						          id="dui_representante"
						          placeholder="DUI Representante"
						          onChange={ (text) => { this.setState({ dui_representante: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6} >
					      		<Input
						          type="text"
						          name="nit_representante"
						          id="nit_representante"
						          placeholder="NIT Representante"
						          onChange={ (text) => { this.setState({ nit_representante: text.target.value }); } }
						        />
					      	</Col>						      		
					    </Row>
				        
				      </FormGroup>

				      <FormGroup style={{ display: this.state.tipo == 2 ? 'none' : '' }}>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="telefono_representante"
						          id="telefono_representante"
						          placeholder="TELEFONO Representante"
						          onChange={ (text) => { this.setState({ telefono_representante: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6} >
					      		<Input
						          type="text"
						          name="mail_representante"
						          id="mail_representante"
						          placeholder="EMAIL Representante"
						          onChange={ (text) => { this.setState({ mail_representante: text.target.value }); } }
						        />
					      	</Col>						      		
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="numero_telefonico"
						          id="numero_telefonico"
						          placeholder="Telefono"
						          onChange={ (text) => { this.setState({ numero_telefonico: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="email"
						          id="email"
						          placeholder="Email"
						          onChange={ (text) => { this.setState({ email: text.target.value }); } }
						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>
				        <hr />
				      </FormGroup>

				      <FormGroup style={{ display: 'none' }}>

				      	<Row md={1}>
					      	<Col sm={12}>
					      		<Input
						          type="text"
						          name="calidad"
						          id="calidad"
						          placeholder="Calidad del cliente"
						          onChange={ (text) => { this.setState({ calidad: text.target.value }); } }
						        />
					      	</Col>		
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>

				      	<Row md={1}>
					      	<Col sm={12}>
					      		<Input
						          type="textarea"
						          name="direccion"
						          id="direccion"
						          placeholder="Domicilio"
						          onChange={ (text) => { this.setState({ direccion: text.target.value }); } }
						        />
					      	</Col>		
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>

				      	<Row md={1}>
					      	<Col sm={12}>
					      		<Input
						          type="textarea"
						          name="descripcion_adicional"
						          id="descripcion_adicional"
						          placeholder="Otros datos"
						          onChange={ (text) => { this.setState({ descripcion_adicional: text.target.value }); } }
						        />
					      	</Col>		
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>
				      	<Button className="button_addcliente" onClick={ this.AddCliente }>Añadir cliente</Button>

				      	{ render_alert_error_client_creation }

				      	{ render_warning_fields }
				      </FormGroup>

			    </Form>


			</div>

		);
	
	}


}




class ViewSelectedCliente extends React.Component{

	constructor(props){
		super(props);
		this.state = {

			dui_vista: '',
			nit_vista: '',	

			dui_re_vista: '',
			nit_re_vista: '',	

			new_doc: '',
			name_doc: '',	

			alert_status_upload_doc: 0	

		};
	}

	componentDidMount(){

		// console.log( this.props.clienteActual );
		this.setVarsCurrentSession();

	}

	setVarsCurrentSession = () => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { 
      				this.acoplateData();
      			});

  	}

	acoplateData(){

		var cadDui = this.props.clienteActual.DUI;	
		var dui_format = cadDui.slice(0, 8).concat( "-", cadDui.slice(8, 9) );

		var cadNit = this.props.clienteActual.NIT;	
		// console.log( cadNit );
		var nit_format = cadNit.slice(0, 4).concat( "-", cadNit.slice(4, 10).concat( "-", cadNit.slice(10, 13).concat( "-", cadNit.slice(13, 14) ) ) );
		// console.log( nit_format );

		var cadDuiRe = this.props.clienteActual.dui_representante;
		var dui_repre_format = '';
		if( cadDuiRe != '' && cadDuiRe != null ){
			dui_repre_format = cadDuiRe.slice(0, 8).concat( "-", cadDuiRe.slice(8, 9) );
		}
		

		var cadNitRe = this.props.clienteActual.nit_representante;
		var nit_repre_format = '';
		if( cadNitRe != null && cadNitRe != '' ){
			nit_repre_format = cadNitRe.slice(0, 4).concat( "-", cadNitRe.slice(4, 10).concat( "-", cadNitRe.slice(10, 13).concat( "-", cadNitRe.slice(13, 14) ) ) );
		}
		


		this.setState({ 
						dui_vista: dui_format,
						nit_vista: nit_format,
						dui_re_vista: dui_repre_format,
						nit_re_vista: nit_repre_format
					  }, () => {});

	}

	onFileChange = event => {

      // Update the state
      this.setState({ new_doc: event.target.files[0] });
      // console.log(event.target.files[0]);

  	};

  	uploadDoc = (idCliente) => {

		var url = globalVars.url;

		var data = new FormData()
		data.append('idCliente', idCliente);
		data.append('codeCliente', this.props.clienteActual.codigo_cliente);
		data.append('nameDoc', this.state.name_doc);
		data.append('fileU', this.state.new_doc);
		data.append('token', this.state.currentToken);
		data.append('key', '90732020f187b3124eda60d942ad2020cada2efdxyzcba');

		this.setState({ alert_status_upload_doc: 4 });

		if( this.state.new_doc == '' || this.state.new_doc == null || this.state.name_doc == '' || idCliente == '' ){

			this.setState({ alert_status_upload_doc: 3 });
	        setTimeout( () => {
	        	this.setState({ alert_status_upload_doc: 0 });
	        }, 3500);

		}else{

			fetch(url+'/upload-cliente-file-api.php', {
			           mode: 'no-cors',
			           method:'POST',
			           body: data

			    }).then( res => {

			    	// console.log(res);
		    		this.setState({ alert_status_upload_doc: 1 });
			        setTimeout( () => {
			        	this.setState({ alert_status_upload_doc: 0 });
			        }, 3500);

				      if (res.ok) {

				        console.log("Perfect! ");
				        this.setState({ alert_status_upload_doc: 1 });
				        setTimeout( () => {
				        	this.setState({ alert_status_upload_doc: 0 });
				        }, 3500);

				      } else if (res.status == 401) {

				        // console.log("Oops! ");
				        this.setState({ alert_status_upload_doc: 2 });
				        setTimeout( () => {
				        	this.setState({ alert_status_upload_doc: 0 });
				        }, 3500);

				      }
			    }, function (e) {
			      	// console.log("Error submitting form!", e);
			    }).catch((error) => {
			        // console.log(error);
			    });

		}


	}

	render(){

		// console.log( this.props.clienteActual );

		var render_alert_status_upload_doc = null;

		if( this.state.alert_status_upload_doc == 1 ){

			render_alert_status_upload_doc = <Alert style={{ marginTop: '2px' }} color="success">Se agregó el documento!</Alert>;

		}

		if( this.state.alert_status_upload_doc == 2 ){

			render_alert_status_upload_doc = <Alert style={{ marginTop: '2px' }} color="danger">Error al subir el documento!</Alert>;
			//
		}

		if( this.state.alert_status_upload_doc == 3 ){

			render_alert_status_upload_doc = <Alert style={{ marginTop: '2px' }} color="warning">Seleccione un documento y asigne un nombre!</Alert>;
			//

		}

		const styles = {

			titleClient: {
				color: "rgb(234,83,63)",
				fontFamily: 'Crimson Text',
				fontSize: '30px',
				fontWeight: '700',
				margin: "25px auto 0",
			},
			containerCardsInfo: {
				
			},

			separatorTitle: {
				marginTop: '8px',
				boxShadow: "0 0 9px rgba(0, 0, 0, 0.3)",
			}

		};


		return(

			<div className="panel-just-selected-clientes-view">


				<h3 style={ styles.titleClient } > Información de Cliente </h3>
				<hr style={ styles.separatorTitle } />

				<div className="containerCardsInfo" >

		      		<Card body className="text-left cards-info-client">
		        		<CardTitle>Codigo Cliente: { this.props.clienteActual.codigo_cliente }</CardTitle>
		        		<CardText>Nombre: { this.props.clienteActual.nombre_completo } </CardText>
		        		<CardText>{ (this.props.clienteActual.razon_social === null || this.props.clienteActual.razon_social === '') ? 'DUI:' : 'NRC:' } { (this.props.clienteActual.razon_social === null || this.props.clienteActual.razon_social === '') ? <a href={this.props.clienteActual.img_dui} target="_blank" rel="noopener noreferrer">{ this.state.dui_vista }</a> : <a href={ this.props.clienteActual.img_iva } target="_blank" rel="noreferrer noopener">{this.props.clienteActual.nrc}</a> }</CardText>
		        		<CardText>NIT: 
		        			{
		        				this.props.clienteActual.img_nit != null && this.props.clienteActual.img_nit != ''
		        				?
		        				<a href={this.props.clienteActual.img_nit} target="_blank" rel="noopener noreferrer"> { this.state.nit_vista } </a>
		        				:
		        				this.state.nit_vista
		        			}
		        		</CardText>
		        		<CardText>Telefono: { this.props.clienteActual.numero_telefonico } / { this.props.clienteActual.numero_telefonico } </CardText>
		        		<CardText>Email: { this.props.clienteActual.email } </CardText>

		      		</Card>

		      		<Card body className="text-left cards-info-client">
		        		<CardTitle style={{ textAlign: "center", fontSize: '20px', borderBottom: "solid 1px #ddd" }}> Resumen de Casos </CardTitle>
		        		<CardText className="statistics-card-client"> <h3 className="firts">Total: </h3> <h3 style={{ color: " #000 "}}>{ this.props.clienteActual.total_casos }</h3> </CardText>
		        		<CardText className="statistics-card-client"> <h3 className="firts">En proceso: </h3> <h3 style={{ color: " #52be80 "}}>{ this.props.clienteActual.casos_activos }</h3> </CardText>
		        		<CardText className="statistics-card-client"> <h3 className="firts">Cerrados:</h3> <h3 style={{ color: " #3498db "}}>{ this.props.clienteActual.casos_inactivos }</h3> </CardText>
		      		</Card>

		      	</div>

		      	<div className="containerCardsInfo">

		      		<Card body className="text-left cards-info-client-address" style={{ overflowY: 'scroll' }}>
		      			{
		        			(this.props.clienteActual.razon_social === null || this.props.clienteActual.razon_social === '')

		        			?

		        			// <CardText style={{ width: '100%', }} > { this.props.clienteActual.calidad } </CardText>
		        			null

		        			:

		        			<CardText style={{ width: '100%', }}>Representante Legal: { this.props.clienteActual.representante_legal } </CardText>

		        		}
		        		{
		        			(this.props.clienteActual.dui_representante === null || this.props.clienteActual.dui_representante === '')

		        			?

		        			null

		        			:

		        			<CardText style={{ width: '100%', }} > 
		        				DUI Representante:  
		        				{ 
		        					this.props.clienteActual.img_dui_repre != '' && this.props.clienteActual.img_dui_repre != null
		        					?
		        					<a href={this.props.clienteActual.img_dui_repre} target="_blank" rel="noopener noreferrer"> { this.state.dui_re_vista } </a>
		        					:
		        					this.state.dui_re_vista
		        				} 
		        			</CardText>

		        		}
		        		{
		        			(this.props.clienteActual.nit_representante === null || this.props.clienteActual.nit_representante === '')

		        			?

		        			null

		        			:

		        			<CardText style={{ width: '100%', }} > 
		        				NIT Representante: 
		        				{ 
		        					this.props.clienteActual.img_nit_repre != '' && this.props.clienteActual.img_nit_repre != null 
		        					?
		        					<a href={this.props.clienteActual.img_nit_repre} target="_blank" rel="noopener noreferrer"> { this.state.nit_re_vista } </a>
		        					:
		        					this.state.nit_re_vista 
		        				} 
		        			</CardText>

		        		}
		        		{
		        			(this.props.clienteActual.telefono_representante === null || this.props.clienteActual.telefono_representante === '')

		        			?

		        			null

		        			:

		        			<CardText style={{ width: '100%', }} > { this.props.clienteActual.telefono_representante } </CardText>

		        		}
		        		{
		        			(this.props.clienteActual.mail_representante === null || this.props.clienteActual.mail_representante === '')

		        			?

		        			null

		        			:

		        			<CardText style={{ width: '100%', }} > { this.props.clienteActual.mail_representante } </CardText>

		        		}
		        		<CardText style={{ width: '100%', }} > { this.props.clienteActual.direccion } </CardText>
		        		<CardText style={{ width: '100%', }} > { this.props.clienteActual.descripcion_adicional } </CardText>
		        		

		        		<br />

		        		<div className="add-docs-box">
			      			{ render_alert_status_upload_doc }
			      			<Form className="form-add-docs">
			      				<FormGroup className="order-adddocs-box">
							        <Label for="exampleFile">Adjuntar nuevo documento</Label>
							        <Input type="file" name="new_doc" id="new_doc" onChange={this.onFileChange}/>
							        <FormText color="muted">
							          Puede cargar con este botón un documento para anexarlo al archivo de este cliente.
							        </FormText>
							    </FormGroup>
							    <FormGroup className="order-adddocs-box">
							        <Label for="exampleFile">Tipo del Archivo:</Label>
							        <div style={{ display: 'flex', padding: '0 50px', justifyContent: 'center' }}>
							          {
									  	  (this.props.clienteActual.razon_social === null || this.props.clienteActual.razon_social === '')

									  	  ?

										  <span style={{ 
										  				backgroundColor: this.state.name_doc == 'img_dui' ? '#545b62' : '#6c757d',
										  				color: '#FFF', width: '25%', display: 'flex', justifyContent: 'center',
										  				borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px'
										  			  }} onClick={ () => this.setState({ name_doc: 'img_dui' }) }>DUI</span>

										  :
										  null
									  }
									  <span style={{ 
									  				backgroundColor: this.state.name_doc == 'img_nit' ? '#545b62' : '#6c757d',
									  				color: '#FFF', width: '25%', display: 'flex', justifyContent: 'center',
									  				borderTopLeftRadius: (this.props.clienteActual.razon_social === null || this.props.clienteActual.razon_social === '') ? '0' : '8px',
									  				borderBottomLeftRadius: (this.props.clienteActual.razon_social === null || this.props.clienteActual.razon_social === '') ? '0' : '8px',
									  				borderTopRightRadius: (this.props.clienteActual.razon_social === null || this.props.clienteActual.razon_social === '') ? '8px' : '0',
									  				borderBottomRightRadius: (this.props.clienteActual.razon_social === null || this.props.clienteActual.razon_social === '') ? '8px' : '0',
									  			  }} onClick={ () => this.setState({ name_doc: 'img_nit' }) }>NIT</span>
									  {
									  	  (this.props.clienteActual.razon_social === null || this.props.clienteActual.razon_social === '')

									  	  ?

									  	  null

									  	  :

										  <span style={{ 
										  				backgroundColor: this.state.name_doc == 'img_dui_repre' ? '#545b62' : '#6c757d',
										  			    color: '#FFF', width: '25%', display: 'flex', justifyContent: 'center'
										  			  }} onClick={ () => this.setState({ name_doc: 'img_dui_repre' }) }>DUI REPRE.</span>
									  }
									  {
									  	  (this.props.clienteActual.razon_social === null || this.props.clienteActual.razon_social === '')

									  	  ?

									  	  null

									  	  :

										  <span style={{ 
										  				backgroundColor: this.state.name_doc == 'img_iva' ? '#545b62' : '#6c757d',
										  			    color: '#FFF', width: '25%', display: 'flex', justifyContent: 'center'
										  			  }} onClick={ () => this.setState({ name_doc: 'img_iva' }) }>T. IVA</span>
									  }
									  {
									  	  (this.props.clienteActual.razon_social === null || this.props.clienteActual.razon_social === '')

									  	  ?

									  	  null

									  	  :

										  <span style={{ 
										  				backgroundColor: this.state.name_doc == 'img_nit_repre' ? '#545b62' : '#6c757d', 
										  				color: '#FFF', width: '25%', display: 'flex', justifyContent: 'center',
										  				borderBottomRightRadius: '8px', borderTopRightRadius: '8px'
										  			  }} onClick={ () => this.setState({ name_doc: 'img_nit_repre' }) }>NIT REPRE.</span>
									  }
									</div>
							    </FormGroup>
							    <FormGroup>

							    	{
								    	this.state.alert_status_upload_doc == 4

								    	?

								    	<Spinner color="primary" />

								    	:

								    	<span style={{ 
								    					backgroundColor: '#6c757d', color: '#FFF',
								    					display: 'flex', justifyContent: 'center',
								    					borderRadius: '10px', width: '210px', 
								    					margin: '0 auto' 	
								    				}} onClick={ () => this.uploadDoc(this.props.clienteActual.id_cliente) }>Archivar Documento</span>
							    	}

							    </FormGroup>
			      			</Form>
			      		</div>
		      		</Card>

		      	</div>


			</div>


		);
	
	}

}



class UpdateInfoCliente extends React.Component{

	constructor(props){
		
		super(props);
		this.state = {

			id_cliente: '',
			primer_nombre: '',
			segundo_nombre: '',
			primer_apellido: '',
			segundo_apellido: '',
			dui: '',
			nit: '',
			numero_telefonico: '',
			email: '',
			calidad: '',
			direccion: '',
			referencia_direccion: '',
			descripcion_adicional: '',
			nrc: '',
			representante_legal: '',
			razon_social: '',
			tipo: '',

			alert_updated_client: false,
			alert_error_client_creation: false,

			no_selected: false,

		};

	}

	componentDidMount(){

		// console.log( this.props.clienteActual );

		if( this.props.clienteActual ){

			this.setState({

				id_cliente: this.props.clienteActual.id_cliente,
				primer_nombre: this.props.clienteActual.primer_nombre,
				segundo_nombre: this.props.clienteActual.segundo_nombre,
				primer_apellido: this.props.clienteActual.primer_apellido,
				segundo_apellido: this.props.clienteActual.segundo_apellido,
				numero_telefonico: this.props.clienteActual.numero_telefonico,
				email: this.props.clienteActual.email,
				calidad: this.props.clienteActual.calidad,
				dui: this.props.clienteActual.DUI,
				nit: this.props.clienteActual.NIT,
				direccion: this.props.clienteActual.direccion,
				referencia_direccion: this.props.clienteActual.referencia_direccion,
				descripcion_adicional: this.props.clienteActual.descripcion_adicional,
				nrc: this.props.clienteActual.nrc,
				representante_legal: this.props.clienteActual.representante_legal,
				razon_social: this.props.clienteActual.razon_social,
				tipo: this.props.clienteActual.tipo_cliente,
				dui_representante: this.props.clienteActual.dui_representante,
				nit_representante: this.props.clienteActual.nit_representante,
				telefono_representante: this.props.clienteActual.telefono_representante,
				mail_representante: this.props.clienteActual.mail_representante

			}, ()=>{} );

			// this.acoplateData();

		}else{

			this.setState({ no_selected: true }, ()=>{ });

		}

		var url = globalVars.url;

    	this.setVarsCurrentSession(url);	

	
	}

	setVarsCurrentSession = (url) => {

      let usuario = JSON.parse(localStorage.getItem('currentUser'));
      let token = JSON.parse(localStorage.getItem('currentToken'));

      this.setState({
        currentUser: usuario,
        currentToken: token
      }, () => { });

  	}


	acoplateData(){

		var cadDui = this.props.clienteActual.DUI;	
		var dui_format = cadDui.slice(0, 8).concat( "-", cadDui.slice(8, 9) );

		var cadNit = this.props.clienteActual.NIT;	
		var nit_format = cadNit.slice(0, 4).concat( "-", cadNit.slice(4, 10).concat( "-", cadNit.slice(10, 13).concat( "-", cadNit.slice(13, 14) ) ) );


		this.setState({ 
						dui: dui_format,
						nit: nit_format,
					  }, () => {});

	}


	UpdateCliente = () => {

		let url = globalVars.url;
		let obj = {};

		obj.id_cliente = this.state.id_cliente;
		obj.primer_nombre = this.state.primer_nombre;
		obj.segundo_nombre = this.state.segundo_nombre;
		obj.primer_apellido = this.state.primer_apellido;
		obj.segundo_apellido = this.state.segundo_apellido;
		obj.dui = this.state.dui;
		obj.nit = this.state.nit;
		obj.numero_telefonico = this.state.numero_telefonico;
		obj.email = this.state.email;
		obj.calidad = this.state.calidad;
		obj.direccion = this.state.direccion;
		obj.referencia_direccion = this.state.referencia_direccion;
		obj.descripcion_adicional = this.state.descripcion_adicional;
		obj.nrc = this.state.nrc;
		obj.representante_legal = this.state.representante_legal;
		obj.razon_social = this.state.razon_social;
		obj.tipo = this.state.tipo;
		obj.dui_representante = this.state.dui_representante;
		obj.nit_representante = this.state.nit_representante;
		obj.telefono_representante = this.state.telefono_representante;
		obj.mail_representante = this.state.mail_representante;
		obj.token = this.state.currentToken;
		obj.key = '90732020f187b3124eda60d942ad2020cada2efdxyzcba';
		// console.log(obj);

		fetch(url+'/update-clients-api.php', {
			           headers:{  
			               "Accept": "application/json",
			               "Content-Type": "application/json"
			           },
			           method:'POST',
			           body: JSON.stringify({
			              obj,
			           }),

			    }).then( response => response.json() )
			      .then( responseJson => {

			      	  // console.log( responseJson );

			          if(responseJson == 'Updated.'){

			            // alert('Se registro exitosamente el Cliente');
			            this.setState({ 
			            				id_cliente: '',
			            				dui: '',
			            				nit: '',
										numero_telefonico: '',
										email: '',
										calidad: '',
										direccion: '',
										referencia_direccion: '',
										descripcion_adicional: '',
										nrc: '',
										representante_legal: '',
										razon_social: '',
										tipo: '',
										dui_representante: '',
										nit_representante: '',
										telefono_representante: '',
										mail_representante: '',
										alert_updated_client: true
			            			});

			          }
			          else{

			          	if( responseJson == 'Token error.' ){

			          			localStorage.removeItem('permissValid');
				          		localStorage.removeItem('currentUser');
	    						localStorage.removeItem('currentToken');
			          			this.setState({
			          				currentUser: null,
			          				currentToken: null
			          			});
			          			return null;
			          			
				          	}else{

					          	this.setState({ alert_error_client_creation: true });
					          	setTimeout(() => {
				  
								  this.setState({ alert_error_client_creation: false });

								}, 3500);
								
								window.scrollTo(0, 120);
							}

			          }
			          
			      }).catch((error) => {
			        console.log(error);
			      });

		

	}

	render(){

		// console.log( this.props.clienteActual );

		if( this.state.currentToken === null || this.state.currentToken === '' || this.state.currentToken === 'null' || this.state.currentToken === 'NULL' ){

			return <Redirect to={{ pathname: '/'}} />;
			//

		}
		

		var render_alert_error_client_creation = null;
		// var render_no_selected_cliente = null;


		const styles = {

			titleClient: {
				color: "rgb(234,83,63)",
				fontFamily: 'Crimson Text',
				fontSize: '30px',
				fontWeight: '700',
				margin: "25px auto 0",
			},
			containerCardsInfo: {
				
			},

			styleIcons:{
				fontSize: "30px",
				color: "#FFF",
			},

			separatorTitle: {
				marginTop: '8px',
				boxShadow: "0 0 9px rgba(0, 0, 0, 0.3)",
			}

		};


		if( this.state.alert_updated_client ){

			return(

				<Alert style={{ margin: 10 }} color="success">Registro Actualizado.</Alert>

			);

		}

		if( this.state.no_selected ){
			
			return(

				<Alert style={{ margin: 10 }} color="danger">No se cargo ningún cliente.</Alert>

			);

		}

		if( this.state.alert_error_client_creation ){

			render_alert_error_client_creation = <Alert style={{ margin: 10 }} color="warning">No se actualizo el cliente.</Alert>;

		}

		return(
			
			<div className="panel-insert-client-view">

				{ render_alert_error_client_creation }

				<Form className="form-add-client">
								      
				      <div className="title-div-cliente-form">
				        <Label className="title-add-cliente-form">Editando cliente</Label>
				      </div>
				      
				      <div className="logo-div-add-cliente-form">
				        <FaUserEdit style={ styles.styleIcons } />
				      </div>

				      <br />

				      <FormGroup style={{ display: this.state.tipo != 'PERSONA JURIDICA' ? '' : 'none' }}>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="primer_nombre"
						          id="primer_nombre"
						          placeholder="Primer nombre"
						          value={ this.state.primer_nombre }
						          onChange={ (text) => { this.setState({ primer_nombre: text.target.value }); } }

						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="segundo_nombre"
						          id="segundo_nombre"
						          placeholder="Segundo nombre"
						          value={ this.state.segundo_nombre }
						          onChange={ (text) => { this.setState({ segundo_nombre: text.target.value }); } }

						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>

				      <FormGroup style={{ display: this.state.tipo != 'PERSONA JURIDICA' ? '' : 'none' }}>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="primer_apellido"
						          id="primer_apellido"
						          placeholder="Primer apellido"
						          value={ this.state.primer_apellido }
						          onChange={ (text) => { this.setState({ primer_apellido: text.target.value }); } }

						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="segundo_apellido"
						          id="segundo_apellido"
						          placeholder="Segundo apellido"
						          value={ this.state.segundo_apellido }
						          onChange={ (text) => { this.setState({ segundo_apellido: text.target.value }); } }

						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>

				      <FormGroup style={{ display: this.state.tipo != 'PERSONA JURIDICA' ? 'none' : '' }}>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="razon_social"
						          id="razon_social"
						          placeholder="Razón social"
						          value={ this.state.razon_social }
						          onChange={ (text) => { this.setState({ razon_social: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="nrc"
						          id="nrc"
						          placeholder="NRC"
						          value={ this.state.nrc }
						          onChange={ (text) => { this.setState({ nrc: text.target.value }); } }
						        />
					      	</Col>
					    </Row>
				        
				      </FormGroup>

				      <FormGroup style={{ display: this.state.tipo != 'PERSONA JURIDICA' ? 'none' : '' }}>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="dui_representante"
						          id="dui_representante"
						          placeholder="DUI Representante"
						          value={ this.state.dui_representante }
						          onChange={ (text) => { this.setState({ dui_representante: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="nit_representante"
						          id="nit_representante"
						          placeholder="NIT Representante"
						          value={ this.state.nit_representante }
						          onChange={ (text) => { this.setState({ nit_representante: text.target.value }); } }
						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>

				      <FormGroup style={{ display: this.state.tipo != 'PERSONA JURIDICA' ? 'none' : '' }}>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="telefono_representante"
						          id="telefono_representante"
						          placeholder="Telefono Representante"
						          value={ this.state.telefono_representante }
						          onChange={ (text) => { this.setState({ telefono_representante: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="mail_representante"
						          id="mail_representante"
						          placeholder="Email Representante"
						          value={ this.state.mail_representante }
						          onChange={ (text) => { this.setState({ mail_representante: text.target.value }); } }
						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>
				      
				      <FormGroup>

				      	<Row md={2}>
					      	<Col sm={6} style={{ display: this.state.tipo != 'PERSONA JURIDICA' ? '' : 'none' }}>
					      		<Input
						          type="text"
						          name="dui"
						          id="dui"
						          placeholder="DUI"
						          value={ this.state.dui }
						          onChange={ (text) => { this.setState({ dui: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6} style={{ display: this.state.tipo != 'PERSONA JURIDICA' ? 'none' : '' }}>
					      		<Input
						          type="text"
						          name="representante_legal"
						          id="representante_legal"
						          placeholder="Representante legal"
						          value={ this.state.representante_legal }
						          onChange={ (text) => { this.setState({ representante_legal: text.target.value }); } }
						        />
					      	</Col>

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="nit"
						          id="nit"
						          placeholder="NIT"
						          value={ this.state.nit }
						          onChange={ (text) => { this.setState({ nit: text.target.value }); } }
						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>

				      	<Row md={2}>
					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="numero_telefonico"
						          id="numero_telefonico"
						          placeholder="Telefono"
						          value={ this.state.numero_telefonico }
						          onChange={ (text) => { this.setState({ numero_telefonico: text.target.value }); } }
						        />
					      	</Col>	

					      	<Col sm={6}>
					      		<Input
						          type="text"
						          name="email"
						          id="email"
						          placeholder="Email"
						          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
						          title="Cuenta de correo debe ser como correo@dominio.com"
						          value={ this.state.email }
						          onChange={ (text) => { this.setState({ email: text.target.value }); } }
						        />
					      	</Col>	
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>
				        <hr />
				      </FormGroup>

				      <FormGroup style={{ display: 'none' }}>

				      	<Row md={1}>
					      	<Col sm={12}>
					      		<Input
						          type="text"
						          name="calidad"
						          id="calidad"
						          placeholder="Calidad"
						          value={ this.state.calidad }
						          onChange={ (text) => { this.setState({ calidad: text.target.value }); } }
						        />
					      	</Col>		
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>

				      	<Row md={1}>
					      	<Col sm={12}>
					      		<Input
						          type="textarea"
						          name="direccion"
						          id="direccion"
						          placeholder="Domicilio"
						          value={ this.state.direccion }
						          onChange={ (text) => { this.setState({ direccion: text.target.value }); } }
						        />
					      	</Col>		
					    </Row>
				        
				      </FormGroup>
				      
				      <FormGroup style={{ display: 'none' }}>

				      	<Row md={1}>
					      	<Col sm={12}>
					      		<Input
						          type="textarea"
						          name="referencia_direccion"
						          id="referencia_direccion"
						          placeholder="Referencia dirección"
						          value={ this.state.referencia_direccion }
						          onChange={ (text) => { this.setState({ referencia_direccion: text.target.value }); } }
						        />
					      	</Col>		
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>

				      	<Row md={1}>
					      	<Col sm={12}>
					      		<Input
						          type="textarea"
						          name="descripcion_adicional"
						          id="descripcion_adicional"
						          placeholder="Otros datos"
						          value={ this.state.descripcion_adicional }
						          onChange={ (text) => { this.setState({ descripcion_adicional: text.target.value }); } }
						        />
					      	</Col>		
					    </Row>
				        
				      </FormGroup>

				      <FormGroup>
				      	<Button className="button_addcliente" onClick={ this.UpdateCliente }>Actualizar datos</Button>

				      	{ render_alert_error_client_creation }

				      </FormGroup>

			    </Form>


			</div>

		);

	}

}

